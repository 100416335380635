import { createReducer, on } from '@ngrx/store';
import { ShowModel } from '@app/models/show-models';
import * as ShowApiActions from '@app/show/show.actions';
import { SliderElement } from '@kehe/phoenix-carousel';
import { OrderVersions } from '@app/models/order-versions';
import { ShowOrdersRecentlyCreatedGhost } from '@app/models/showOrdersRecentlyCreatedGhost';
import * as ShowOrdersListGridActions from '@app/modules/orders-show/list/store/actions/grid.actions';
import * as ShowOrderDetailEcmActions from '@app/modules/orders-show/detail/store/actions/ecm.actions';
import * as ShowOrderListMenuActions from '@app/modules/orders-show/list/store/actions/menu.actions';
import * as ShowOrderListEcmActions from '@app/modules/orders-show/list/store/actions/ecm.actions';

export const key = 'show';

export interface State {
  shows: ShowModel[];
  hasShowBeenChecked: boolean;
  sliderElements: SliderElement[];
  orderVersions: OrderVersions;
  ghostOrders: Array<ShowOrdersRecentlyCreatedGhost>;
}

export const initialState: State = {
  shows: [],
  hasShowBeenChecked: false,
  sliderElements: null,
  orderVersions: {},
  ghostOrders: []
};

export const reducer = createReducer(
  initialState,
  on(ShowApiActions.checkSupplierInShowSuccess, (state, { shows }) => {
    return {
      ...state,
      hasShowBeenChecked: true,
      shows
    };
  }),
  on(ShowApiActions.checkSupplierInShowFailure, (state) => ({
    ...state,
    hasShowBeenChecked: true
  })),
  on(
    ShowApiActions.getSupplierSliderElementsSuccess,
    (state, { sliderElements }) => ({
      ...state,
      sliderElements
    })
  ),
  on(ShowApiActions.getSupplierSliderElementsFailed, (state) => ({
    ...state,
    sliderElements: null
  })),
  on(
    ShowOrderListMenuActions.orderVersionsLoadedSuccess,
    ShowOrderDetailEcmActions.orderVersionsLoadedSuccess,
    (state, { orders }) => {
      let orderVersions = { ...state.orderVersions };
      orders.forEach((order) => {
        orderVersions = addOrderToVersionsIfNewer(
          order.orderId,
          order.masterVersionNumber,
          orderVersions
        );
      });

      return {
        ...state,
        orderVersions
      };
    }
  ),

  on(
    ShowOrderListEcmActions.removeOrdersRecentlyCreatedGhost,
    (state, { ordersId }) => {
      let showOrdersRecentlyCreatedGhostClone = [...state.ghostOrders];
      showOrdersRecentlyCreatedGhostClone =
        showOrdersRecentlyCreatedGhostClone.filter(
          (recentlyCreated) =>
            !ordersId.includes(String(recentlyCreated.orderId))
        );

      return {
        ...state,
        showOrdersRecentlyCreatedGhost: showOrdersRecentlyCreatedGhostClone
      };
    }
  ),

  on(
    ShowOrdersListGridActions.ordersListDeleteOrdersSuccess,
    (state, { response }) => {
      let orderVersions = { ...state.orderVersions };
      response.forEach((order) => {
        orderVersions = addOrderToVersionsIfNewer(
          order.id,
          order.masterVersionNumber,
          orderVersions
        );
      });

      return {
        ...state,
        orderVersions
      };
    }
  )
);

export const getShows = (state: State) => state && state.shows;
export const getHasShowBeenChecked = (state: State) =>
  state && state.hasShowBeenChecked;
export const getSliderElements = (state: State) =>
  state && state.sliderElements;
export const getOrderVersions = (state: State) => state && state.orderVersions;
export const getGhostOrders = (state: State) => state && state.ghostOrders;

function addOrderToVersionsIfNewer(
  orderId: number,
  masterVersionNumber: number,
  orderVersions: OrderVersions
): OrderVersions {
  if (masterVersionNumber > (Number(orderVersions[orderId]) || 0)) {
    orderVersions[orderId] = masterVersionNumber;
  }
  return orderVersions;
}
