<kehe-phoenix-modal name="chooseFrequencyModal" modalTitle="Auto-Renewal" [show]="true"
  (modalEvent)="handleModalEvent($event)" size="md">
  <div body>
    <p>
      <strong>Please select the frequency of your auto-renewal subscription service.</strong>
    </p>
    <p>Note: The ESN(s) added to your cart will be auto-renewed at the same frequency selected below.</p>
    <form [formGroup]="form">
      <kendo-formfield>
        <div class="mb-2">
          <input #yearly kendoRadioButton type="radio" formControlName="frequency" value="yearly" />
          <kendo-label class="me-2 k-radio-label" [for]="yearly" text="Yearly - $500/year (per ESN)"></kendo-label>
        </div>
        <div>
          <input #monthly kendoRadioButton type="radio" formControlName="frequency" value="monthly" />
          <kendo-label class="me-2 k-radio-label" [for]="monthly" text="Monthly - $50/month (per ESN)"></kendo-label>
        </div>
      </kendo-formfield>
    </form>
  </div>

  <div footer class="d-flex">
    <div class="me-auto">
      <kehe-phoenix-button name="terms" classAttr="btn-link btn-medium" (click)="onViewTermsAndConditionsClick()">View
        Terms & Conditions</kehe-phoenix-button>
    </div>
    <div class="me-2">
      <kehe-phoenix-button name="cancel" classAttr="btn-link btn-medium"
        (click)="onCancelClick()">Cancel</kehe-phoenix-button>
    </div>
    <div>
      <kehe-phoenix-button name="add" classAttr="btn-primary btn-medium" [disabled]="!form.valid"
        (click)="onAddClick(form.get('frequency').value)">Add</kehe-phoenix-button>
    </div>
  </div>

</kehe-phoenix-modal>