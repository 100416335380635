<kehe-phoenix-modal
  modalTitle="Updated Terms of Service"
  hideCloseIcon="true"
  [show]="userAgreementStatus === userAgreementStatuses.UserHasNotAgreed"
  size="lg"
>
  <div body class="user-agreement-modal-body">
    <div class="user-agreement-modal-message">
      <span>We have updated our Terms & Conditions and Privacy Policy to make it more clear and transparent.</span>
      <span>Please read each tab carefully.</span>
    </div>
    <kehe-phoenix-alert
      [show]="shouldDisplayAlert$ | async"
      [type]="alertType$ | async"
      [message]="alertMessage$ | async"
    >
    </kehe-phoenix-alert>
    <kendo-buttongroup
      selection="single"
      width="100%"
      class="mb-4"
    >
      <button
        kendoButton
        [toggleable]="true"
        [selected]="activeTab === userAgreementTabs.TermsAndConditions"
        (selectedChange)="onTabClick(userAgreementTabs.TermsAndConditions)"
      >
      Terms & Conditions
      </button>
      <button
        kendoButton
        [toggleable]="true"
        [selected]="activeTab === userAgreementTabs.PrivacyStatement"
        (selectedChange)="onTabClick(userAgreementTabs.PrivacyStatement)"
      >
        Privacy Policy
      </button>
    </kendo-buttongroup>
    <div [ngSwitch]="activeTab" class="user-agreement-document">
      <div
        *ngSwitchCase="userAgreementTabs.TermsAndConditions"
        [innerHtml]="termsAndConditionsPage$ | async"
      >
      </div>
      <div
        *ngSwitchCase="userAgreementTabs.PrivacyStatement"
        [innerHtml]="privacyStatementPage$ | async"
      >
      </div>
    </div>
  </div>
  <div footer *ngIf="(shouldDisplayAlert$ | async) === false">
    <div class="user-agreement-body-footer"></div>
    <form [formGroup]="userAgreementForm" class="ms-1">
      <input
        type="checkbox"
        kendoCheckBox
        formControlName="hasAcceptedAgreement"
        #userAgreement
      />
      <kendo-label
        [for]="userAgreement"
        text="I agree to the Terms & Conditions and Privacy Policy"
        class="k-checkbox-label ms-1"
      >
      </kendo-label>
    </form>
    <div class="d-flex flex-row justify-content-end">
      <kehe-phoenix-button
        [disabled]="!userAgreementForm.valid"
        [spinner]="isWriteLoading$ | async"
        classAttr="btn-primary btn-medium"
        (click)="onSubmitAcceptedAgreement()"
      >
        Accept
      </kehe-phoenix-button>
    </div>
  </div>
</kehe-phoenix-modal>
