import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSupplierList from './store/supplier-list.reducer';
import { SupplierListEffects } from './store/supplier-list.effects';
import { SupplierPageComponent } from './pages/supplier-page/supplier-page.component';
import { SupplierService } from './services/supplier.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SupplierListRoutingModule } from './supplier-list-routing.module';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixChipsModule } from '@kehe/phoenix-chips';
import { PhoenixSearchModule } from '@kehe/phoenix-search';
import { GridModule } from '@progress/kendo-angular-grid';
import { PagerModule } from '@progress/kendo-angular-pager';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { BreadCrumbsComponent } from '@app/breadcrumbs/breadcrumbs.component';
import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';

@NgModule({
  declarations: [
    SupplierPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    SupplierListRoutingModule,
    StoreModule.forFeature('supplierList', fromSupplierList.reducer),
    EffectsModule.forFeature([SupplierListEffects]),
    PhoenixButtonModule,
    PhoenixChipsModule,
    PhoenixSearchModule,
    GridModule,
    PagerModule,
    PhoenixIndicatorsModule,
    BreadCrumbsComponent,
    PhoenixAlertsModule
  ],
  providers: [SupplierService],
  exports: [SupplierPageComponent]
})
export class SupplierListModule {}
