import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ResourceActions } from '@app/constants/resource-actions';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

/**
 * Announcement List page Guard, actives the route only if the user has the Supplier_Site Resource Action
 */
@Injectable({
  providedIn: 'root'
})
export class AnnouncementsListGuard  {
  constructor(private router: Router,
              private store: Store) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(AuthenticationSelectors.selectDoesUserHaveAction(ResourceActions.SupplierSiteAccess)).pipe(
      map((hasSiteAccess) => hasSiteAccess || this.router.parseUrl('/unauthorized'))
    )
  }
}
