import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Payload } from '../models/payload';
import {
  LiveAndPostShows,
  ShowEligibleCustomer,
  ShowModel
} from '../models/show-models';
import { SliderElement } from '@kehe/phoenix-carousel';
import { ShowParticipation } from '../models/show-participation';
import {
  CarouselItem,
  CarouselTypeId
} from '@app/components/show-announcements/carousel-item-interfaces';

@Injectable({
  providedIn: 'root'
})
export class ShowService {
  constructor(private _httpClient: HttpClient) {}

  public getSupplierIsInShow(esns: string[]): Observable<ShowModel[]> {
    const url = `${environment.api}/shows/supplier/checkShowEligibility`;
    return this._httpClient.post<Payload<ShowModel[]>>(url, { esns }).pipe(
      map((res) => res.data)
    );
  }

  public getImpersonatedSupplierIsInShow(
    headersOnly: boolean = false
  ): Observable<ShowModel[]> {
    const url = `${environment.api}/shows/impersonated-supplier/checkShowEligibility`;
    const params: HttpParams = new HttpParams().set('headersonly', headersOnly);
    return this._httpClient.get<Payload<ShowModel[]>>(url, { params }).pipe(
      map((res) => res.data)
    );
  }

  public loadSupplierShowHistory(
    vendors: string[]
  ): Observable<ShowParticipation[]> {
    const url = `${environment.api}/shows/api/supplier/participation`;
    return this._httpClient.post<ShowParticipation[]>(url, { esns: vendors });
  }

  public getLiveAndPostShows(): Observable<LiveAndPostShows[]> {
    const url = `${environment.api}/future-orders/supplier/active-shows-list`;
    return this._httpClient.get<LiveAndPostShows[]>(url);
  }

  public getSupplierSliderElements(
    showId: string
  ): Observable<SliderElement[]> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );
    return this._httpClient
      .get<CarouselItem>(
        `${environment.api}/ads/carousel/show/${CarouselTypeId.SupplierShow}/${showId}`,
        { headers }
      )
      .pipe(
        map((res) => this.carouselItemToSliderElementsArray(res)),
        catchError((response) => of(null))
      );
  }

  public carouselItemToSliderElementsArray(
    carouselItem: CarouselItem
  ): SliderElement[] {
    let result: SliderElement[] = null;
    if (!carouselItem?.slides?.length) {
      return result;
    }
    result = carouselItem.slides.map((slide) => {
      return {
        imageUrl:
          slide?.mediaType === 'Video'
            ? slide?.mediaThumbnailUrl
            : slide?.mediaUrl,
        linkUrl: slide?.linkUrl,
        videoUrl: slide?.mediaType === 'Video' ? slide?.mediaUrl : null
      };
    });
    return result;
  }

  getCustomersInShow(
    showId: string = null,
    search = null
  ): Observable<ShowEligibleCustomer[]> {
    const url = `${environment.api}/shows/api/supplier/eligibility/retailers`;
    let params = new HttpParams()
      .set("pageCount", 25);

    if (showId) {
      params = params.set('showid', showId);
    }

    if (search) {
      params = params.set('search', search);
    }

    return this._httpClient
      .get<Payload<ShowEligibleCustomer[]>>(url, { params })
      .pipe(
        map((response) =>
          response.data?.map((customer) => ({
            ...customer,
            isKCDCustomer:
              customer.dcCustomerNumber.startsWith('1694') ||
              customer.dcCustomerNumber.startsWith('4192')
          }))
        )
      );
  }
}
