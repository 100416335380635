import { createSelector } from "@ngrx/store";
import { User } from "oidc-client-ts";
import * as JWT from 'jwt-decode';
import { State } from "../index";
import * as fromAuthentication from "./authentication.reducer";

export const selectAuthenticationState = (state: State) => state.authentication;
export const selectUser = createSelector(
    selectAuthenticationState,
    fromAuthentication.getUser,
);
export const selectLegacyToken = createSelector(
    selectAuthenticationState,
    fromAuthentication.getLegacyToken,
);
export const selectUserDetail = createSelector(
    selectAuthenticationState,
    fromAuthentication.getUserDetail,
)

export const selectIsUserAuthenticated = createSelector(
    selectUser,
    (user: User) => {
        if (user && user.access_token) { return true; }
        return false;
    }
);
export const selectToken = createSelector(
    selectUser, (user: User) => {
        if (user && user.access_token) { return user.access_token; }
        return null;
    }
);
export const selectUserTokens = createSelector(
    selectToken, selectLegacyToken,
    (token: string, legacyToken: string) => {
        return { token, legacyToken };
    }
);
export const selectUserFirstName = createSelector(
    selectUser, (user: User) => {
        if (user) { return JWT(user.access_token).first_name; }
        return '';
    }
);
export const selectUserLastName = createSelector(
    selectUser, (user: User) => {
        if (user) { return JWT(user.access_token).last_name; }
        return '';
    }
);
export const selectUserFullName = createSelector(
    selectUser, (user: User) => {
        return user?.profile?.name;
    }
);
export const selectUserEmail = createSelector(
    selectUser, (user: User) => {
        if (user) { return JWT(user.access_token).email; }
        return '';
    }
);
export const selectIsUserEmployee = createSelector(
    selectUserEmail, (email: string) => {
        return email && email.toLocaleLowerCase().includes('@kehe.com')
    }
);
export const selectUserActions = createSelector(
    selectUser,
    selectLegacyToken,
     (user: User, legacyToken: string) => {
        if (user && user.profile) { 
            // get v1 actions from token and concat - TODO: move this to own selector
            const decodedV1Token = legacyToken ? JWT(legacyToken) : '';
            let v1Roles = [];
            if (decodedV1Token) {
                v1Roles = decodedV1Token.role;
            }
            const actions = user.profile.actions as string[];
            return actions.concat(v1Roles); 
        }
        return null;
    }
);
export const selectDoesUserHaveAction = (action: string) => createSelector(
    selectUserActions,
    (userActions: string[]) => {
        return userActions && userActions.includes(action);
    }
);

/*
this selector converts the `exp` property in the token, measured in seconds since the beginning of the epoch,
to a new Date object by creating a new Date object that starts at the beginning of the epoch and adds
the number of seconds (taken from `exp`) to convert the date.
*/
export const selectTokenExpiration = createSelector(
    selectToken,
    (token: string) => {
        if (!token) { return null; }
        const decodedToken = JWT(token);
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decodedToken.exp);
        return expirationDate;
    }
);

export const selectIsInternalUser = createSelector(
    selectUser, (user: User) => {
        if (user && user.profile) { return user.profile.is_internal_user === true || user.profile.is_internal_user === 'true' ? true : false; }
        return false;
    }
);

export const selectUserType = createSelector(
    selectUserDetail,
    (user) => {
        return user?.type
    },
);
export const selectUserCompany = createSelector(
    selectUserDetail,
    (user) => {
        return user?.company
    },
);
export const selectUserNumber = createSelector(
    selectUserDetail,
    (user) => {
        return user?.number
    },
);
export const selectUserCanEditProfileInV1 = createSelector(
    selectUserType,
    (userType) => {
        return userType === 'supplier' || userType === 'broker';
    },
);
