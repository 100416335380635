import { Message } from '@progress/kendo-angular-conversational-ui';

export enum ActionTypes {
  Link = 'link',
  Feedback = 'feedback'
}

export interface ChatBotMessage extends Message {
  conversationId?: string;
  systemMessageId?: string;
  userMessageId?: string;
}

export interface ConnectUser {
  Name: string;
  FirstName: string;
  LastName: string;
  Email: string;
  IsInternalUser: boolean;
  IsConnectChatbotAuthorized: boolean;
}

export interface ChatBotUser {
  Id: string;
  ChatUserName: string;
  Created: string;
  IsChatUserAvailable: boolean;
  ImpersonatedUserFirstName: string;
  ImpersonatedUserLastName: string;
  ImpersonatedUserEmail: string;
  ImpersonatedLastUpdated: string;
}

export interface ChatBotAuthorization {
  AccessToken: string;
  ExpiresIn: number;
  IdToken: string;
  RefreshToken: string;
  TokenType: string;
}

export interface ChatBotAuthorizeRequest {
  RequestUser: ConnectUser;
  ChatUser?: ChatBotUser | undefined;
  AppAuth?: ChatBotAuthorization | undefined;
}

export interface ChatBotAuthorizeResponse {
  RequestUser: ConnectUser;
  ChatUser: ChatBotUser;
  AppAuth: ChatBotAuthorization;
}

export interface Messages {
  RequestMessage: string;
  ResponseMessage: string;
  ResponseLinkObjects: ResponseLinkObjects[];
}

export interface ResponseLinkObjects {
  url: string;
  title: string;
  isExternal: boolean;
}

export interface ChatBotSendMessageRequest {
  RequestUser: ConnectUser;
  ChatUser: ChatBotUser;
  AppAuth: ChatBotAuthorization;
  RequestMessage: string;
}

export interface ChatBotSendMessageResponse {
  RequestUser: ConnectUser;
  ChatUser: ChatBotUser;
  AppAuth: ChatBotAuthorization;
  Messages: Messages;
  conversationId: string;
  systemMessageId: string;
  userMessageId: string;
}

export interface ChatBotSendFeedbackRequest {
  appAuth: ChatBotAuthorization;
  conversationId: string;
  systemMessageId: string;
  userMessageId: string;
  requestMessage: string;
  responseFeedback: boolean;
}
