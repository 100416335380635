import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierListState } from './supplier-list.reducer';
import { AlertTypes, IAlert } from '@kehe/phoenix-notifications';

export const getSupplierListState = createFeatureSelector<SupplierListState>(
  'supplierList'
);

export const getSupplierListFilter = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter
);

export const getSupplierList = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierList
);

export const getSupplierListLoading = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.isLoading
);

export const getSupplierListCount = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.availableCount
);

export const getSupplierListPageCount = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.pageCount
);

export const getDisplaySupplierFilterPanel = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.displayFilterPanel
);

export const getSupplierListCurrentPage = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.pageIndex + 1
);

export const getSupplierListSkip = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.skip
);

export const getSupplierListSearch = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilter.search
);

export const getSupplierListFilterChips = createSelector(
  getSupplierListState,
  (state: SupplierListState) => state.supplierListFilterChips
);

export const getSupplierListSort = createSelector(
  getSupplierListState,
  (state: SupplierListState) => {
    return { orderNumb: state.supplierListFilter.sortName, dir: state.supplierListFilter.orderDirection };
  }
);

export const getPosForUserEsns = createSelector(
  getSupplierListState,
  (state: SupplierListState) => {
    return state.posForUserEsns;
  }
);

export const selectShowPurchasingOptionsAlert = createSelector(
  getPosForUserEsns,
  (posForUserEsns): IAlert => {

    let message = '';
    let displayAction = posForUserEsns?.purchasingOptions?.length === 1;
  
    if (posForUserEsns?.hasPoInSupplierReview)
    {
      message = 'One or more of your Suppliers have Purchasing Options to review. ';
    }
  
    if (posForUserEsns?.hasSupplierWithoutPO)
    {
      message += 'If any of your Suppliers do not have Purchasing Options, please reach out to <a href=mailto:purchasingoptions@supplierconnections.kehe.com>purchasingoptions@supplierconnections.kehe.com</a> for further assistance.'
    }

    if (!!message) {
      return {
        message: message,
        show: true,
        type: AlertTypes.Information,
        action1: displayAction && !posForUserEsns?.hasSupplierWithoutPO ? 'Purchasing Options' : null,
      };
    }
    return null;
  }
);

