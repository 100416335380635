import { Message } from './../service/API.service';
import { Actions, createEffect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessagesActions } from './messages.actions';
import {
  switchMap,
  map,
  withLatestFrom,
  startWith,
  filter
} from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { MessagesService } from '../service/messages.service';
import {
  selectEarliestShowStartDate,
  selectchatMessageBeingSend,
  selectEnabledChats,
  selectMessageBeingSend
} from './messages.selectors';
import { combineLatest, forkJoin } from 'rxjs';
import { isEmpty } from 'lodash';
import { IChatRoom } from '../models/IChatRoom';
import { EStatusChatRoom } from '../models/EStatusChatRoom';
import * as ShowApiActions from '@app/show/show.actions';
import { of } from 'rxjs';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

@Injectable()
export class MessagesEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _messagesService: MessagesService
  ) {}

  openChat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.openChat),
      withLatestFrom(
        this._store.select(AuthenticationSelectors.selectUserEmail),
        this._store.select(selectEnabledChats)
      ),
      filter((data) => {
        const newChat = data && data[0];
        const enabledChats = data && data.length > 2 && data[2];
        if (enabledChats) {
          const existingChatIndex = enabledChats.findIndex(
            (chat) =>
              !chat.loading &&
              chat.supplierNumber === newChat.supplierNumber &&
              chat.customerNumber === newChat.customerNumber &&
              chat.status === EStatusChatRoom.opened
          );
          return existingChatIndex === -1;
        } else {
          return true;
        }
      }),
      switchMap(([actions, userEmail, enabledChats]) => {
        const existingChatIndex = enabledChats.findIndex(
          (chat) =>
            !chat.loading &&
            chat.supplierNumber === actions.supplierNumber &&
            chat.customerNumber === actions.customerNumber &&
            chat.status !== EStatusChatRoom.closed
        );

        if (
          existingChatIndex > -1 &&
          enabledChats[existingChatIndex].status === EStatusChatRoom.collapsed
        ) {
          const chatRoom = enabledChats[existingChatIndex];
          return of(MessagesActions.toggleCollapseChat({ chatRoom }));
        } else {
          return this._messagesService
            .listChatRoomByCustomerSupplierNumberQuery(
              actions.customerNumber,
              actions.supplierNumber
            )
            .pipe(
              map((payload: any) => {
                if (
                  payload &&
                  payload.data.chatRoomByCustomerSupplierNumber &&
                  payload.data.chatRoomByCustomerSupplierNumber.items &&
                  payload.data.chatRoomByCustomerSupplierNumber.items.length ===
                    0
                ) {
                  return MessagesActions.openChatSuccessNew({
                    chatRoom: {
                      customerName: actions.customerName,
                      customerNumber: actions.customerNumber,
                      supplierName: actions.supplierName,
                      supplierNumber: actions.supplierNumber
                    }
                  });
                } else {
                  const chatRoom =
                    payload.data.chatRoomByCustomerSupplierNumber.items[0];
                  return MessagesActions.openChatSuccessExisting({
                    chatRoom,
                    userEmail
                  });
                }
              })
            );
        }
      })
    )
  );

  getMessages$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.openChatSuccessExisting),
      switchMap((actions) => {
        return this._messagesService
          .messagesByChatRoom(actions.chatRoom.id, 200)
          .pipe(
            map((payload: any) => {
              const messages = payload.items;
              const nextTokenMessages = payload.nextToken;
              return MessagesActions.getMessagesSuccess({
                messages,
                nextTokenMessages,
                chatRoom: actions.chatRoom,
                userEmail: actions.userEmail
              });
            })
          );
      })
    )
  );

  getMoreMessages$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.getMoreMessages),
      withLatestFrom(
        this._store.select(AuthenticationSelectors.selectUserEmail)
      ),
      switchMap(([{ chatRoom }, userEmail]) => {
        return this._messagesService
          .messagesByChatRoom(chatRoom.id, 30, chatRoom.nextTokenMessages)
          .pipe(
            map((payload: any) => {
              const messages = payload.items;
              const nextTokenMessages = payload.nextToken;
              return MessagesActions.getMoreMessagesSuccess({
                messages,
                nextTokenMessages,
                chatRoom,
                userEmail
              });
            })
          );
      })
    )
  );

  sendMessage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.sendMessage),
      withLatestFrom(
        this._store.select(selectMessageBeingSend),
        this._store.select(selectchatMessageBeingSend)
      ),
      switchMap(([actions, messageBeingSend, chatMessageBeingSend]) => {
        return this._messagesService
          .getChatRoomByCustomerSupplierNumber(
            chatMessageBeingSend.customerNumber,
            chatMessageBeingSend.supplierNumber
          )
          .pipe(
            map((payload: any) => {
              if (payload && payload.items && payload.items.length === 0) {
                // We need first to create this chat
                return MessagesActions.createChatRoom({
                  messageBeingSend,
                  chatMessageBeingSend
                });
              } else {
                return MessagesActions.sendMessageWithChatAlreadyCreated({
                  messageBeingSend,
                  chatMessageBeingSend,
                  newChatRoom: chatMessageBeingSend
                });
              }
            })
          );
      })
    )
  );

  createChatRoom$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.createChatRoom),
      switchMap((actions) => {
        return this._messagesService
          .createChatRoom(actions.chatMessageBeingSend)
          .pipe(
            map((payload: any) => {
              const createdChatRoom = payload.data.createChatRoom;
              return MessagesActions.sendMessageWithChatAlreadyCreated({
                messageBeingSend: {
                  ...actions.messageBeingSend,
                  chatRoomID: createdChatRoom.id
                },
                chatMessageBeingSend: actions.chatMessageBeingSend,
                newChatRoom: createdChatRoom
              });
            })
          );
      })
    )
  );

  sendMessageWithChatAlreadyCreated$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.sendMessageWithChatAlreadyCreated),
      switchMap((actions) => {
        return this._messagesService.sendMessage(actions.messageBeingSend).pipe(
          map((payload: Message) => {
            return MessagesActions.sendMessageWithChatAlreadyCreatedSuccess({
              message: payload,
              chatRoom: actions.newChatRoom
            });
          })
        );
      })
    )
  );

  sendMessageWithChatAlreadyCreatedSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.sendMessageWithChatAlreadyCreatedSuccess),
      switchMap((actions) => {
        return this._messagesService
          .updateChatRoom(
            actions.message.id,
            actions.chatRoom.id,
            actions.chatRoom.customerNumber
          )
          .pipe(
            map((payload: any) => {
              return MessagesActions.sendMessageSuccess({
                message: actions.message,
                chatRoomID: actions.chatRoom.id
              });
            })
          );
      })
    )
  );

  subscribeToChatRoomsBySupplier$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShowApiActions.checkSupplierInShowSuccess),
      withLatestFrom(
        this._store.select(ShowSelectors.selectSuppliers),
        this._store.select(AuthenticationSelectors.selectUserEmail)
      ),
      switchMap(([actions, suppliers, userEmail]) => {
        const supplierCalls = [];
        suppliers.forEach((supplier) => {
          supplierCalls.push(
            this._messagesService
              .subscribeChatRoomBySupplier(supplier.esn)
              .pipe(startWith({}))
          );
        });
        return combineLatest(supplierCalls).pipe(
          map((payload: any) => {
            const hasPayload = !payload.every((p) => isEmpty(p));
            if (hasPayload) {
              const chatRooms = [];
              payload.map((p) => {
                if (!isEmpty(p)) {
                  chatRooms.push(p.data.onUpdateChatRoomBySupplierNumber);
                }
                return MessagesActions.receiveChatRoomUpdate({
                  chatRooms,
                  userEmail
                });
              });
            } else {
              return MessagesActions.receiveChatRoomUpdate({
                chatRooms: [],
                userEmail
              });
            }
          })
        );
      })
    )
  );

  getUnreadsBySupplierNumber$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ShowApiActions.checkSupplierInShowSuccess),
      withLatestFrom(this._store.select(ShowSelectors.selectSuppliers)),
      switchMap(([actions, suppliers]) => {
        const supplierCalls = [];
        suppliers.forEach((supplier) => {
          supplierCalls.push(
            this._messagesService.getUnreadsBySupplierNumber(supplier.esn)
          );
        });

        return forkJoin(supplierCalls).pipe(
          map((payload: any) => {
            return MessagesActions.getUnreadsBySupplierNumberSuccess({
              unreads: payload
            });
          })
        );
      })
    )
  );

  getChatRoomBySupplierNumber$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.getUnreadsBySupplierNumberSuccess),
      withLatestFrom(
        this._store.select(ShowSelectors.selectSuppliers),
        this._store.select(selectEarliestShowStartDate)
      ),
      switchMap(([actions, suppliers, earliestShowStartDate]) => {
        const supplierCalls = [];
        suppliers.forEach((supplier) => {
          supplierCalls.push(
            this._messagesService.getChatRoomBySuppliers(supplier.esn)
          );
        });

        return forkJoin(supplierCalls).pipe(
          map((payload: any) => {
            let chatRooms = [];
            payload.forEach((p) => {
              chatRooms = chatRooms.concat(p.items);
            });
            return MessagesActions.getChatRoomByESNSuccess({
              chatRooms,
              earliestShowStartDate
            });
          })
        );
      })
    )
  );

  notifyChatIsRead$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        MessagesActions.openChatSuccessExisting,
        MessagesActions.readMessageFromCollapsedChat
      ),
      filter((chat) => {
        return (
          chat &&
          chat.chatRoom.supplierNumber === chat.chatRoom.clientNumberUnread
        );
      }),
      switchMap((actions) => {
        return this._messagesService
          .updateChatRoomWithMessageRead(actions.chatRoom.id)
          .pipe(
            map((payload: any) => {
              return MessagesActions.notifyChatIsReadSuccess({
                chatRoom: {} as IChatRoom
              });
            })
          );
      })
    )
  );

  triggerReadMessageOnExpand$ = createEffect(() =>
    this._actions$.pipe(
      ofType(MessagesActions.toggleCollapseChat),
      filter((chat) => {
        return (
          chat &&
          chat.chatRoom.status === EStatusChatRoom.opened &&
          chat.chatRoom.clientNumberUnread === chat.chatRoom.supplierNumber
        );
      }),
      map((actions) => {
        return MessagesActions.readMessageFromCollapsedChat({
          chatRoom: actions.chatRoom
        });
      })
    )
  );
}
