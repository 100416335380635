import { createSelector } from "@ngrx/store";
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import { DateUtils } from '@kehe/phoenix-utils';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from '@app/feature-flag/index';

const selectFormattedDateForPriorityEvent = createSelector(
  ShowSelectors.selectPriorityEventToDisplay,
  (show) => {
    if (!show) return null;

    const start = show.startDate;
    const end = show.endDate;

    const startMonth = DateUtils.create(start).format('MMM');
    const endMonth = DateUtils.create(end).format('MMM');
    const sameMonth = startMonth === endMonth;

    const startYear = DateUtils.create(start).format('YYYY');
    const endYear = DateUtils.create(end).format('YYYY');
    const sameYear = startYear === endYear;

    const startRange = `${startMonth} ${DateUtils.create(start).format('D')}${sameYear ? '' : (', ' + startYear)}`;
    const endRange = `${sameMonth ? '' : endMonth + ' '}${DateUtils.create(end).format('D')}, ${endYear}`;

    return `${startRange} - ${endRange}`;
  }
)

export const selectShowCountdownView = createSelector(
  ShowSelectors.selectPriorityEventToDisplay,
  selectFormattedDateForPriorityEvent,
  (show, formattedDate) => {
    if (!show) return null;

    const countdownBannerView = {
      name: show.name,
      formattedDate: formattedDate,
      targetDate: new Date(show.endDate),
    }

    countdownBannerView.targetDate.setUTCHours(28, 0, 0, 0);

    return countdownBannerView;
  }
);

export const selectIsChatBotAvailable = createSelector(
  AuthenticationSelectors.selectDoesUserHaveAction('SupplierChatbot_Create'),
  selectIsFeatureFlagOn(FeatureFlags.ChatBot.key),
  (doesUserHaveAction, isFlagOn) => doesUserHaveAction && isFlagOn
);