import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  filter
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowService } from '@app/services/show.service';
import * as ShowApiActions from './show.actions';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import * as AuthenticationActions from '@app/authentication/authentication.actions';

@Injectable()
export class ShowEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private showService: ShowService
  ) {}

  checkSupplierInShow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.logInSuccess),
      switchMap(() =>
        this.showService.getSupplierShowEligibility().pipe(
          map((response) =>
            ShowApiActions.checkSupplierInShowSuccess({
              shows: response || null
            })
          ),
          catchError(() => of(ShowApiActions.checkSupplierInShowFailure()))
        )
      )
    );
  });

  getCarouselForSelectedShow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShowApiActions.checkSupplierInShowSuccess),
      withLatestFrom(
        this.store.select(ShowSelectors.selectPriorityEventToDisplay)
      ),
      filter(([action, priorityEvent]) => !!priorityEvent),
      switchMap(([_, priorityEvent]) => {
        return this.showService
          .getSupplierSliderElements(priorityEvent.uniqueId)
          .pipe(
            map((sliderElements) =>
              ShowApiActions.getSupplierSliderElementsSuccess({
                sliderElements
              })
            ),
            catchError(() =>
              of(ShowApiActions.getSupplierSliderElementsFailed())
            )
          );
      })
    );
  });
}
