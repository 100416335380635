import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ShowService } from '@app/services/show.service';
import {
  switchMap,
  tap,
  filter,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { ShowEligibleCustomer } from '@app/models/show-models';

export interface ShowCustomerComboboxState {
  showId: string | null;
  customers: ShowEligibleCustomer[];
  search: string;
  isLoading: boolean;
  selectedCustomer: ShowEligibleCustomer;
}

@Injectable()
export class ShowCustomerComboboxStore extends ComponentStore<ShowCustomerComboboxState> {
  constructor(private showService: ShowService) {
    super({
      showId: null,
      customers: [],
      search: '',
      isLoading: true,
      selectedCustomer: {} as ShowEligibleCustomer
    });

    this.getInitialCustomers(this.showId$);
    this.fetchFilteredCustomers(this.search$);
  }

  showId$ = this.select((state) => state.showId);
  search$ = this.select((state) => state.search);
  selectedCustomer$ = this.select((state) => state.selectedCustomer);

  readonly setCustomers = this.updater(
    (state, customers: ShowEligibleCustomer[]) => ({
      ...state,
      customers,
      selectedCustomer: customers.length === 1 ? customers[0] : state.selectedCustomer,
      isLoading: false
    })
  );

  readonly setSearch = this.updater(
    (state, search: string): ShowCustomerComboboxState => ({
      ...state,
      search
    })
  );

  readonly setShowId = this.updater(
    (state, showId: string): ShowCustomerComboboxState => ({
      ...state,
      showId
    })
  );

  readonly setSelectedCustomer = this.updater(
    (
      state,
      selectedCustomer: ShowEligibleCustomer
    ): ShowCustomerComboboxState => ({
      ...state,
      selectedCustomer
    })
  );

  readonly getInitialCustomers = this.effect<string | null>((showId$) =>
    showId$.pipe(
      switchMap((showId) => {
        return this.showService
          .getCustomersInShow(showId, this.get().search)
          .pipe(tap((response) => this.setCustomers(response || [])));
      })
    )
  );

  readonly fetchFilteredCustomers = this.effect<string>((searchString$) =>
    searchString$.pipe(
      filter((searchString) => searchString.trim().length > 0),
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => {
        this.patchState({
          customers: [],
          isLoading: true
        });
      }),
      switchMap((searchString) => {
        return this.showService
          .getCustomersInShow(this.get().showId, searchString)
          .pipe(tap((response) => this.setCustomers(response || [])));
      })
    )
  );
}
