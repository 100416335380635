import { environment } from '../../environments/environment';

export const managePricesUrl = '/manage-prices';

export const priceChangeMgmtUrl = 'price-mgmt';
export const priceChangeDetailsUrl = priceChangeMgmtUrl + '/details';
export const catchWeightIssuesUrl = priceChangeDetailsUrl + '/catch-weight-issues';
export const linePricingIssuesUrl = priceChangeDetailsUrl + '/line-pricing-issues';
export const shipperIssuesUrl = priceChangeDetailsUrl + '/shipper-issues';
export const effectiveDateIssuesUrl = priceChangeDetailsUrl + '/effective-date-issues';

const schemeAndSubdomain = `https://${environment.name}`;


export function getPromotionsUrl(): string {
    return `${schemeAndSubdomain}promotions.kehe.com`;
}
export function getAnnouncementsUrl(): string {
    return `${schemeAndSubdomain}communications.kehe.com/#/announcements`;
}
export function getReportsUrl(): string {
    return `${schemeAndSubdomain}reports.kehe.com/`;
}
export function getItemSearchUrl(): string {
    return `${schemeAndSubdomain}connect.kehe.com/itemmanagement/`;
}
export function getProductSearchUrl(): string {
    return `${environment.web}/products`;
}
export function getVendorManagementUrl(): string {
    return `${schemeAndSubdomain}connect.kehe.com/vendormanagement/`;
}

export function getPurchasingOptionRoute(esn: string, masterId: number): string {
    if (!esn || !masterId) {
        return;
    }
    return `/supplier/${esn}/purchasing-options/${masterId}`;
}
