<div *ngIf="vm$ | async as vm">
  <kehe-phoenix-alert *ngIf="vm.showBanner"
    [show]="true"
    [type]="'priority'"
    [messageAsHtml]="showInstructionsText ? vm.priorityMsg + vm.instructionsText : vm.priorityMsg"
    [stick]="true"
    [hideCloseIcon]="true"
    [action1]="showViewButton ? 'View' : null"
    (alertEvent)="bannerClick($event)">
  </kehe-phoenix-alert>
</div>