import { BookWavesMode } from '../modules/orders-show/shared/components/book-waves-toggle/BookWavesMode.enum';
import { EventTypeIds, EventTypeDescriptions } from './event-types';

export class ShowModel {
    name: string;
    location: string;
    startDate: string;
    endDate: string;
    status: ShowStatus;
    uniqueId: string;
    showBooks: Book[];
    preShowPeriodDays: number;
    postShowPeriodDays: number;
    sodEligibilityType: SodEligibilityType;
    suppliers: Array<ShowEligibleSupplier>;
    eventTypeId: EventTypeIds;
    eventTypeDescription: EventTypeDescriptions;
    disableNegotiations: boolean;
    hasWaves: boolean;
    bookWavesMode: BookWavesMode;
}
export interface ShowEligibleSupplier {
  esn: string;
  email: string;
  name: string;
}

export interface ShowEligibleCustomer {
  dcCustomerNumber: string;
  name: string;
  isKCDCustomer?: boolean;
}

export interface Book {
  id: number;
  bookCode: string;
  startDate: string;
  endDate: string;
  waves: Wave[];
}

export interface Wave {
  name: string;
  startDate: string;
  endDate: string;
}

export enum SodEligibilityType {
    none = 'None',
    grocery = 'Grocery',
    natural = 'Natural',
    groceryAndNatural = 'GroceryAndNatural'
}

export enum ShowStatus {
  Draft = 'Draft',
  PreShow = 'PreShow',
  Live = 'Live',
  PostShow = 'PostShow',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}