<kendo-grid
  [data]="suppliersSubscriptions$ | async"
  [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
  kendoGridSelectBy="esn"
  [selectedKeys]="selectedEsns$ | async"
  (selectedKeysChange)="esnSubscriptionSelected($event)"
  [sort]="sort"
  [sortable]="{allowUnsort: false, mode: 'single'}"
  (sortChange)="sortChange($event)"
>
  <kendo-grid-checkbox-column showSelectAll="true" [width]="40"></kendo-grid-checkbox-column>
  <kendo-grid-column field="esn" title="ESN" [width]="90"></kendo-grid-column>
  <kendo-grid-column field="supplierName" title="Supplier"></kendo-grid-column>
  <kendo-grid-column title="Subscription Status" [width]="140">
    <ng-template kendoGridCellTemplate let-subscription>
      <app-esn-subscription-status-tag [status]="subscription.status"></app-esn-subscription-status-tag>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column  title="Start Date" [width]="100" *ngIf="!thirdPartyUsers">
    <ng-template kendoGridCellTemplate let-subscription>
      {{subscription.startDate | date: 'MM/dd/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column  title="End Date" [width]="100" *ngIf="!thirdPartyUsers">
    <ng-template kendoGridCellTemplate let-subscription>
      {{subscription.endDate | date: 'MM/dd/yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Recipients" [width]="100">
    <ng-template kendoGridCellTemplate let-subscription>
      {{subscription.emails?.length}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column  title="Days (Weekly)" [width]="125" *ngIf="!thirdPartyUsers"></kendo-grid-column>
  <kendo-grid-column [width]="150">
    <ng-template kendoGridCellTemplate let-subscription>
      <app-esn-subscription-actions-bar [subscription]="subscription" [selectedEsns$]="selectedEsns$ | async"></app-esn-subscription-actions-bar>
    </ng-template>
  </kendo-grid-column> 
  <ng-template kendoGridNoRecordsTemplate>
    <div *ngIf="!isLoading" class="custom-no-results-msg">
      <em class="fas fa-seedling" aria-hidden="true"></em>
      <div class="custom-msg-container">
        <div class="no-results">No Associated Suppliers</div>
      </div>
    </div>
    <div *ngIf="isLoading" class="custom-no-results-msg">
      <kehe-phoenix-loading></kehe-phoenix-loading>
    </div>
  </ng-template>     
</kendo-grid> 

<kendo-datapager 
  *ngIf="!isLoading && count$ | async as count"
  [pageSize]="take$ | async"
  [skip]="skip$ | async"
  [total]="count"
  [buttonCount]="4"
  [pageSizeValues]="[25, 50, 100, 250]"
  (pageChange)="onBrokersGridPageChange($event)"
></kendo-datapager>