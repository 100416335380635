import { createAction, props } from '@ngrx/store';
import { SupplierDetail } from '../models/supplier-detail';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';
import { AlertMessage } from '../../../models/alert-message';
import { BrokerDetail } from '../models/broker-detail';
import { ChecklistNavigationTab } from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import { Contact } from '../../../models/contact';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { UncoveredBrandTempClassAlertResponse } from '../models/uncovered-brand-temp-class-alert-response';
import { PendoEvent } from '../models/pendo-event.interface';

export const noOpAction = createAction(
  '[Supplier Detail] No Op'
);
export const replaceKendoBreadCrumbs = createAction(
  '[Supplier Detail] Replace BreadCrumbs',
  props <{ items: BreadCrumbItem[] }>(),
);
export const clearKendoBreadCrumbs = createAction(
  '[Supplier Detail] Clear BreadCrumbs',
);
// Load Supplier
export const loadSupplierDetail = createAction(
  '[Supplier Detail] Load Supplier Detail',
  props<{ esn: string }>()
);
export const loadSupplierDetailSuccess = createAction(
  '[Supplier Detail] Load Supplier Detail Success',
  props<{ supplier: SupplierDetail }>()
);
export const loadSupplierDetailError = createAction(
  '[Supplier Detail] Load Supplier Detail Error',
);
export const loadSupplierRelatedData = createAction(
  '[Supplier Detail] Load Supplier Related Data',
  props<{ esn: string }>()
);
export const checkSupplierBrokerChanged = createAction(
  '[Supplier Detail] Check Supplier Broker Changed',
  props<{ supplierForm: any, supplier: SupplierDetail }>()
);
export const loadSupplierBroker = createAction(
  '[Supplier Detail] Load Supplier Broker',
  props<{ supplier: SupplierDetail }>()
);
export const loadSupplierBrokerSuccess = createAction(
  '[Supplier Detail] Load Supplier Broker Success',
  props<{ broker: BrokerDetail }>()
);
export const loadSupplierBrokerError = createAction(
  '[Supplier Detail] Load Supplier Broker Error',
);
export const clearSupplierBroker = createAction(
  '[Supplier Detail] Clear Supplier Broker',
);
// Load Supplier

// Edit Supplier
export const editSupplier = createAction(
  '[Supplier Detail] Edit Supplier',
);
export const clearEditSupplier = createAction(
  '[Supplier Detail] Clear Edit Supplier',
);
export const supplierFormChanged = createAction(
  '[Supplier Detail] Supplier Form Changed',
  props<{
    form: any,
    isDirty: boolean,
    isSupplierFormValid: boolean,
  }>()
);
export const saveSupplierForm = createAction(
  '[Supplier Detail] Save Supplier Form',
);
export const saveSupplierFormSuccess = createAction(
  '[Supplier Detail] Save Supplier Form Success',
  props<{ supplier: SupplierDetail, form: any }>()
);
export const saveSupplierFormError = createAction(
  '[Supplier Detail] Save Supplier Form Error',
);
export const addSupplierContact = createAction(
  '[Supplier Detail] Add Supplier Contact',
  props<{ contact: Contact }>()
);
export const addSupplierContactSuccess = createAction(
  '[Supplier Detail] Add Supplier Contact Success',
  props<{ contacts: Contact[], created: Contact }>()
);
export const addSupplierContactError = createAction(
  '[Supplier Detail] Add Supplier Contact Error',
  props<{ errorCode: number }>()
);
export const clearSupplierContactForm = createAction(
  '[Supplier Detail] Clear Supplier Contact Form',
);
// Edit Supplier

// Save Warnings
export const confirmCancelEdit = createAction(
  '[Supplier Detail] Confirm Cancel Edit',
);
export const showLeaveUnsavedChangesModal = createAction(
  '[Supplier Detail] Show Leave Unsaved Changes Modal',
  props<{ destinationURL: string }>()
);
export const hideLeaveUnsavedChangesModal = createAction(
  '[Supplier Detail] Hide Leave Unsaved Changes Modal',
);
export const confirmLeaveUnsavedChanges = createAction(
  '[Supplier Detail] Confirm Leave Unsaved Changes',
);
export const clearLeaveUnsavedChangeDestinationURL = createAction(
  '[Supplier Detail] Clear Leave Unsaved Changes Modal',
);
// Save Warnings

// Submit Supplier
export const confirmSubmit = createAction(
  '[Supplier Detail] Confirm Submit',
);
export const cancelSubmit = createAction(
  '[Supplier Detail] Cancel Submit',
);
export const supplierSubmit = createAction(
  '[Supplier Detail] Supplier Submit',
);
export const supplierSubmitSuccess = createAction(
  '[Supplier Detail] Supplier Submit Success',
  props<{ supplier: SupplierDetail }>()
);
export const supplierSubmitError = createAction(
  '[Supplier Detail] Supplier Submit Error',
  props<{ errorCode: number }>()
);
// Submit Supplier

// Tabs & Navigations
export const toggleNavigationPanel = createAction(
  '[Supplier Detail] Toggle Navigation Panel',
);
export const setCurrentTab = createAction(
  '[Supplier Detail] Set Current Tab',
  props<{ tab: SupplierDetailTabs }>()
);
// Tabs & Navigations

// Alerts
export const showAlertMessage = createAction(
  '[Supplier Detail] Show Alert Message',
  props<{ alert: AlertMessage }>()
);
export const hideAlertMessage = createAction(
  '[Supplier Detail] Hide Alert Message'
);
export const hideBrokerV2AlertMessage = createAction(
    '[SUPPLIER BROKER V2 TAB] Hide Broker V2 Alert Message'
);
export const brokerV2NonHqAddedSuccess = createAction(
    '[SUPPLIER BROKER V2 TAB] Broker V2 Non HQ Added Successfully'
);
// Alerts

// Validate Supplier for Draft Checklist NavBar
export const supplierValidated = createAction(
  '[Supplier Detail] Supplier Validated',
  props<{ tabs: ChecklistNavigationTab[] }>()
);
// Validate Supplier for Draft Checklist NavBar

// Purchasing Options
export const loadPurchasingOptionSupplierReviewStatusSuccess = createAction(
  '[Supplier Detail Effect] Check Purchasing Options Status Success',
  props<{ hasAnyPurchasingOptionsToReview: boolean }>()
);
export const loadPurchasingOptionSupplierReviewStatusFromPurOpSuccess = createAction(
  '[Supplier Detail Page] Purchasing Options Status Changed From Pur Ops Lib Success',
  props<{ hasAnyPurchasingOptionsToReview: boolean }>()
);
export const saveUncoveredBrandTempClassAlerts = createAction(
  '[Supplier Detail Page] Purchasing Options Save Uncovered Brand Temp Class Alerts',
  props<{ uncoveredBrandTempClassAlerts: UncoveredBrandTempClassAlertResponse[] }>()
);
// End Purchasing Options

// Calendar Analyst Link
export const loadAnalystCalendarLink = createAction(
  '[Supplier Detail] Load Analyst Calendar Link'
);

export const loadAnalystCalendarLinkSuccess = createAction(
  '[Supplier Detail] Load Analyst Calendar Link Success',
  props<{ url: string }>(),
);

export const clickPurchasingInfoScheduleButton = createAction(
  '[Supplier Detail] Click Purchasing Info Schedule Button'
);

export const clickPurchasingInfoScheduleButtonSuccess = createAction(
  '[Supplier Detail] Click Purchasing Info Schedule Button Success',
  props<{ pendoEvent: PendoEvent }>(),
);
// Broker V2 related actions

/**
 * For when the edit button is clicked
 */
export const showEditBrokerModal = createAction(
    '[Supplier Detail] Show Edit Broker Modal'
);
/**
 * For when the add button is clicked
 */
export const showAddBrokerModal = createAction(
    '[Supplier Detail] Show Add Broker Modal'
);

export const showDeleteBrokerModal = createAction(
  '[Supplier Detail] Show Delete Broker or Document Modal'
);

export const closeBrokerModal = createAction(
    '[Supplier Detail] Close Broker Modal'
);

export const brokerDeleteModalSuccess = createAction(
    '[Supplier Detail] Delete from broker modal success'
);
export const brokerV2LoadNonHeadquarterBrokerSuccess = createAction(
    '[SUPPLIER DETAIL - BROKER V2 NON HQ STORE] Load non headquarter broker success'
);

export const updateSupplierFormAndSave = createAction(
  '[Supplier Detail] Update Supplier Form and Save Changes',
  props<{
    form: any,
    isDirty: boolean,
    isSupplierFormValid: boolean,
    shouldNotShowSuccessToast: boolean
  }>()
);
export const showAddBrokerModalWithSkipOption = createAction(
    '[Supplier Detail] Show Add Broker Modal With Skip Option'
);

export const loadPurchasingOptionsHelpGuideUrl = createAction(
  '[Supplier Detail] Load Purchasing Options Help Guide',
);
