<div [@drawerStatus]="status" class="d-flex flex-column ms-3" [ngClass]="chatRoom?.status === 'Opened' ? 'chat-drawer' : 'chat-drawer-collapsed'">
  <div [id]="'drawer-header'" class="d-flex flex-row mx-3 justify-content-between align-items-center header" (click)="toggleCollapseChat(chatRoom)">
    <div class="d-flex flex-row align-items-center">
      <em class="fas icon" [ngClass]="source === 'Retailer' ? 'fa-dolly' : 'fa-shopping-basket'" aria-hidden="true"></em>
      <div class="d-flex flex-column m-0 ms-2">
        <h4 class="drawer-header-text mb-0">
          {{!!messagesToRender?.length || chatRoom?.loading ? source === 'Retailer' ? chatRoom?.supplierName :  chatRoom?.customerName : 'New Message'}}
        </h4>
        <div *ngIf="source === 'Supplier'" class="supplier-info">
          {{chatRoom?.supplierName}} | ESN {{chatRoom?.supplierNumber}}
        </div>
      </div>
    </div>
    <em *ngIf="chatRoom?.clientNumberUnread === chatRoom?.supplierNumber && chatRoom?.status ==='Collapsed'" class="unread-icon"></em>
    <em class="far fa-times" title="Close" (click)="closeChatRoom($event, chatRoom.id)"></em>
  </div>
  <div *ngIf="chatRoom?.status === 'Opened'" class="panel-divider"></div>
  <div *ngIf="chatRoom?.status === 'Opened'" [id]="'drawer-body'" class="body mx-3" #scrollToBottom (scroll)="onScroll($event)">
    <div *ngIf="!messagesToRender?.length" class="d-flex h-100 align-items-center flex-column justify-content-center">
      <div [id]="'drawer-no-messages'" *ngIf="!chatRoom?.loading" class="d-flex h-100 align-items-center flex-column justify-content-center">
        <em class="fas" [ngClass]="source === 'Retailer' ? 'fa-dolly' : 'fa-shopping-basket'" aria-hidden="true"></em>
        <div class="destination-name my-2">{{chatRoom?.customerName}}</div>
        <div class="detail">Start a message with this retailer.</div>
      </div>
      <div [id]="'drawer-loading-messages'" *ngIf="chatRoom?.loading">
        <kehe-phoenix-loading></kehe-phoenix-loading>
      </div>
    </div>
    <div *ngIf="messagesToRender?.length">
      <div *ngIf="chatRoom.loadingMoreMessages" class="my-3">
        <kehe-phoenix-loading></kehe-phoenix-loading>
      </div>
      <div *ngFor="let messageToRender of messagesToRender" class="d-flex flex-column">
        <div [id]="'message-to-render-date-' + messagesToRender?.date" class="dateToRender mt-2">{{messageToRender?.date}}</div>
        <div *ngFor="let message of messageToRender?.messages" [id]="'message-' + message?.id">
          <app-chat-bubble [message]="message"></app-chat-bubble>
        </div>
      </div>
      <div class="mb-3"></div>
    </div>
  </div>
  <div *ngIf="chatRoom?.status === 'Opened'" [id]="'drawer-footer'" class="footer">
    <form [formGroup]="inputForm" class="mx-3 d-flex h-100 align-items-end">
      <kendo-textarea
        class="text-input"
        resizable="auto"
        placeholder="Start message"
        formControlName="textMessage"
        (keyup.enter)="submit()"
        (blur)="onBlurMethod()"
        [maxlength]="MAX_TEXT_MESSAGE_LENGTH">
      </kendo-textarea>
      <div class="send-button d-flex justify-content-center align-items-center" [ngClass]="inputForm?.controls?.textMessage?.value?.length > 0 ? 'send-button-enabled' : 'send-button-disabled'" (click)="submit()">
        <em class="fas fa-arrow-up"></em>
      </div>
    </form>
  </div>
</div>
