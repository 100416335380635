import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FsmaComplianceService } from "./fsma-compliance-banner.service";
import { FsmaComplianceApiStore } from "./fsma-compliance-banner.api-store";
import { FsmaComplianceStore } from "./fsma-compliance-banner.store";
import { IAlertEvent, PhoenixAlertsModule } from "@kehe/phoenix-notifications";

@Component({
  selector: 'app-fsma-compliance-banner',
  templateUrl: './fsma-compliance-banner.component.html',
  styleUrls: ['./fsma-compliance-banner.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PhoenixAlertsModule,
  ],
  providers: [
    FsmaComplianceStore,
    FsmaComplianceApiStore,
    FsmaComplianceService,
  ],
})
export class FsmaComplianceBannerComponent {

  @Input() showViewButton: boolean;
  @Input() showInstructionsText: boolean;

  constructor(
    private _fsmaComplianceStore: FsmaComplianceStore
  ) {}

  vm$ = this._fsmaComplianceStore.vm$;

  bannerClick(event: IAlertEvent) {
    this._fsmaComplianceStore.bannerClick(event);
  }
}