import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SupplierDetailState,
    supplierDetailStateKey
} from './supplier-detail.reducer';
import { isDraft, isInvitedDraftSupplier } from '../utils/supplier-utils';
import { ChecklistNavigationTabStatus } from '@app/shared/components/checklist-navigation-panel/checklist-navigation-panel.component';
import { selectCurrentRoute, selectUrl } from '@app/shared/state/router/router.selectors';
import { BrokerOpenedModals } from '@app/modules/supplier-onboarding/enums/broker-opened-modal.enum';
import { AlertTypes } from '@kehe/phoenix-notifications';
import { SupplierDetailTabs } from '../models/supplier-detail-tabs';
import { PoAlertType, UncoveredBrandTempClassAlertResponse } from '../models/uncovered-brand-temp-class-alert-response';
import { Constants } from '@app/constants/constants';

export const getSupplierDetailState =
    createFeatureSelector<SupplierDetailState>(supplierDetailStateKey);

// Navigation
export const selectCurrentTab = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.currentTab
);
export const selectDisplayTabPanel = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.displayTabPanel
);
// Navigation

// Details
export const selectSupplierDetail = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => (state === undefined ? null : state.detail)
);

export const selectSupplierType = createSelector(
    selectSupplierDetail,
    (detail) => detail.supplierType
);

export const selectSupplierBroker = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.broker
);
export const selectIsDraftSupplier = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => isDraft(state.detail)
);
export const selectIsInvitedDraftSupplier = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => isInvitedDraftSupplier(state?.detail)
  );
export const selectSupplierCreatedTime = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state?.detail?.created
);
export const selectIsSupplierOnboardingV4FeatureON = createSelector(
  selectSupplierCreatedTime,
  (createdDate) => {
    if (!createdDate) {
      return false; // Default value if createdDate is undefined/null
    }
    const created = new Date(createdDate);
    return created > Constants.FeatureDates.SupplierOnboardingV4;
  }
);
export const selectIsLoadSupplierBrokerError = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.isLoadSupplierBrokerError
);
export const selectSupplierBrokerLoading = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.brokerLoading
);
export const selectSupplierDetailsLoading = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.supplierLoading
);
// Details

// Edit Supplier
export const selectEditMode = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.editMode
);
export const getSupplierForm = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.supplierForm
);
export const selectSupplierFormIsDirty = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) =>
        state.supplierFormIsDirty
);
export const selectIsSupplierFormValid = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) =>
        state.supplierFormIsValid
);
export const selectIsSupplierFormSaving = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.supplierFormIsSaving
);
export const selectShowLeaveUnsavedChangesModal = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.showLeaveUnsavedChangesModal
);
export const selectLeaveUnsavedChangesDestinationURL = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.leaveUnsavedChangesDestinationURl
);
export const selectIsSavingContact = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.isSavingContact
);
export const selectNewlyAddedContact = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.newlyAddedContact
);
export const selectSaveContactError = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.saveContactError
);
// Edit Supplier

// Submit Supplier
export const selectShowSubmitConfirmation = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.showSubmitConfirmation
);
export const selectSubmitSupplierError = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.submitSupplierError
);
export const selectSubmitSupplierDocumentsRequiredError = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.submitSupplierDocumentsRequiredError
);
// Submit Supplier

// Header Alerts
export const selectAlertMessage = createSelector(
    getSupplierDetailState,
    selectCurrentTab,
    (state: SupplierDetailState, currentTab: SupplierDetailTabs) => {
        if (currentTab !== SupplierDetailTabs.broker) {
            return state.alertMessage;
        }
    }
);
export const selectDeleteHQBrokerFailed = createSelector(
    getSupplierDetailState,
    selectCurrentTab,
    (state: SupplierDetailState, currentTab: SupplierDetailTabs) => {
        return (
            currentTab === SupplierDetailTabs.broker &&
            state.brokerOpenedModal === BrokerOpenedModals.delete &&
            state.alertMessage.type === AlertTypes.Error
        );
    }
);
export const selectHasApiFailure = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.alertMessage.type === AlertTypes.Error
);
// Header Alerts

// Purchasing Options
export const selectHasAnyPurchasingOptionsToReview = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.hasAnyPurchasingOptionsToReview
);

export const selectIsPurchasingOptionsListPage = createSelector(
    selectCurrentRoute,
    selectUrl,
    (currentRoute, url) => {
        return (
            currentRoute.routeConfig.path === '' &&
            Boolean(url.match(/\/supplier\/\d*\/purchasing-options/))
        );
    }
);
export const selectDisplayHasPurchasingOptionsToReviewBanner = createSelector(
    selectHasAnyPurchasingOptionsToReview,
    selectIsPurchasingOptionsListPage,
    (hasPurchasingOptionsToReview, isPurchasingOptionsListPage) =>
        hasPurchasingOptionsToReview && isPurchasingOptionsListPage
);

export const selectUnCoveredBrandsTempClassAlerts = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.poUncoveredBrandTempClassAlerts
);

export const selectUnCoveredBrandsTempClassErrorAlert = createSelector(
    selectUnCoveredBrandsTempClassAlerts,
    selectIsPurchasingOptionsListPage,
    (alerts: UncoveredBrandTempClassAlertResponse[],  isPurchasingOptionsListPage: boolean) => {
        const errorAlert = alerts?.find(item => item.alertType === PoAlertType.Error);
        return getBrandTempClassAlert(isPurchasingOptionsListPage, errorAlert, AlertTypes.Error);
    }
);

export const selectUnCoveredBrandsTempClassWarningAlert = createSelector(
    selectUnCoveredBrandsTempClassAlerts,
    selectIsPurchasingOptionsListPage,
    (alerts: UncoveredBrandTempClassAlertResponse[],  isPurchasingOptionsListPage: boolean) => {
        const warningAlert = alerts?.find(item => item?.alertType === PoAlertType.Warning);
        return getBrandTempClassAlert(isPurchasingOptionsListPage, warningAlert, AlertTypes.Warning);
    }
);

function getBrandTempClassAlert(isPurchasingOptionsListPage: boolean, alert: UncoveredBrandTempClassAlertResponse, alertType: AlertTypes) {
    if (isPurchasingOptionsListPage && alert != null) {
        let items = alert.brandTempClassList.map(brand => `<li>${brand}</li>`).join('');
        return { messageAsHtml: `<p>${alert.alertMessage}</p> <ul>${items}</ul>`, show: true, type: alertType };
    } else {
        return null;
    }
}

// Purchasing Options
// Utils
export const selectSupplierValidatedTabs = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.supplierValidatedTabs
);

export const selectLegalSignaturesTabStatus = createSelector(
  selectSupplierValidatedTabs,
  (tabs) => {
    const tab = tabs.find(t => t.tab === SupplierDetailTabs.legalSignatures);
    return tab.status === ChecklistNavigationTabStatus.checked
  }
);

// Analyst Calendar Link
export const selectAnalystCalendarLink = createSelector(
    getSupplierDetailState,
    ({ analystCalendarLink }) => analystCalendarLink
);

// Utils
// Broker v2 Modals
export const selectBrokerOpenedModalState = createSelector(
    getSupplierDetailState,
    (state) => state.brokerOpenedModal
);
export const selectBrokerModalIsOpened = createSelector(
    selectBrokerOpenedModalState,
    (brokerOpenedModal) =>
        brokerOpenedModal === BrokerOpenedModals.addBroker ||
        brokerOpenedModal === BrokerOpenedModals.editBroker ||
        brokerOpenedModal === BrokerOpenedModals.addBrokerWithSkipOption
);
export const selectDeleteBrokerModalIsOpen = createSelector(
    selectBrokerOpenedModalState,
    (brokerOpenedModal) => brokerOpenedModal === BrokerOpenedModals.delete
);
export const selectIsAddBrokerModal = createSelector(
    selectBrokerOpenedModalState,
    (brokerOpenedModals) => brokerOpenedModals === BrokerOpenedModals.addBroker
);
export const selectShouldSaveAfterUpdateForm = createSelector(
    getSupplierDetailState,
    (state) => state.shouldSaveAfterUpdateForm
);
export const selectShouldNotShowSuccessToast = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.shouldNotShowSuccessToast
);
export const selectBrokerModalOpenedWithSkip = createSelector(
    selectBrokerOpenedModalState,
    (brokerOpenedModal) =>
        brokerOpenedModal === BrokerOpenedModals.addBrokerWithSkipOption
);
