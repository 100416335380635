<ng-container *ngIf="state$ | async as vm">
  <div class="retailer-combobox-label margin-top-8">{{label}}</div>
  <kendo-combobox
    data-automation-id="{{ automationId }}"
    [data]="vm.customers"
    textField="name"
    valueField="dcCustomerNumber"
    [value]="vm.selectedCustomer"
    [loading]="vm.isLoading"
    [valuePrimitive]="false"
    [placeholder]="placeholder"
    [filterable]="true"
    (filterChange)="handleFilterCustomer($event)"
    (valueChange)="handleCustomerSelected($event)"
  >
    <ng-template kendoComboBoxItemTemplate let-customer>
      <div
        class="kendo-dropdown-list-item-template"
        [ngStyle]="{
          overflow: 'hidden',
          'text-overflow': 'ellipsis'
        }"
      >
        <div class="item-header">{{ customer?.name }}</div>
        <div
          [ngStyle]="{
            overflow: 'hidden',
            'text-overflow': 'ellipsis'
          }"
          title="{{ customer?.dcCustomerNumber }}"
        >
          {{ customer?.dcCustomerNumber }}
          <kehe-phoenix-tag
            *ngIf="customer?.isKCDCustomer"
            [tag]="{
              text: 'CONNECT Direct',
              bgColor: '#FFFFFF',
              borderColor: '#BBB'
            }"
          ></kehe-phoenix-tag>
        </div>
      </div>
    </ng-template>
    <ng-template
      *ngIf="vm.customers?.length >= longListCount"
      kendoComboBoxFooterTemplate
    >
      <kendo-formhint class="col-sm-12">
        Search results are limited to {{ longListCount }} items
      </kendo-formhint>
    </ng-template>
  </kendo-combobox>
</ng-container>
