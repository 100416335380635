import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { environment } from '../environments/environment';
import { Event, NavigationEnd, Router } from '@angular/router';
import { DestroyableDirective } from './abstract/destroyable';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { ResourceActions } from './constants/resource-actions';
import { PermissionsService } from '@app/services/permissions.service';
import { UserNotificationsFacade } from './modules/user-notifications/store/user-notifications.facade';
import { MessagesFacade } from './modules/messages/store/messages.facade';
import { IChatRoom } from './modules/messages/models/IChatRoom';
import { GoogleAnalyticsService } from './services/googleAnalytics.service';
import { datadogRum } from '@datadog/browser-rum';
import { Environment } from './../environments/environment-enum';
import { UntypedFormControl } from '@angular/forms';
import { ShowModel, ShowStatus } from './models/show-models';
import { IPermissions } from './models/ipermissions';
import { Constants } from './constants/constants';
import { BreakpointService } from '@kehe/phoenix-utils';
import { selectFormattedUserNotificationCards } from './modules/user-notifications/store/user-notifications.selectors';
import { FeedbackStore } from '@kehe/phoenix-feedback';
import { selectConfigurableFeatureFlagIfOn } from './shared/state/feature-flag/feature-flag.selectors';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import * as AppActions from './app.actions';
import { selectIsFeatureFlagOn } from '@app/shared/state/feature-flag/feature-flag.selectors';
import { FeatureFlags } from './feature-flag/index';
import { ManageProgressFeatureOption, PhoenixManageProgressPanelFacadeService } from '@kehe/phoenix-top-bar';
import { TopNavProfileOptionName } from './enum/top-nav-profile-option-name.enum';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';
import * as AppSelectors from './app.selectors';
import { selectIsShowIdFromUrlValid } from './modules/orders-show/list/pages/orders-list-page-show/orders-list-page-show.selectors';

declare let ga: any;
declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroyableDirective  implements OnInit {
  env: any = environment;
  // create local version of Logout, so that we can hold the event
  profileOptions: any[];

  title = 'app';
  isFeedbackDown = false;
  popNoCustomersFoundModal = false;
  public userEmail: string;
  public userName$: Observable<string>;
  public userFirstName$: Observable<string>;
  public userLastName$: Observable<string>;
  public userActions$: Observable<Array<string>>;
  public isOnCallbackPage: boolean;
  public userActions: any;
  public showMessagingComponent = false;
  public headerChats: Array<IChatRoom>;
  public userFullName$: Observable<string>;
  public showSuppliers$ = this._store.select(ShowSelectors.selectSuppliers);
  public messagingSource = 'Supplier';
  public unreadCount: number;
  public topbarColor = '';
  public topbarTitle = 'Supplier';
  searchControl = new UntypedFormControl('');
  public notificationShowGoToButton = false;
  public currentShow: ShowModel;
  public showGlobalAlert = false;
  public targetDate: Date;
  public isLargeOrBelow = false;
  priorityEventToDisplay$ = this._store.select(ShowSelectors.selectPriorityEventToDisplay);
  showCountdownView$ = this._store.select(AppSelectors.selectShowCountdownView);

  @ViewChild('mainContainer', { read: ViewContainerRef, static: true }) _vcr;

  userNotifications$ = this.store.select(selectFormattedUserNotificationCards)
  userNotificationCount$: Observable<number>;
  isClearNotificationsOpen$: Observable<boolean>;

  displaySupplierOutageBanner$ = this._store.select(selectConfigurableFeatureFlagIfOn<{ message: string }>(FeatureFlags.SupplierOutageBanner.key));
  outageAlertClosed = false;

  constructor(
    public router: Router,
    public _store: Store,
    private permissionsService: PermissionsService,
    private userNotificationsFacade: UserNotificationsFacade,
    private store: Store,
    private _messagesFacade: MessagesFacade,
    private googleAnalyticsService: GoogleAnalyticsService,
    private permissions: PermissionsService,
    private _breakpointService: BreakpointService,
    private feedbackStore: FeedbackStore,
    private _manageProgressFacade: PhoenixManageProgressPanelFacadeService
  ) {
    super();
    // set any default profile menu options here:
    this.profileOptions = [
      ManageProgressFeatureOption,
      { name: 'Logout', data: '', icon: null }
    ];
    // set google analytics
    if (environment.ga) {
      // run one time to setup the google analytics property id
      ga('create', environment.ga, 'auto');

      // check each route change and send google tracking event
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      });

      // add e-commerce tag
      ga('require', 'ec');
    }
  }

  isSmallOrBelow$ = this._breakpointService.isSmallAndBelow$;

  ngOnInit() {
    this.userFirstName$ = this._store.select(AuthenticationSelectors.selectUserFirstName);
    this.userNotificationsFacade.loadUserNotificationsCount();
    this.userNotificationCount$ = this.userNotificationsFacade.getUserNotificationCount();
    this.isClearNotificationsOpen$ = this.userNotificationsFacade.isClearNotificationsModalOpen();
    this.userFullName$ = this._store.select(AuthenticationSelectors.selectUserFullName);

    this.beginLogging();

    this._store.pipe(select(AuthenticationSelectors.selectUserEmail)).subscribe(val => {
      this.userEmail = val;
      if (this.userEmail) {
        ga('set', 'dimension1', this.userEmail);
        ga('send', 'pageview');

        this.setDataDogUser(this.userEmail);
        this._manageProgressFacade.setUserEmail(this.userEmail);
      }
    });

    this._manageProgressFacade.setManageProgressApi(
      environment.manageProgressApi
    );

    combineLatest([
      this._store.select(selectIsFeatureFlagOn(FeatureFlags.Messaging.key)),
      this._store.select(ShowSelectors.selectIsSupplierInShow),
      this._store.select(selectIsFeatureFlagOn(FeatureFlags.ShowImpersonation.key)),
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([messagingFeatureFlag, supplierIsInShow, impersonationFeatureFlag]) => {
      const userPermissions: IPermissions = {
        impersonate: this.permissions.userHasAction(Constants.UserActions.ProductImpersonate)
      };
      if ((messagingFeatureFlag && supplierIsInShow) && (!impersonationFeatureFlag && !userPermissions?.impersonate)) {
        this.showMessagingComponent = true;
        this._messagesFacade.selectHeaderChatsFiltered$.pipe(takeUntil(this.destroy$))
          .subscribe(headerChats => {
            this.headerChats = headerChats;
          });

        this._messagesFacade.selectUnreadCount$.pipe(takeUntil(this.destroy$))
          .subscribe(unreadCount => {
            this.unreadCount = unreadCount;
          });
      } else {
        this.showMessagingComponent = false;
      }

    });

    combineLatest([
      this._store.select(ShowSelectors.selectIsSupplierInShow),
      this._store.select(ShowSelectors.selectCurrentShow),
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([supplierIsInShow, currentShow]) => {
      this.notificationShowGoToButton = currentShow?.status === ShowStatus.Live;
      if (supplierIsInShow && currentShow) {
        this.currentShow = currentShow;
        this.showGlobalAlert = true;
      } else {
        this.showGlobalAlert = false;
      }
    });

    combineLatest([
      this._breakpointService.isXtraLargeView$,
      this._breakpointService.isXtraXtraLargeView$,
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([isExtraLargeView, isExtraExtraLargeView]) => {
      this.isLargeOrBelow = !isExtraLargeView && !isExtraExtraLargeView;
    });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(AuthenticationSelectors.selectUserActions))
    .subscribe(res => {
      if (res) {
        this.userActions = res;
        if (this.userActions) {
            this.checkSiteAccess();
        }
      }
    });

    this.router.events.pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((event: Event) => {
      this.footPosition(event);
    });


    const myProfileIndex = this.profileOptions.findIndex(
      (option) => option.data === 'my-profile'
    );
    if (myProfileIndex === -1) {
      this.profileOptions.unshift({
        name: 'My Profile',
        data: 'my-profile',
        icon: null
      });
    }

    combineLatest([
      this._store.select(selectIsShowIdFromUrlValid),
      this._store.select(ShowSelectors.selectSelectedShow),
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([isShowIdValid, selectedShow]) => {
      this.setShowTopBar(isShowIdValid, selectedShow);
    });

    this.searchControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(searchValue => {
      this._messagesFacade.setFilterHeaderChatsText(searchValue);
    });
  }

  handleFeedbackClick(): void {
    this.feedbackStore.openModal();
  }

  setShowTopBar(isShowIdValid, selectedShow) {
    if (selectedShow?.status === 'Live' && isShowIdValid) {
      this.topbarColor = '#4B4370';
      this.topbarTitle = `Supplier - ${selectedShow.name}`;
    } else {
      this.topbarColor = '';
      this.topbarTitle = 'Supplier';
    }
  }

  footPosition(route) {
    if (route.routerEvent && route.routerEvent.url.indexOf('/callback') > -1) {
      this.isOnCallbackPage = true;
    } else {
      this.isOnCallbackPage = false;
    }
  }

  handleClose() {
    this.showGlobalAlert = false;
  }

  userSectionClick(profileOption: { name: TopNavProfileOptionName, data: any }) {
    switch (profileOption.name) {
      case TopNavProfileOptionName.Logout:
        this.store.dispatch(AppActions.logOut());
        break;
      case TopNavProfileOptionName.MyProfile:
        this.router.navigate([profileOption.data]);
        break;
      case TopNavProfileOptionName.ManageProgress:
        this._manageProgressFacade.openManageProgressPanel();
        break;
    }
  }
  
  private checkSiteAccess(): void {
    if (!this.doesUserHaveResourceAction(ResourceActions.SupplierSiteAccess)) {
      this.store.dispatch(AppActions.logOut());
    }
  }

  private doesUserHaveResourceAction(resourceAction: string): boolean {
    return this.permissionsService.userHasAction(resourceAction);
  }

  handleHelpClick($event) {
    this.router.navigate(['/show/help']);
  }

  onNotificationIconClick(shouldDisplayNotificationPanel: boolean): void {
    if (shouldDisplayNotificationPanel) {
      this.userNotificationsFacade.loadUserNotifications();
      this.userNotificationsFacade.loadUserNotificationsCount();
    }
  }

  onNotificationRedirect(event: {url: string, notificationId: number}): void {
    const notificationIds: number[] = [event.notificationId];
    this.userNotificationsFacade.clearUserNotifications(notificationIds);
    window.open(event.url, '_blank');
  }

  onClearNotifications(notificationIds: number[]): void {
    this.userNotificationsFacade.clearUserNotifications(notificationIds);
  }

  onClearAllNotifications(): void {
    this.userNotificationsFacade.toggleClearNotificationsModal();
  }

  handleSelectChatRoom($event: IChatRoom) {

    this.googleAnalyticsService.eventEmitter(
      'show',
      'top-bar-open-messaging',
      'open-messaging',
      1
    );

    this._messagesFacade.openChat(
      $event.customerNumber,
      $event.supplierNumber,
      $event.customerName,
      $event.supplierName,
    );
  }

  handleNewMessageButtonClick($event) {
    this._messagesFacade.createNewChat();
  }

  beginLogging(): void {
    switch (environment.current) {
      case Environment.UAT:
        datadogRum.init({
          applicationId: '7d22fc98-b19c-489a-951c-142e376289a1',
          clientToken: 'pub11fe46d4003ac76071c3119461b22acb',
          site: 'datadoghq.com',
          service: 'connect-supplier',
          env: 'uat',
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel: 'mask-user-input',
          workerUrl: 'datadog_scripts/worker.js',
        });

        datadogRum.startSessionReplayRecording();
        break;

      case Environment.PROD:
        datadogRum.init({
          applicationId: '7d22fc98-b19c-489a-951c-142e376289a1',
          clientToken: 'pub11fe46d4003ac76071c3119461b22acb',
          site: 'datadoghq.com',
          service: 'connect-supplier',
          env: 'prod',
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel: 'mask-user-input',
          workerUrl: 'datadog_scripts/worker.js',
        });

        datadogRum.startSessionReplayRecording();
        break;

      default:
    }
  }

  setDataDogUser ( userEmail ) {
    datadogRum.setUser({
      email: userEmail.toLowerCase()
    });
  }

  get isUserAgreementEnabled$(): Observable<boolean> {
    return this._store.select(selectIsFeatureFlagOn(FeatureFlags.UserAgreement.key));
  }

  handleCloseOutageAlert() {
    this.outageAlertClosed = true;
  }
}
