<app-supplier-dc-brand-filter
  #suppierDcBrandFilter
  (filterChanged)="onSupplierDcBrandChanged($event)"
>
</app-supplier-dc-brand-filter>

<div class="container">
  <div class="row mb-3">
    <div class="col-sm-12">
      <div class="d-flex">
        <kendo-label>Retailer Area</kendo-label>
        <div class="ms-auto">
          <app-select-all (clicked)="selectAllRetailerAreas()"></app-select-all>
        </div>
      </div>
      <kendo-multiselect 
        name="select-chain-group"
        id="select-chain-group"
        placeholder="Select Retailer Area"
        [data]="retailerAreaNameOptions"
        valueField="id"
        textField="name"
        [kendoDropDownFilter]="{ operator: 'contains'}"
        [autoClose]="false"
        [(ngModel)]="selectedRetailerAreaList"
        [tagMapper]="tagMapper"
        [virtual]="{ itemHeight: 24 }"
        (ngModelChange)="onChainGroupSelected()"
      ></kendo-multiselect>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-sm-12 channel">
      <label>Channel</label>
      <kendo-dropdownlist
        [data]="channels"
        valueField="value"
        textField="label"
        [(ngModel)]="selectedChannel"
        (ngModelChange)="onChannelChanged()"
    ></kendo-dropdownlist>
    </div>
  </div>
</div>
