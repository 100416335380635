import { Message } from './../service/API.service';
import { IMessage } from './../models/IMessage';
import { IChatRoom } from './../models/IChatRoom';
import { IChatRoomGraphQL } from './../models/IChatRoomGraphQL';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MessagesActions = createActionGroup({
  source: 'Messages',
  events: {
    'Get Chat Room By ESN Success': props<{ chatRooms: Array<IChatRoom>, earliestShowStartDate: string }>(),
    'Create New Chat': emptyProps(),
    'Close New Chat': emptyProps(),
    'Open Chat': props<{ customerNumber: string, supplierNumber: string, customerName: string, supplierName: string }>(),
    'Open Chat Success New': props<{ chatRoom: IChatRoom }>(),
    'Open Chat Success Existing': props<{ chatRoom: IChatRoomGraphQL, userEmail: string }>(),
    'Send Message': props<{ message: string, chatId: string, userFullName: string, userEmail: string }>(),
    'Send Message Success': props<{ message: IMessage, chatRoomID: string }>(),
    'Send Message With Chat Already Created': props<{ messageBeingSend: IMessage, chatMessageBeingSend: IChatRoom, newChatRoom: IChatRoom }>(),
    'Send Message With Chat Already Created Success': props<{ message: IMessage, chatRoom: IChatRoom }>(),
    'Receive Message': props<{ message: Message }>(),
    'Receive Chat Room Update': props<{ chatRooms: Array<IChatRoom>, userEmail: string }>(),
    'Create Chat Room': props<{ messageBeingSend: IMessage, chatMessageBeingSend: IChatRoom }>(),
    'Close Chat': props<{ chatId: string }>(),
    'Toggle Collapse Chat': props<{ chatRoom: IChatRoom }>(),
    'Read Message From Collapsed Chat': props<{ chatRoom: IChatRoom }>(),
    'Subscription Created': emptyProps(),
    'Get Messages Success': props<{ messages: Array<IMessage>, nextTokenMessages: string, chatRoom: IChatRoomGraphQL, userEmail: string }>(),
    'Get More Messages': props<{ chatRoom: IChatRoom }>(),
    'Get More Messages Success': props<{ messages: Array<IMessage>, nextTokenMessages: string, chatRoom: IChatRoom, userEmail: string }>(),
    'Get Unreads By Supplier Number Success': props<{ unreads: Array<{ items: Array<IChatRoom>, scannedCount: number }> }>(),
    'Notify Chat Is Read Success': props<{ chatRoom: IChatRoom }>(),
    'Save Draft Message Chat': props<{ chatId: string, draftMessage: string }>(),
    'Set Filter Header Chats Text': props<{ text: string }>(),
  }
});