import { Supplier } from './../../../models/supplier';
import { Observable } from 'rxjs';
import { SubscriptionFilterChangedEvent } from './../../../models/subscription-filter-changed-event';
import { CreateSubscriptionFilterComponent } from './../../../models/create-subscription-filter-component';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Brand } from '@app/modules/product-detail/models/brand';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { selectSupplierOptions } from '../../../store/data-exchange.selectors';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { SubscriptionFilter } from '../../../models/subscription-filter';
import { SubscriptionFilterKey } from '../../../models/enum/subscription-filter-key';
import { DataExchangeService } from '../../../services/data-exchange.service';
import * as DistributionCenterSelectors from '@app/shared/state/distribution-center/distribution-center.selectors';
import { DistributionCenter } from '@app/distribution-center/distribution-center';

@Component({
  selector: 'app-supplier-dc-brand-filter',
  templateUrl: './supplier-dc-brand-filter.component.html',
  styleUrls: []
})
export class SupplierDcBrandFilterComponent extends DestroyableDirective  implements OnInit, CreateSubscriptionFilterComponent {

  dcOptions: DistributionCenter[] = [];
  supplierOptions$: Observable<Supplier[]>;
  brandOptions: Brand[] = [];

  selectedSupplier: Supplier = null;
  selectedDcList: DistributionCenter[] = [];
  selectedBrandList: Brand[] = [];
  isBrandsLoading = false;

  @Output()
  filterChanged = new EventEmitter<SubscriptionFilterChangedEvent>();

  constructor(
    protected _store: Store,
    protected dataExchangeService: DataExchangeService,
    private ref: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this._store.pipe(
      takeUntil(this.destroy$),
      select(DistributionCenterSelectors.selectDistributionCenterListWithTrimmedName),
    ).subscribe(dcs => {
      this.dcOptions = dcs;
    });

    this.supplierOptions$ = this._store.pipe(select(selectSupplierOptions));
  }

  reset() {
    this.selectedDcList = [];
    this.selectedSupplier = null;
    this.selectedBrandList = [];
    this.publishFilterChangedEvent();
  }

  onSupplierSelected() {
    this.updateBrand();
    this.publishFilterChangedEvent();
  }

  updateBrand() {
    this.selectedBrandList = [];

    if (this.brandEnabled()) {
      this.isBrandsLoading = true;

      this.dataExchangeService.getBrandsBySupplier(this.selectedSupplier.esn).pipe(
        takeUntil(this.destroy$)
      ).subscribe(brands => {
        this.ref.markForCheck();
        this.isBrandsLoading = false;
        this.brandOptions = brands;
      },
      (error) => {
        this.isBrandsLoading = false;
      });
    }
  }

  selectAllDcs(): void {
    if (this.selectedDcList.length === this.dcOptions.length) { return; }
    this.selectedDcList = this.dcOptions;
    this.onDcSelected();
  }

  onDcSelected() {
    this.updateBrand();
    this.publishFilterChangedEvent();
  }

  selectAllBrands(): void {
    if (!this.brandEnabled() || this.selectedBrandList.length === this.brandOptions.length) { return; }
    this.selectedBrandList = this.brandOptions;
    this.onBrandSelected();
  }

  onBrandSelected() {
    this.publishFilterChangedEvent();
  }

  brandEnabled() {
    return this.selectedDcList.length > 0 && !!this.selectedSupplier;
  }

  isFormValid(): boolean {
    return !!this.selectedDcList &&
    this.selectedDcList.length > 0 &&
    !!this.selectedSupplier &&
    !!this.selectedBrandList &&
    this.selectedBrandList.length > 0;
  }

  getFilters(): SubscriptionFilter[] {
    return [
      { key: SubscriptionFilterKey.Dc, value: this.selectedDcList.map(dc => dc.number) },
      { key: SubscriptionFilterKey.BrandCode, value: this.selectedBrandList.map(brand => brand.code) }
    ];
  }

  publishFilterChangedEvent(): void {
    const formValid = this.isFormValid();

    this.filterChanged.emit({
      filtersValid: formValid,
      selectedSupplier: this.selectedSupplier,
      filters: formValid ? this.getFilters() : [],
    });
  }

  tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }

}
