export enum ResourceActions {
    SupplierSiteAccess = 'Supplier_Site',
    SupplierBiDataRead = 'SupplierBiDataEmbed_Read',
    PriceManagementAccess = 'PriceManagement_Access',
    SupplierAnalyticsRead = 'SupplierAnalytics_Read',
    SSRSReportsBaseRead = 'SSRSReportsBase_Read',
    SSRSReportsEnhancedRead = 'SSRSReportsEnhanced_Read',
    SupplierProductV2 = 'Product_Read',
    ThirdPartyOfRecord = 'ThirdPartyOfRecord_Read',
    CustomPromotionsRead = 'Custom_Promotions_Read'
}
