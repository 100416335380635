<kehe-phoenix-alert *ngIf="hasTransactionSucceeded$ | async" type="success" [show]="true" [stick]="true"
  [message]="alertMessage" (alertEvent)="onSuccessfulTransactionAlertEvent($event)"></kehe-phoenix-alert>
<div class="row">
  <div class="col d-flex justify-content-end">
    <kehe-phoenix-button class="help-button" name="help-button" classAttr="btn-medium btn-link"
      iconClassAttr="fa fa-question" (click)="showHelpModal = true;">Help</kehe-phoenix-button>
  </div>
</div>
<kehe-phoenix-alert show="true" type="custom" bgColor="#E5EDF3" *ngIf="!thirdPartyUsers">
  <div content class="d-flex align-items-center">
    <i class="far fa-bullhorn pe-3" style="font-size:2rem;"></i>
    <div>
      <strong>Introducing an API-Like Tool Built to Enhance Data Reporting</strong>
      <div>Many Brokers have shared interest in an easy and automated way to access their suppliers’ reporting. KeHE
        heard the ask and created a solution using KeHE CONNECT BI Data Exchange for Brokers™, adding a second level of
        automation to the existing time-saving tool! <a
          href="mailto:DL-Connect.Supplier_Feedback@kehe.com?Subject=Request for CONNECT BI Data Exchange for Brokers Enhancement">Contact
          us</a> to receive the API-like tool and get started! Click <a href="https://joom.ag/JhCd"
          target="_blank">here</a> to learn more.</div>
    </div>
  </div>
</kehe-phoenix-alert>
<div class="row mt-3">
  <div class="col">
    <h4>Data Exchange for {{ companyName$ | async }}</h4>
    <div>Looking to have access to your Supplier Data? You are now eligible to subscribe to the below suppliers. <a
        [routerLink]="[]" (click)="onSubscriptionOverviewClick()">What's included in the Subscription?</a></div>
  </div>
</div>
<div class="row mt-3 mb-2">
  <div class="col">
    <kehe-phoenix-menu-button [menuOptions]="brokerBulkActionOptions$ | async"
      [enabled]="(selectSelectedSupplierSubscriptionsCount$ | async) > 0" menuTitle="Actions"
      (optionSelected)="brokerBulkActionSelected($event)">
      ></kehe-phoenix-menu-button>
  </div>
</div>
<div class="row kehe-grid no-scroll p-0 w-100">
  <div class="col">
    <app-esn-subscription-grid></app-esn-subscription-grid>
  </div>
</div>
<app-subscription-overview-modal *ngIf="showSubscriptionInfoModal$ | async"></app-subscription-overview-modal>
<app-choose-frequency-modal *ngIf="showFrequencyModal$ | async"></app-choose-frequency-modal>
<app-manage-recipients-modal *ngIf="showManageRecipientsModal$ | async"></app-manage-recipients-modal>
<app-cancel-esn-subscription-modal *ngIf="showCancelEsnSubscriptionModal$ | async"></app-cancel-esn-subscription-modal>
<app-help-modal [show]="showHelpModal" (modalClose)="showHelpModal = false;" [videos]="helpVideos"
  [showReportIssue]="false"></app-help-modal>