import { createAction, props } from '@ngrx/store';
import { Payload } from './../../../../../models/payload';
import { OrdersListShowModel } from '../../models/orders-list-show-model';
import { OrdersListShowActions } from './enum.actions';
import { ShowBulkDeleteOrdersResponse } from '../../../../../models/showBulkDeleteOrdersResponse';
import { OrderListShowTabId } from '../../models/eorder-list-show-tab-id';

export const pageLoad = createAction(OrdersListShowActions.PageLoad);

export const loadOrders = createAction(
  OrdersListShowActions.LoadOrders,
  props<{ showId?: string }>()
);

export const mockLoadList = createAction(
  '[Orders List Show] Mock Load List',
  props<{
    orders: Payload<OrdersListShowModel[]>,
    selectedTab: OrderListShowTabId
  }>()
);

export const loadOrdersSuccess = createAction(
  '[Orders List Show] Load Orders Success',
  props<{
    orders: Payload<OrdersListShowModel[]>,
    selectedTab: OrderListShowTabId
  }>()
);

export const loadOrdersFailed = createAction(
  '[Orders List Show] Load Orders Failed',
);

export const downloadReportSuccess = createAction(
  '[Show Order List > Grid Effects] Download Report Success',
  props<{
    reportId: string,
    presignedUrl: string,
  }>()
);

export const downloadReportFailure = createAction(
  '[Show Order List > Grid Effects] Download Report Failure',
);

export const refreshButtonClicked = createAction(
  '[Orders List Show] Refresh Button Clicked',
);

export const toggleOrderSelect = createAction(
  OrdersListShowActions.ToggleOrderSelect,
  props<OrdersListShowModel>()
);

export const toggleOrderSelectAll = createAction(
  OrdersListShowActions.ToggleOrderSelectAll,
  props<{
    checkAll: boolean
  }>()
);

export const ordersListPageChange = createAction(
  OrdersListShowActions.OrdersListPageChange,
  props<{ pageCount: number; pageIndex: number }>()
);

export const ordersListStatusChange = createAction(
  OrdersListShowActions.OrdersListStatusChange,
  props<{ status: string, pageIndex: number }>()
);

export const ordersListShowIdChange = createAction(
  OrdersListShowActions.OrdersListShowIdChange,
  props<{ showId: string }>()
);

export const openDeleteOrdersModal = createAction(
  OrdersListShowActions.OpenDeleteOrdersModal,
  props<{ orders: Array<OrdersListShowModel> }>()
);

export const closeDeleteOrdersModal = createAction(
  OrdersListShowActions.CloseDeleteOrdersModal,
);

export const ordersListDeleteOrders = createAction(
  OrdersListShowActions.OrdersListDeleteOrders,
  props<{ orders: Array<OrdersListShowModel> }>()
);

export const ordersListDeleteOrdersSuccess = createAction(
  OrdersListShowActions.OrdersListDeleteOrdersSuccess,
  props<{ response: Array<ShowBulkDeleteOrdersResponse> }>()
);

export const ordersListDeleteOrdersFailure = createAction(
  OrdersListShowActions.OrdersListDeleteOrdersFailure,
  props<{error: any}>()
);

export const closeDeleteErrorBanner = createAction(
  OrdersListShowActions.CloseDeleteErrorAlert,
);

export const openHelpModal = createAction(
  OrdersListShowActions.OpenHelpModal,
  props<{ isOpened: boolean }>()
);

export const closeShowReportBannerClicked = createAction(
  '[Orders List Show] Close Show Report Banner Clicked'
);

export const downloadShowReportBannerClicked = createAction(
  '[Orders List Show] Download Show Report Banner Clicked'
);
