<div>
  <div class="row">
    <div class="col">
      <div class="mb-2" *ngIf="!isManageDraftSupplierPage && (alertMessage$ | async ) as alertMessage">
        <kehe-phoenix-alert *ngIf="(hasPoToReview$ | async)" [type]="alertMessage.type" [show]="true" [message]="alertMessage.message" [stick]="true" [hideCloseIcon]="false" (alertEvent)="hideAlertMessage()"> </kehe-phoenix-alert>
      </div>
      <h2 *ngIf="isManageDraftSupplierPage">Manage Draft Suppliers</h2>
      <h2 *ngIf="!isManageDraftSupplierPage">Manage Suppliers</h2>
      <h5 *ngIf="suppliersCount">{{suppliersCount | number}} result{{suppliersCount > 1 ? 's' : ''}}</h5>
    </div>
  </div>

  <app-breadcrumbs title="Manage Supplier(s)" type="parent"></app-breadcrumbs>

  <div class="row mt-4 mb-2">
    <div class="col-md-5 col-lg-6 mb-2 mb-md-0 ">
      <kehe-phoenix-chip-list *ngIf="filterChips.length > 0" [chips]="filterChips" (chipClicked)="filterChipClicked($event)" (clearAllChips)="filterChipClearAllClicked()"></kehe-phoenix-chip-list>
    </div>

    <div class="col-md-7 col-lg-6 text-right">
      <kehe-phoenix-search
        placeholder="Search by ESN, DBA or Legal Name"
        name="txtSearchSuppliersList"
        [formControl]="search"
      ></kehe-phoenix-search>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="kehe-grid no-scroll p-0 w-100">
        <kendo-grid
          [data]="suppliers$ | async"
          [sortable]="{allowUnsort: false, mode: 'single'}"
          [sort]="currentSort"
          (sortChange)="sortGrid($event)">
          <kendo-grid-column field="esn" title="ESN" width="100" [headerClass]="'kehe-grid-header-hover'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a class="link-cell" [routerLink]="[getSupplierLink(dataItem?.link?.link)]">{{dataItem.esn}}</a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="name" title="Legal Name" [headerClass]="'kehe-grid-header-hover'"></kendo-grid-column>
          <kendo-grid-column field="doingBusinessAs" title="Doing Business As (DBA)" [headerClass]="'kehe-grid-header-hover'"></kendo-grid-column>
          <kendo-grid-column field="categoryManagerFullName" title="Category Manager" [headerClass]="'kehe-grid-header-hover'"></kendo-grid-column>
          <kendo-grid-column field="supplierDevelopmentManagerFullName" title="Supplier Development Manager" width="240" [headerClass]="'kehe-grid-header-hover'" [hidden]="isManageDraftSupplierPage"></kendo-grid-column>
          <kendo-grid-column field="status" title="Status" width="150" [headerClass]="'kehe-grid-header-hover'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kehe-phoenix-tag [tag]="dataItem?.statusTag?.tag" classAttr="large"></kehe-phoenix-tag>
            </ng-template>
          </kendo-grid-column>
          <ng-template kendoGridNoRecordsTemplate>
            <div *ngIf="!suppliersLoading" class="custom-no-results-msg">
              <em class="fas fa-seedling" aria-hidden="true"></em>
              <div class="custom-msg-container">
                <span class="no-results">No Results Found</span>
              </div>
            </div>
            <div *ngIf="suppliersLoading"class="custom-no-results-msg">
              <kehe-phoenix-loading></kehe-phoenix-loading>
            </div>
          </ng-template>
        </kendo-grid>
        <kendo-datapager *ngIf="!suppliersLoading"
          [pageSize]="pageSize"
          [skip]="skip"
          [total]="suppliersCount"
          [buttonCount]="4"
          [pageSizeValues]="[25, 50, 100, 250]"
          (pageChange)="pageChange($event)"
          ></kendo-datapager>
      </div>
    </div>
  </div>
</div>
