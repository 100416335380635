import { IMessage } from './../models/IMessage';
import { IChatRoom } from './../models/IChatRoom';

export interface MessagesState {
  enabledChats: Array<IChatRoom>;
  headerChats: Array<IChatRoom>;
  headerChatsFiltered: Array<IChatRoom>;
  messageBeingSend: IMessage;
  chatMessageBeingSend: IChatRoom;
  unreadCount: number;
  unreadIds: Array<string>;
  newChatOpen: boolean;
  filterHeaderChatsText: string;
}


export const messagesInitialState: MessagesState = {
  enabledChats: [],
  headerChats: [],
  headerChatsFiltered: [],
  messageBeingSend: {} as IMessage,
  chatMessageBeingSend: {} as IChatRoom,
  unreadCount: 0,
  unreadIds: [],
  newChatOpen: false,
  filterHeaderChatsText: ''
};
