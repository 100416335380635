<div class="shopping-cart-page">
  <div class="my-3">
    <kehe-phoenix-alert type="information" [show]="false" [message]="alertMessage" [stick]="true"
      [hideCloseIcon]="true"> </kehe-phoenix-alert>
  </div>

  <div class="row mb-3 justify-content-end">
    <div class="col-12 col-sm-8">
      <app-breadcrumbs title="Order Details" type="child"></app-breadcrumbs>
    </div>
    <div class="col-sm-4 text-end">
      <kehe-phoenix-button classAttr="btn-medium btn-primary" (click)="proccedToCheckout()"
        [disabled]="(numberOfItems$ | async) === 0">
        Proceed to Checkout
      </kehe-phoenix-button>
    </div>
  </div>

  <app-order-summary></app-order-summary>
  <app-order [canDelete]="true"></app-order>
</div>