import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DestroyableDirective } from "@app/abstract/destroyable";
import { Store } from "@ngrx/store";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class FsmaComplianceService extends DestroyableDirective {
  private _apiProdutSupplierUrl: string;

  constructor(private http: HttpClient, private store: Store) {
    super();
    this._apiProdutSupplierUrl = environment.productSupplierApi;
  }

  getFsmaNonCompliantProductsCount() {
    const url = `${this._apiProdutSupplierUrl}/pending-food-traceability`;
    const params = new HttpParams();

    return this.http
      .get<number>(url, { params })
      .pipe(
        catchError((err) => throwError(err))
      );
  }
}
