import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ISupplier } from '../../models/ISupplier';
import { ShowEligibleCustomer } from "@app/models/show-models";
import { IChatRoom } from '../../models/IChatRoom';
import { GoogleAnalyticsService } from './../../../../services/googleAnalytics.service';
import { filter, tap, withLatestFrom } from 'rxjs/operators';

export interface ChatDrawerState {
  suppliers: ISupplier[];
  selectedSupplier: ISupplier | null;
  selectedCustomer: ShowEligibleCustomer | null;
  chatRoom: IChatRoom;
}

@Injectable()
export class ChatDrawerStore extends ComponentStore<ChatDrawerState> {
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    super({
      suppliers: [],
      selectedSupplier: null,
      selectedCustomer: null,
      chatRoom: {} as IChatRoom
    });

  }

  readonly chatRoom$ = this.select((state) => state.chatRoom);

  readonly canCreateMessage$ = this.select(
    ({ selectedSupplier, selectedCustomer }) =>
      !!selectedSupplier && !!selectedCustomer
  );

  readonly viewModel$ = this.select(
    this.state$,
    this.canCreateMessage$,
    (state, canCreateMessage) => ({
      ...state,
      canCreateMessage,
    })
  );

  // Updaters for suppliers and customers
  readonly setSuppliers = this.updater((state, suppliers: ISupplier[]) => ({
    ...state,
    suppliers
  }));


  readonly selectSupplier = this.updater(
    (state, selectedSupplier: ISupplier) => ({
      ...state,
      selectedSupplier
    })
  );

  readonly selectCustomer = this.updater(
    (state, selectedCustomer: ShowEligibleCustomer) => ({
      ...state,
      selectedCustomer
    })
  );

  // Create Message effect
  readonly createMessage = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.canCreateMessage$),
      filter(([_, canCreateMessage]) => canCreateMessage),
      tap(() => {
        const { selectedSupplier, selectedCustomer } = this.get();
        if (selectedSupplier && selectedCustomer) {
          this.googleAnalyticsService.eventEmitter(
            'show',
            'top-bar-open-messaging',
            'open-messaging',
            1
          );
          const chatRoom: IChatRoom = {
            customerNumber: selectedCustomer.dcCustomerNumber,
            customerName: selectedCustomer.name,
            supplierNumber: selectedSupplier.esn,
            supplierName: selectedSupplier.name
          };
          // Emit the chat room
          this.patchState({ chatRoom });
        }
      })
    )
  );
}
