import { IMessagesToRender } from './../../models/IMessagesToRender';
import { IChatRoom } from './../../models/IChatRoom';
import { MessagesFacade } from './../../store/messages.facade';
import { Component, OnInit, Input } from '@angular/core';
import { ISupplier } from '../../models/ISupplier';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-messages-page',
    templateUrl: './messages-page.component.html',
    styleUrls: ['./messages-page.component.scss'],
})
export class MessagesPageComponent implements OnInit {

  @Input()
  userFullName: string;

  @Input()
  source: string;

  @Input()
  userEmail: string;

  @Input()
  suppliers: Array<ISupplier>;

  public selectSuppliers$: Observable<Array<ISupplier>>;
  public selectNewChatOpen$: Observable<boolean>;

  public enabledChats: Array<IChatRoom>;
  public enabledChatsMessages: Array<Array<IMessagesToRender>>;


  constructor(
    private _messagesFacade: MessagesFacade,
  ) {

  }

  ngOnInit() {
    this._messagesFacade.selectEnabledChats$.subscribe(enabledChats => {
      this.enabledChatsMessages = [];
      this.enabledChats = enabledChats;
      enabledChats.forEach(chat => {
        if (chat.messagesToRender) {
          this.enabledChatsMessages.push([...chat.messagesToRender]);
        } else {
          this.enabledChatsMessages.push([]);
        }
      });
    });

    this.selectSuppliers$ = this._messagesFacade.selectSuppliers$;
    this.selectNewChatOpen$ = this._messagesFacade.selectNewChatOpen$;
  }

  closeChat(event) {
    this._messagesFacade.closeChat(event);
  }

  toggleCollapse(event) {
    this._messagesFacade.toggleCollapseChat(event);
  }

  closeNewChat($event) {
    this._messagesFacade.closeNewChat();
  }

  sendMessage(event) {
    this._messagesFacade.sendMessage(
      event.textMessage,
      event.chatId,
      this.userFullName,
      this.userEmail
    );
  }

  saveDraftMessage(event) {
    this._messagesFacade.saveDraftMessageChat(
      event.draftMessage,
      event.chatId,
    );
  }

  getMoreMessages(event) {
    this._messagesFacade.getMoreMessages(
      event.chatRoom,
    );
  }

  openChat($event: IChatRoom) {
    this._messagesFacade.openChat(
      $event.customerNumber,
      $event.supplierNumber,
      $event.customerName,
      $event.supplierName,
    );
    this._messagesFacade.closeNewChat();
  }

}
