import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map, retry } from 'rxjs/operators';
import { PreSignedUrlResponse } from '@kehe/purchasing-options-lib/lib/modules/purchasing-options/models/pre-signed-url-response';

@Injectable({
  providedIn: 'root'
})
export class PurchasingOptionsSupplierService {
  private _url: string;

  constructor(private httpClient: HttpClient) {
    this._url = environment.purchasingOptionsApi;
  }

  supplierHasPOToReview(esn: string): Observable<boolean> {
    return this.httpClient
      .get<boolean>(`${this._url}/supplier/${esn}/status/2`
      )
      .pipe(retry(1), map((response) => response));
  }

  checkForPosToReview(): Observable<boolean> {
    return this.httpClient
      .get<boolean>(`${this._url}/supplier/check/purchasing-options`
      )
      .pipe(retry(1), map((response) => response));
  }

  downloadHelpGuide(): Observable<PreSignedUrlResponse> {
    return this.httpClient.get<PreSignedUrlResponse>(
      `${this._url}/resources/export-help-guide`)
      .pipe(retry(1), map((response) => response));
  }
}

