import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, withLatestFrom, mergeMap, filter, tap } from 'rxjs/operators';
import * as SupplierListActions from './supplier-list.actions';
import { SupplierService } from '../services/supplier.service';
import { Store, select } from '@ngrx/store';
import { getPosForUserEsns, getSupplierList, getSupplierListFilter } from './supplier-list.selectors';
import {Router} from '@angular/router';
import {Constants} from '../../../constants/constants';
import { PurchasingOptionsSupplierService } from '@app/modules/supplier-detail/services/purchasing-options.service';
import { getPurchasingOptionRoute } from '../../../utilities/url-utility';

@Injectable()
export class SupplierListEffects {
  constructor(
    private _actions$: Actions,
    private _supplierService: SupplierService,
    private _purOpsService: PurchasingOptionsSupplierService,
    private _store: Store,
    private _router: Router,
  ) {}

  triggerRefresh$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SupplierListActions.applyFilter,
        SupplierListActions.supplierListPageChange,
        SupplierListActions.supplierListSearchChange,
        SupplierListActions.supplierListSortChange,
        SupplierListActions.supplierListFilterChipClicked,
        SupplierListActions.SupplierListFilterChipsClearAll,
      ),
      map(() => SupplierListActions.loadSupplierList()))
  );

  loadSupplierList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        SupplierListActions.loadSupplierList,
      ),
      withLatestFrom(this._store.pipe(select(getSupplierListFilter))),
      switchMap(([action, filter]) => {

        const supplierListFilter = {...filter};
        if (this._router.url.startsWith(Constants.ManageDraftSupplierPath)) {
          supplierListFilter.status = 'Draft';
        }

        return this._supplierService.get(supplierListFilter).pipe(
          mergeMap((suppliers) => {
            const actions = [];
              actions.push(SupplierListActions.loadSupplierListSuccess({ suppliers }));
              actions.push(SupplierListActions.checkForPos());
            return actions;
          })
        );
      })
    )
  );

  checkForPos$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SupplierListActions.checkForPos),
      withLatestFrom(this._store.pipe(select(getSupplierList))),
      filter(([, supplierList]) => supplierList.length > 0), // Proceed only if supplierList count > 0
      switchMap(([_]) =>
        this._purOpsService.checkForPosToReview().pipe(
          map((posForUserEsns) =>
            SupplierListActions.checkForPosSuccess({ posForUserEsns }) // Dispatch the action with the response
          )
        )
      )
    )
  );

  PurchasingOptionLinkClicked$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SupplierListActions.poLinkClicked),
        withLatestFrom(this._store.pipe(select(getPosForUserEsns))),
        tap(([action, posForUserEsns]) => {
          let po = posForUserEsns.purchasingOptions[0];
          const url = getPurchasingOptionRoute(po.esn, po.id);
          
          window.open(url, '_blank');
        })
      ),
    { dispatch: false }
  );
}
