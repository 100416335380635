import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {map, retry} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Payload } from '../models/payload';
import { SupplierListItem } from '@app/modules/supplier-list/models/supplier-list-item';
import { Constants } from '../constants/constants';
import { SupplierUserDetails } from '../models/supplier-user-detail';
import { ISupplier } from '@app/modules/messages/models/ISupplier';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  private _supplierApiUrl: string = environment.supplierApi;
  private _enterpriseSupplier: string = environment.supplierEnterprise;

  constructor(private http: HttpClient) { }

  single = (id: string): Observable<any> => {
      return of({});
   }

  getDraftESNs(): Observable<any> {
    return this.http.get<any>(this._supplierApiUrl + '/api/user-to-draft-esns').pipe(retry(2));
  }

  getUserDetails(): Observable<SupplierUserDetails> {
    // TODO: remove environment logic once redrive is done in non-production environments
    const url = `${environment.usersMicroservice}${environment.production ? 'supplier/' : ''}mydetails`;
    return this.http.get<{ availableCount, data }>(url)
      .pipe(retry(1), map((response) => {
        return response.data;
      }));
  }

  getSuppliers(): Observable<Payload<Array<SupplierListItem>>> {
    return this.http
      .get<any>(`${this._enterpriseSupplier}/list`)
      .pipe(retry(2), map(suppliers => {
        if (suppliers.data) {
          suppliers.data = suppliers.data.filter(supplier => supplier.esn !== Constants.DraftPlaceholderEsn);
          suppliers.availableCount = suppliers.data.length;
        }
        return suppliers;
      }));
  }

  getSuppliersForUser(email: string): Observable<Payload<ISupplier[]>> {
    return this.http.get<Payload<ISupplier[]>>(environment.usersMicroservice + email + '/suppliers').pipe(retry(2));
  }


}
