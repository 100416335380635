import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierOnboardingState, supplierOnboardingStateKey } from './supplier-onboarding.reducer';
import { OnboardingTabStatuses } from '../enums/onboarding-tab-statuses.enum';
import { OnboardingTabs } from '../enums/onboarding-tabs.enum';
import { OnboardingTabStatus } from '../models/onboarding-tab-status';
import {
    SupplierDetailState,
    supplierDetailStateKey
} from '@app/modules/supplier-detail/store/supplier-detail.reducer';
import { getSupplierDetailState } from '@app/modules/supplier-detail/store/supplier-detail.selectors';
import { SupplierDetailTabs } from '@app/modules/supplier-detail/models/supplier-detail-tabs';

export const getSupplierOnboardingState =
    createFeatureSelector<SupplierOnboardingState>(supplierOnboardingStateKey);

export const getSuuplierDetailState =
    createFeatureSelector<SupplierDetailState>(supplierDetailStateKey);

export const getCurrentTab = createSelector(
    getSupplierDetailState,
    (state: SupplierDetailState) => state.currentTab
);

export const getSupplierOnboardingStatuses = createSelector(
    getSupplierOnboardingState,
    (state: SupplierOnboardingState) => state.tabStatuses
);

export const getSupplierOnboardingV2Statuses = createSelector(
    getSupplierOnboardingState,
    (state: SupplierOnboardingState) => state.onboardingTabStatuses
);

export const selectSupplierOnboardingStatus = createSelector(
  getSupplierOnboardingStatuses,
  getSupplierOnboardingV2Statuses,
  (v1Statues, v2Statuses) => v2Statuses.length < 1 ? v1Statues : v2Statuses
);

export const getIsSubmitEnabled = createSelector(
    getSupplierOnboardingState,
    getCurrentTab,
    (state: SupplierOnboardingState, currentTab: SupplierDetailTabs) => {
        if (currentTab === SupplierDetailTabs.broker) {
            return false;
        } else {
            return (state.onboardingTabStatuses || []).every(
                (item) => item.tabStatus === OnboardingTabStatuses.approved
            );
        }
    }
);

export const isGeneralTabNotSubmitted = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => getTabStatus(state.onboardingTabStatuses, OnboardingTabs.general) === OnboardingTabStatuses.notSubmitted
);

export const isPurchasingInfoTabNotSubmitted = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => getTabStatus(state.onboardingTabStatuses, OnboardingTabs.purchasingInfo) === OnboardingTabStatuses.notSubmitted
);

export const getSupplierOnboardingV2StatusForTab = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState, tab: OnboardingTabs) => getTabStatus(state.onboardingTabStatuses, tab)
);

export const selectShowValidateModal = createSelector(
    getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.showValidateModal
);

export const selectIsValidating = createSelector(
    getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.isValidating
);

export const selectValidationErrorMessage = createSelector(
    getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.validationErrorMessage
);

// Revision
export const selectRevisionStatus = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.revisionStatus
);
export const showRevisionStatus = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.revisionStatus !== null
);
// Revision

// Docusign
export const selectShowDocusignSignerConfirmation = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.docusignSigner !== null
);
export const selectDocusignSigner = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.docusignSigner
);
export const selectIsSendingDocusign = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.isSendingDocusign
);
export const selectSendDocusignError = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.sendDocusignError
);
// Docusign

// User
export const selectShowAddUserModal = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.showAddUserModal
);
export const selectAddUserInProgress = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.addUserInProgress
);
export const selectAddUserError = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.addUserError
);
export const selectUsers = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.users
);

export const selectShowDeleteUserModal = createSelector(
  getSupplierOnboardingState,
  (state) => state.showDeleteUserModal
);
export const selectStagedUser = createSelector(
  getSupplierOnboardingState,
  (state) => state.stagedUser
);
export const selectDeleteUserInProgress = createSelector(
  getSupplierOnboardingState,
  (state) => state.deleteUserInProgress
);
export const selectDeleteUserError = createSelector(
  getSupplierOnboardingState,
  (state) => state.deleteUserError
);
export const selectLoadingUsers = createSelector(
  getSupplierOnboardingState,
  (state: SupplierOnboardingState) => state.loadingUsers
);
// User

// Utils
function getTabStatus(onboardingTabStatuses: OnboardingTabStatus[], tab: OnboardingTabs) {
  const tabStatus = (onboardingTabStatuses || []).find(item => item.tab === tab);
  if (tabStatus) {
    return tabStatus.tabStatus;
  }
  return OnboardingTabStatuses.notSubmitted;
}
