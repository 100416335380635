import { DateUtils } from '@kehe/phoenix-utils';
import isSameDay from './isSameDay';


const formatDayToRender = (date: string) => {
  if (isSameDay(new Date(date), new Date())) {
    return DateUtils.create(date).format('[Today] [at] h:mm A');
  } else {
    return DateUtils.create(date).format('MM/DD/YYYY [at] h:mm A');
  }
};

export default formatDayToRender;
