import { on, createReducer } from '@ngrx/store';
import { User } from 'oidc-client-ts';
import * as AuthenticationActions from '@app/authentication/authentication.actions';
import { environment } from '../../../../environments/environment';
import { SupplierUserDetails } from '@app/models/supplier-user-detail';

export const authKey = 'authentication';

export interface State {
  user: User | null;
  legacyToken: string | null;
  userDetail: SupplierUserDetails;
}

export const initialState: State = {
  user: null,
  legacyToken: null,
  userDetail: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthenticationActions.logInSuccess, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
  on(AuthenticationActions.getLegacyTokenSuccess, (state, action) => {
    return {
      ...state,
      legacyToken: action.legacyToken,
    };
  }),
  on(AuthenticationActions.getUserDetailSuccess, (state, { user }) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      type: user.type,
      number: environment.production ? user.number : '03000123',
      company: user.company,
      name: user.name,
      email: user.email,
      created: user.created,
      status: user.status,
    }
  })),
  
);

export const getUser = (state: State) => state && state.user;
export const getLegacyToken = (state: State) => state.legacyToken;
export const getUserDetail = (state: State) => state && state.userDetail;
