import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../models/payload';
import { ProductListFilter } from '../models/product-list-filter';
import { ProductListItem } from '../models/product-list-item';
import { IProductFilterCheckItem } from '../models/iproduct-filter-item';
import { IChip } from '@kehe/phoenix-chips';
import { IKeheTab } from '@kehe/phoenix-tabs';
import { QuickFilterTitle } from '../quick-filter-util';
import { PendoEvent } from '../models/pendo-event.interface';

export enum ProductActions {
  LoadProductList = '[Product] Load Product List',
  LoadProductListSuccess = '[Product] Load Product List Success',
  LoadProductListError = '[Product] Load Product List Error',
  loadProductTraits = '[Product] Load Product Traits',
  ProductListPageChange = '[Product] Product List Page Change',
  OpenProductFilterPanel = '[Product] Open Product Filter Panel',
  CloseProductFilterPanel = '[Product] Close Product Filter Panel',
  ApplyFilter = '[Product] Apply Filters',
  LoadAllDefaultFilters = '[Product] All Default Filters dropdown values',
  LoadAllDefaultFiltersSuccess = '[Product] All Default Filters dropdown values Success',
  GetStatusList = '[Product] Product List Get Statuses List',
  GetScoreCardList = '[Product] Product List Get Score Card List',
  GetTempClassList = '[Product] Product List Get Temp Class List',
  GetCategoryList = '[Product] Product List Get Categories List',
  GetSubCategoryList = '[Product] Product List Get Sub Categories List',
  GetScoreCardListSuccess = '[Product] Product List Get Statuses List Success',
  GetSupplierList = '[Product] Product List Get Suppliers List',
  GetSupplierListSuccess = '[Product] Product List Get Suppliers List Success',
  GetBrandList = '[Product] Product List Get Brand List',
  GetBrandListSuccess = '[Product] Product List Get Brand List Success',
  GetPriceGroupList = '[Product] Product List Get Price group List',
  GetPriceGroupListSuccess = '[Product] Product List Get Price Group List Success',
  GetCategoryManagerList = '[Product] Product List Get Category Manager List',
  GetCategoryManagerListSuccess = '[Product] Product List Get Categroy Manager List Success',
  GetCategoryListSuccess = '[Product] Product List Get Categroy List Success',
  GetSubCategoryListSuccess = '[Product] Product List Get Sub Categroy List Success',
  GetTempClassListSuccess = '[Product] Product List Get Score card List Success',
  GetStatusListSuccess = '[Product] Product List Get Temp class List Success',
  ProductListSearchChange = '[Product] Product List Search Change',
  CheckAllProducts = '[Product] Check All Products',
  GetHeaderCheckBox = '[Product] Get Header Checkbox',
  ProductListSortChange = '[Product] Product List Sort Change',
  ProductListInitialSort = '[Product] Product List Initial Sort',
  CreateProductListFilterChips = '[Product] Create Product List Filter Chips',
  ProductListFilterChipClicked = '[Product] Product List Filter Chip Clicked',
  ProductListFilterChipsClearAll = '[Product] Product List Filter Chips Clear All',
  ProductListFilterClearAll = '[Product] Product List Filter Clear All',
  ChangeListSelectedTab = '[Product] Change List Selected Tab',
  ShowAddProductModal = '[Product] Show Add Product Modal',
  HideAddProductModal = '[Product] Hide Add Product Modal',
  ShowCloneProductModal = '[Product] Show Clone Product Modal',
  ShowDeleteProductModal = '[Product] Show Delete Product Modal',
  GetAddNewProductFormNameValuePairs = '[Add New Product] Load Add New Product Name Value Pairs',
  GetAddNewProductFormNameValuePairsSuccess = '[Add New Product] Load Add New Product Name Value Pairs Success',
  AddProductFormChanged = '[Add New Product] Form Changed',
  UpdateFormValidityState = '[Add New Product] Update Form Validity',
  SaveNewProductChanges = '[Add New Product] Save Changes',
  SaveNewProductChangesSuccess = '[Add New Product] Save Changes Success',
  SaveNewProductChangesError = '[Add New Product] Save Changes Error',
  LoadSupplierFilters = '[Product] Supplier Filters dropdown values',
  LoadSupplierFiltersSuccess = '[Product] Supplier Filters dropdown values Success',
  LoadDraftProductListFilters = '[Product] Draft Product List Filters dropdown values',
  LoadDraftProductListFiltersSuccess = '[Product] Draft Product List Filters dropdown values Success',
  DeleteProduct = '[Product List] Delete Product',
  DeleteProductSuccess = '[Product List] Delete Product Success',
  DeleteProductError = '[Product List] Delete Product Error',
  LoadQuickFilterCount = '[Product] Load Quick Filter Count',
  LoadQuickFilterCountSuccess = '[Product] Load Quick Filter Count Success',
  LoadQuickFilterCountError = '[Product] Load Quick Filter Count Error',
  UpdateAssignToMeQuickFilterCountDiff = '[Product] Update AssignToMe Quick Filter CountDiff',
  SelectedQuikcFilter = '[Product] Selected Quikc Filter',
  SelectedCreatedByMeQuickFilter = '[Product] Selected CreatedByMe Quick Filter',
  SelectedStatusQuickFilter = '[Product] Selected Status Quick Filter',
  ResetQuickFilter = '[Product] Reset Quick Filter',
  GetNewWorkFlowStatusFilterList = '[Product] Product List Get NewWorkFlowStatus Filter List',
  GetNewWorkFlowStatusFilterListSuccess = '[Product] Product List  Get NewWorkFlowStatus Filter List Success',
  UpdateProductListItemSelection = '[Product List] Update ProductList Item Selection',
  UpdateFtlIngredientsConfirmationModalVisibility = '[Product List Bulk Action] Update FtlIngredients Confirmation Modal Visibility',
  UpdateFtlIngredientsConfirmationSuccess = '[Product List Bulk Action] Update FtlIngredients Confirmation Success',
  UpdateAllProductListItemSelection = '[Product List] Update All ProductList Item Selection'

}

export const loadProductList = createAction(
  ProductActions.LoadProductList
);
export const getAddNewProductFormNameValuePairs = createAction(
  ProductActions.GetAddNewProductFormNameValuePairs,
);
export const getAddNewProductFormNameValuePairsSuccess = createAction(
  ProductActions.GetAddNewProductFormNameValuePairsSuccess,
  props<{ filters: any }>()
);
export const changeListSelectedTab = createAction(
  ProductActions.ChangeListSelectedTab,
  props<{ selectedTab: IKeheTab }>()
);
export const loadProductListSuccess = createAction(
  ProductActions.LoadProductListSuccess,
  props<{ product: Payload<Array<ProductListItem>> }>()
);

export const loadCustomerListError = createAction(
  ProductActions.LoadProductListError
);

export const productListPageChange = createAction(
  ProductActions.ProductListPageChange,
  props<{ pageCount: number; pageIndex: number }>()
);

export const openProductFilter = createAction(
  ProductActions.OpenProductFilterPanel
);

export const closeProductFilter = createAction(
  ProductActions.CloseProductFilterPanel
);

export const applyFilter = createAction(
  ProductActions.ApplyFilter,
  props<{ productListFilter: ProductListFilter }>()
);

export const loadAllDefaultFilters = createAction(
  ProductActions.LoadAllDefaultFilters
);

export const loadAllDefaultFiltersSuccess = createAction(
  ProductActions.LoadAllDefaultFiltersSuccess,
  props<{ filters: any }>()
);

export const getStatusList = createAction(
  ProductActions.GetStatusList
);
export const getScoreCardList = createAction(
  ProductActions.GetScoreCardList
);

export const getTempClassList = createAction(
  ProductActions.GetTempClassList
);

export const getSupplierList = createAction(
  ProductActions.GetSupplierList
);

export const getBrandList = createAction(
  ProductActions.GetBrandList
);

export const getPriceGroupList = createAction(
  ProductActions.GetPriceGroupList
);

export const getCategoryManagerList = createAction(
  ProductActions.GetCategoryManagerList
);

export const getStatusListSuccess = createAction(
  ProductActions.GetStatusListSuccess,
  props<{ items: IProductFilterCheckItem[] }>()
);

export const getScoreCardListSuccess = createAction(
  ProductActions.GetScoreCardListSuccess,
  props<{ items: IProductFilterCheckItem[] }>()
);

export const getTempClassListSuccess = createAction(
  ProductActions.GetTempClassListSuccess,
  props<{ items: IProductFilterCheckItem[] }>()
);

export const getSupplierListSuccess = createAction(
  ProductActions.GetSupplierListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getBrandListSuccess = createAction(
  ProductActions.GetBrandListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getPriceGroupListSuccess = createAction(
  ProductActions.GetBrandListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getCategoryManagerListSuccess = createAction(
  ProductActions.GetCategoryManagerListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const GetCategoryListSuccess = createAction(
  ProductActions.GetCategoryListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getCategoryList = createAction(
  ProductActions.GetCategoryList,
  props<{ search: string }>()
);

export const GetSubCategoryListSuccess = createAction(
  ProductActions.GetSubCategoryListSuccess,
  props<{ list: IProductFilterCheckItem[] }>()
);

export const getSubCategoryList = createAction(
  ProductActions.GetSubCategoryList,
  props<{ search: string }>()
);

export const productListSearchChange = createAction(
  ProductActions.ProductListSearchChange,
  props<{ search: string }>()
);

export const checkAllProducts = createAction(
  ProductActions.CheckAllProducts,
  props<{ isChecked: boolean }>()
);

export const productListSortChange = createAction(
  ProductActions.ProductListSortChange,
  props<{ orderDirection: number, sortName: number }>()
);

export const productListInitialSort = createAction(
  ProductActions.ProductListInitialSort,
  props<{ list: Array<ProductListItem> }>()
);

export const createProductListFilterChips = createAction(
  ProductActions.CreateProductListFilterChips
);

export const productListFilterChipClicked = createAction(
  ProductActions.ProductListFilterChipClicked,
  props<{ chip: IChip }>()
);

export const productListFilterChipsClearAll = createAction(
  ProductActions.ProductListFilterChipsClearAll
);

export const getProductsTraits = createAction(
  ProductActions.loadProductTraits
);

export const productListFilterClearAll = createAction(
  ProductActions.ProductListFilterClearAll
);

export const showAddProductModal = createAction(
  ProductActions.ShowAddProductModal
);

export const hideAddProductModal = createAction(
  ProductActions.HideAddProductModal
);

export const showCloneProductModal = createAction(
  ProductActions.ShowCloneProductModal,
  props<{ cloneProduct: any }>()
);

export const showDeleteProductModal = createAction(
  ProductActions.ShowDeleteProductModal,
  props<{ show: boolean, productToDelete: any }>()
);

export const addProductFormChanged = createAction(
  ProductActions.AddProductFormChanged,
  props<{ form: any, isDirty: boolean, isValidated: boolean }>()
);

export const updateFormValidityState = createAction(
  ProductActions.UpdateFormValidityState,
  props<{ status: any }>()
);

export const saveNewProductChanges = createAction(
  ProductActions.SaveNewProductChanges,
);

export const saveNewProductChangesSuccess = createAction(
  ProductActions.SaveNewProductChangesSuccess,
  props<{ newProductResponse: any, pendoEvent: PendoEvent }>()
);

export const saveNewProductChangesError = createAction(
  ProductActions.SaveNewProductChangesError,
);

export const loadDraftProductListFilters = createAction(
  ProductActions.LoadDraftProductListFilters
);

export const loadDraftProductListFiltersSuccess = createAction(
  ProductActions.LoadDraftProductListFiltersSuccess,
  props<{ filters: any }>()
);

export const deleteProduct = createAction(
  ProductActions.DeleteProduct,
  props<{ upc: string }>()
);

export const deleteProductSuccess = createAction(
  ProductActions.DeleteProductSuccess,
  props<{ upc: string }>()
);
export const deleteProductError = createAction(
  ProductActions.DeleteProductError,
);

export const loadQuickFilterCount = createAction(
  ProductActions.LoadQuickFilterCount
);

export const loadQuickFilterCountSuccess = createAction(
  ProductActions.LoadQuickFilterCountSuccess,
  props<{ quickFilterCount: any }>()
);

export const loadQuickFilterCountError = createAction(
  ProductActions.LoadQuickFilterCountError
);

export const updateAssignToMeQuickFilterCountDiff = createAction(
  ProductActions.UpdateAssignToMeQuickFilterCountDiff,
  props<{ countDiff: number }>()
);

export const getNewWorkFlowStatusFilterList = createAction(
  ProductActions.GetNewWorkFlowStatusFilterList
);

// export const getAssinedToFilterList = createAction(
//   ProductActions.GetAssinedToFilterList
// );

export const getNewWorkFlowStatusFilterListSuccess = createAction(
  ProductActions.GetNewWorkFlowStatusFilterListSuccess,
  props<{ response: any }>()
);

export const selectedQuickFilter = createAction(
  ProductActions.SelectedQuikcFilter,
  props<{ filter: QuickFilterTitle }>()
);

export const selectedCreatedByMeQuickFilter = createAction(
  ProductActions.SelectedCreatedByMeQuickFilter,
  props<{ createdByMeUser: string }>()
);

export const selectedStatusQuickFilter = createAction(
  ProductActions.SelectedStatusQuickFilter,
  props<{ filter: QuickFilterTitle }>()
);

export const resetQuickFilter = createAction(
  ProductActions.ResetQuickFilter
);

export const updateProductListItemSelection = createAction(
  ProductActions.UpdateProductListItemSelection,
  props<{ productListItem: ProductListItem }>()
);

export const updateFtlIngredientsConfirmationModalVisibility = createAction(
  ProductActions.UpdateFtlIngredientsConfirmationModalVisibility,
  props<{ visible: boolean }>()
);

export const updateFtlIngredientsConfirmationSuccess = createAction(
  ProductActions.UpdateFtlIngredientsConfirmationSuccess,
  props<{ updatedCount: number }>()
);

export const updateAllProductListItemSelection = createAction(
  ProductActions.UpdateAllProductListItemSelection,
  props<{ selection: boolean }>()
);
