
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { IChatRoom } from '../../models/IChatRoom';
import { ShowEligibleCustomer } from "@app/models/show-models";
import { ISupplier } from '../../models/ISupplier';
import { ChatDrawerStore } from './chat-drawer-new.store';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { takeUntil } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { CommonModule } from '@angular/common';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { ShowService } from '@app/services/show.service';
import { ShowCustomerComboboxComponent } from '@app/modules/orders-show/shared/components/show-customer-combobox/show-customer-combobox.component';

@Component({
  selector: 'app-chat-drawer-new',
  standalone: true,
  imports: [
    CommonModule,
    ComboBoxModule,
    ReactiveFormsModule,
    PhoenixButtonModule,
    ShowCustomerComboboxComponent,
  ],
  providers: [
    ChatDrawerStore,
    ShowService,
  ],
  templateUrl: './chat-drawer-new.component.html',
  styleUrls: ['./chat-drawer-new.component.scss']
})

export class ChatDrawerNewComponent extends DestroyableDirective {

  @Input()
  set suppliers(suppliers: ISupplier[]) {
    this.store.setSuppliers(suppliers);
  }

  @Output()
  closeChat: EventEmitter<null> = new EventEmitter();

  @Output()
  openChat: EventEmitter<IChatRoom> = new EventEmitter();

  viewModel$ = this.store.viewModel$;
  longListCount = 25;

  constructor(
    private store: ChatDrawerStore
  ) {
    super();

    this.store.chatRoom$
    .pipe(
      takeUntil(this.destroy$))
    .subscribe((chatRoom) => {
      if (!isEmpty(chatRoom)) {
        this.openChat.emit(chatRoom);
      }
    });
  }

  closeChatRoom(event) {
    event.stopPropagation();
    this.closeChat.emit();
  }

  createMessage() {
    this.store.createMessage();
  }

  handleSupplierSelected(value: ISupplier) {
    this.store.selectSupplier(value);
  }

  handleCustomerSelected(value: ShowEligibleCustomer) {
    this.store.selectCustomer(value);
  }
}
