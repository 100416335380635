import { MaxCharLengths } from '@kehe/phoenix-address/lib/models/max-char-lengths';
import { environment } from '../../environments/environment';

export class Constants {

  public static KeHE_CONNECT = 'KeHE CONNECT';
  public static KeHE_CONNECT_BI = `${Constants.KeHE_CONNECT} BI®`;

    public static CheckBox = {
        Checked: `<i class="fas fa-check-square fa-lg" style="color:#44C4DA"></i>`,
        UnChecked: `<i class="far fa-square fa-lg" style="color:#999999"></i>`,
    };

    public static EmptyValue = '--';

    public static Features = {
        Products: 'Products',
        Supplier: 'Supplier',
        Documents: 'Documents',
        BIReports: 'BIReports',
    };

    /**
     * User Resource Actions
     */
    public static UserActions = {
        PromoROIRead: 'PromoROI_Read',
        DocumentRead: 'Document_Read',
        CustomerRead: 'Customer_Read',
        ProductCreate: 'Product_Create',
        ProductRead: 'Product_Read',
        ProductUpdate: 'Product_Update',
        ProductImpersonate: 'Product_Impersonate',
        SupplierImpersonate: 'Supplier_Impersonate',
        SalesOrderRead: 'SalesOrder_Read',
        SalesOrderCreate: 'SalesOrder_Create',
        SalesOrderUpdate: 'SalesOrder_Update',
        SalesOrderDelete: 'SalesOrder_Delete',
        SupplierRead: 'Supplier_Read',
        SupplierUpdate: 'Supplier_Update',
        BrokerRead: 'Broker_Read',
        DistributionCenterRead: 'DistributionCenter_Read',
        ShipmentRead: 'Shipment_Read',
        UsersRead: 'User_Read',

        PurchasingOptionsRead: 'PurchasingOptions_Read',
        PurchasingOptionsUpdate: 'PurchasingOptions_Update',
        PurchasingOptionsCreate: 'PurchasingOptions_Create',
        PurchasingOptionsDelete: 'PurchasingOptions_Delete',

        NewProductCreate: 'NewProduct_Create',
        NewProductUpdate: 'NewProduct_Update',
        NewProductDelete: 'NewProduct_Delete',
    };

    public static Menu = {
        Products: 'Products',
        Sales: 'Sales',
        Documents: 'Documents',
        Purchasing: 'Purchasing',
        BIReports: 'Connect Insights',
        Utilities: 'Utilities',
        Users: 'Users'
    };

    public static SubMenu = {
        Products_Manage_Prod: 'Manage Products',
        BIReports_Return_Promo: 'Return on Promo Investment',
        /**
         * Sales's submenu item.
         */
        CustomerManagement: 'Manage Customers',
        SalesManageOrders: 'Manage Orders',
        SalesManageShipments: 'Manage Shipments',

        /**
         * Purchasing submenu items
         */
        ManageSuppliers: 'Manage Suppliers',
        ManageBrokers: 'Manage Brokers',
        /**
         * Utilities submenu items
         */
        DistributionCenter: 'Distribution Centers',
        // Users
        ManageUsers: 'Manage Users'
    };

    public static ManageDraftSupplierPath = '/draft-supplier';

    public static LetterOfFinancialResponsibilityDocumentTypeId = 8;
    public static ProductPriceListSpecSheetDocumentTypeId = 7;
    public static W9DocumentTypeId = 5;
    public static BrokerRecordDocumentTypeId = 12;
    public static PoliciesProceduresDocumentTypeId = 2;
    public static HoldHarmlessFormDocumentTypeId = 3;
    public static SupplierCodeOfConductDocumentTypeId = 9;
    public static PurchasingInfoDocumentTypeId = 24;
    public static SupplierInformationFormDocumentTypeId = 1;
    public static RetailerRequestedDetailsDocumentTypeId = 26;
    public static TermsExemptionFormDocumentTypeId = 15;
    public static PoliciesProceduresAddendumDocumentTypeId = 25;
    public static BlankInvoiceDocumentTypeId = 6;

    public static LegalDocIds = [Constants.W9DocumentTypeId, Constants.SupplierInformationFormDocumentTypeId];

    public static DraftPlaceholderEsn = '20210000';
    public static W9IRS = 'https://www.irs.gov/pub/irs-pdf/fw9.pdf';
    public static OnBoardingAlertGeneralTabInReview = 'The Legal Name, Headquarter Address, and W9 Form are currently ' +
      'being validated by KeHE. You may continue working on the remaining onboarding tabs until validation is complete. ' +
      'If you have any questions, please contact your category manager. ';
    public static OnBoardingAlertGeneralTabRejected = 'KeHE has requested updates to the General tab. ' +
      'Click on “Revision Notes” below to review the requested changes. ' +
      'Once all your revisions have been completed, click "Validate" again to send your updates to KeHE for review.';
    public static OnBoardingAlertGeneralTabReadyToValidate = 'The Legal Name, Headquarter Address, and W9 Form must be ' +
      'validated in order to prepare your contract documents for signature.  ' +
      'Click the “Validate” button below to submit them to KeHE for review.';
    public static OnBoardingAlertLegalTabDocusignSend = 'The DocuSign link has been emailed to the signee for their signature.';

    public static OnBoardingAlertDocumentReadyForValidation = 'Unsubmitted documents must be validated by KeHE. Click the "Validate" button below to submit documents to KeHE for review.';
    public static OnBoardingAlertDocumentInReview = 'Your documents are currently being validated by KeHE. You may continue working on the remaining onboarding tabs until validation is complete. If you have any questions, please contact your category manager.';

  public static V1Groups = {
    Vendor: {
      Description: 'Vendor User',
      Id: environment.vendorGroupId,
      Name: 'Vendor'
    },
    VendorAdmin: {
      Description: 'Administers other Vendor Users',
      Id: environment.vendorAdminGroupId,
      Name: 'VendorAdmin'
    },
    VendorManager: {
      Description: 'Manages products, brands, promotions, etc',
      Id: environment.vendorManagerGroupId,
      Name: 'VendorManager'
    },
    VendorReports: {
      Description: 'Vendor Reports',
      Id: environment.vendorReportsGroupId,
      Name: 'VendorReportsVendor'
    },
    SupplierOnboarding: {
      Description:
        'The user is going through the onboarding process and has limited access to updating supplier data.',
      Id: environment.supplierOnboardingGroupId,
      Name: 'SupplierOnboarding'
    },
    SupplierOnboardingRedirect: {
      Description: 'Group to indicate a supplier is onboarding and redirect to V2',
      Id: environment.supplierOnboardingRedirectGroupId,
      Name: 'SupplierOnboardingRedirect'
    },
    CONNECT_Supplier_Supplier: {
      Description: 'Indicates whether the vendor user should have enhanced BI capabilities in CONNECT Supplier',
      Id: environment.connectSupplierGroupId,
      Name: 'CONNECT_Supplier_Supplier'
    }
  };

  public static ComboboxCountLimit = 25;

	public static SupplierV1DummyESN = '20210000';

  public static AddressFieldMaxCharLengthsDefault: MaxCharLengths = {
    attention: 25,
    street1: 30,
    street2: 30,
    city: 25,
    zipCode: 10,
  };

  public static AddressFieldMaxCharLengthsPickup: MaxCharLengths = {
    name: 30,
    attention: 25,
    street1: 30,
    street2: 30,
    city: 28,
    zipCode: 10,
  };

  public static AddressFieldEmailMaxCharLength: number = 30;
  public static AddressFieldRemitNameMaxCharLength: number = 30;
  public static AddressFieldPickupNotesMaxCharLength: number = 30;

  public static AAPFieldToolTip = "Acceptance into Administrative Allowance Program will require a 2% allowance " +
    "and exclude the supplier from the following fees; New Item Intro Fees, " +
    "New Product Set Up Fee, Lumping Fee, and EP/MCB Fee.";

  public static AAPDocumentationFile = "KeHE Supplier Administrative Allowance Program Overview.pdf";
}
