import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {map} from 'rxjs/operators';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { ResourceActions } from '../../../../constants/resource-actions';
import {
  bulkManageRecipientsClicked,
  closeSuccessfulTransactionAlert,
  loadSuppliersByEmail,
  whatsIncludedInMySubscriptionClick
} from '../../store/data-exchange.actions';
import {
  getShowFrequencyModal,
  hasTransactionSucceeded,
  selectActionsForBrokerActions,
  selectSelectedSupplierSubscriptionsLength,
  selectShowCancelEsnSubscriptionModal,
  selectShowManageRecipientsModal,
  selectShowSubscriptionOverviewModal
} from '../../store/data-exchange.selectors';
import { Observable } from 'rxjs';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { EsnSubscriptionBulkAction } from '../../models/enum/esn-subscription-bulk-action';
import { AlertEvents, IAlertEvent } from '@kehe/phoenix-notifications';
import { ExternalVideo } from '@app/models/external-video';
import { HelpCenterGroup } from '@app/modules/reporting/models/enum/help-center-group';
import { PermissionsService } from '@app/services/permissions.service';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';

@Component({
  selector: 'app-esn-subscriptions',
  templateUrl: './esn-subscriptions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EsnSubscriptionsComponent extends DestroyableDirective implements OnInit {

  constructor(
    private _store: Store,
    private _permSvc: PermissionsService,
    ) {
    super();
  }

  showHelpModal = false;
  helpVideos: ExternalVideo[] = [
    {
      videoTitle: 'Broker Data Exchange',
      vimeoId: '700527315/1faf0b33b3',
      group: HelpCenterGroup.ConnectBi,
    },
  ];

  companyName$: Observable<string>;
  showSubscriptionInfoModal$: Observable<boolean>;
  brokerBulkActionOptions$: Observable<OptionMenuItem[]>;
  selectSelectedSupplierSubscriptionsCount$: Observable<number>;
  showManageRecipientsModal$: Observable<boolean>;
  showCancelEsnSubscriptionModal$: Observable<boolean>;
  hasTransactionSucceeded$: Observable<any>;
  showFrequencyModal$: Observable<boolean>;
  thirdPartyUsers: boolean = false;
  alertMessage: string;

  ngOnInit() {
    this._store.dispatch(loadSuppliersByEmail());
    this.companyName$ = this._store.select(AuthenticationSelectors.selectUserCompany),
    this.showSubscriptionInfoModal$ = this._store.select(selectShowSubscriptionOverviewModal);
    this.hasTransactionSucceeded$ = this._store.select(hasTransactionSucceeded);
    this.selectSelectedSupplierSubscriptionsCount$ = this._store.select(selectSelectedSupplierSubscriptionsLength);
    this.showManageRecipientsModal$ = this._store.select(selectShowManageRecipientsModal);
    this.showCancelEsnSubscriptionModal$ = this._store.select(selectShowCancelEsnSubscriptionModal);
    this.brokerBulkActionOptions$ = this._store.select(selectActionsForBrokerActions);
    this.showFrequencyModal$ = this._store.select(getShowFrequencyModal);
    if (this._permSvc.userHasAction(ResourceActions.ThirdPartyOfRecord)) {
      this.thirdPartyUsers = true;
      this.brokerBulkActionOptions$ = this.brokerBulkActionOptions$.pipe(map(options=>options.filter(option=>option.name!=='Cancel')));
      this.alertMessage = "You have successfully registered for ESN.";
    }else{
      this.alertMessage = "You have successfully submitted your order.";
    }
  }

  onSubscriptionOverviewClick(): void {
    this._store.dispatch(whatsIncludedInMySubscriptionClick());
  }

  brokerBulkActionSelected(bulkAction: OptionMenuItem) {
    switch (bulkAction.name) {
      case EsnSubscriptionBulkAction.ManageRecipients:
        this._store.dispatch(bulkManageRecipientsClicked());
        break;
    }
  }

  onSuccessfulTransactionAlertEvent(alertEvent: IAlertEvent): void {
    if (alertEvent.type === AlertEvents.Closed) { this._store.dispatch(closeSuccessfulTransactionAlert()); }
  }

}
