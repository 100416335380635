import { createAction, props } from '@ngrx/store';
import { Payload } from '../../../models/payload';
import { SupplierListFilter } from '../models/supplier-list-filter';
import { SupplierListItem } from '../models/supplier-list-item';

export enum SupplierActions {
  LoadSupplierList = '[Supplier] Load Supplier List',
  LoadSupplierListSuccess = '[Supplier] Load Supplier List Success',
  LoadSupplierListError = '[Supplier] Load Supplier List Error',
  SupplierListPageChange = '[Supplier] Supplier List Page Change',
  OpenSupplierFilterPanel = '[Supplier] Open Supplier Filter Panel',
  CloseSupplierFilterPanel = '[Supplier] Close Supplier Filter Panel',
  ApplyFilter = '[Supplier] Apply Filters',
  GetDropdownFilterData = '[Supplier] Get Dropdown Filter Data',
  SupplierListSearchChange = '[Supplier] Supplier List Search Change',
  SupplierListSortChange = '[Supplier] Supplier List Sort Change',
  createSupplierListFilterChips = '[Supplier] Create Supplier List Filter Chips',
  SupplierListFilterChipClicked = '[Supplier] Supplier List Filter Chip Clicked',
  SupplierListFilterChipsClearAll = '[Supplier] Supplier List Filter Chips Clear All',
  CheckForPos = '[Supplier] Check if any Supplier has a PO to Review',
  CheckForPosSuccess = '[Supplier] Check if any Supplier has a PO to Review Success',
  HideAlertMessage = '[Supplier] Hide Alert Message',
}

export const loadSupplierList = createAction(
  SupplierActions.LoadSupplierList
);

export const loadSupplierListSuccess = createAction(
  SupplierActions.LoadSupplierListSuccess,
  props<{ suppliers: Payload<Array<SupplierListItem>> }>()
);

export const supplierListPageChange = createAction(
  SupplierActions.SupplierListPageChange,
  props<{ pageCount: number; pageIndex: number, skip: number }>()
);

export const openSupplierFilter = createAction(
  SupplierActions.OpenSupplierFilterPanel
);

export const closeSupplierFilter = createAction(
  SupplierActions.CloseSupplierFilterPanel
);

export const applyFilter = createAction(
  SupplierActions.ApplyFilter,
  props<{ supplierListFilter: SupplierListFilter }>()
);

export const supplierListSearchChange = createAction(
  SupplierActions.SupplierListSearchChange,
  props<{ search: string }>()
);

export const supplierListSortChange = createAction(
  SupplierActions.SupplierListSortChange,
  props<{ orderDirection: number, sortName: number }>()
);

export const createSupplierListFilterChips = createAction(
  SupplierActions.createSupplierListFilterChips
);

export const supplierListFilterChipClicked = createAction(
  SupplierActions.SupplierListFilterChipClicked,
  props<{ key: string }>()
);

export const SupplierListFilterChipsClearAll = createAction(
  SupplierActions.SupplierListFilterChipsClearAll
);

export const checkForPos = createAction(
  SupplierActions.CheckForPos
);

export const checkForPosSuccess = createAction(
  SupplierActions.CheckForPosSuccess,
  props<{ hasPoToReview: boolean }>()
);

export const hideAlertMessage = createAction(
  SupplierActions.HideAlertMessage
);