<div class="container">
    <div class="row mb-3">
      <div class="col-sm-12">
        <kendo-label>Supplier</kendo-label>
        <kendo-combobox
          name="select-supplier"
          id="select-supplier"
          placeholder="Select Supplier"
          [data]="supplierOptions$ | async"
          valueField="esn"
          textField="name"
          [kendoDropDownFilter]="{ operator: 'contains'}"
          [(ngModel)]="selectedSupplier"
          (ngModelChange)="onSupplierSelected()"
        ></kendo-combobox>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="d-flex">
          <kendo-label>DC</kendo-label>
          <div class="ms-auto">
            <app-select-all (clicked)="selectAllDcs()"></app-select-all>
          </div>
        </div>
        <kendo-multiselect 
          name="select-dc"
          id="select-dc"
          placeholder="Select DC(s)"
          [data]="dcOptions"
          valueField="id"
          textField="name"
          [kendoDropDownFilter]="{ operator: 'contains'}"
          [autoClose]="false"
          [(ngModel)]="selectedDcList"
          [tagMapper]="tagMapper"
          (ngModelChange)="onDcSelected()"
        ></kendo-multiselect>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="d-flex">
          <kendo-label>Brand</kendo-label>
          <div class="ms-auto" (click)="selectAllBrands()">
            <app-select-all (clicked)="selectAllBrands()"></app-select-all>
          </div>
        </div>
        <kendo-multiselect 
          name="select-brand"
          id="select-brand"
          placeholder="Select Brand"
          [data]="brandOptions"
          valueField="code"
          textField="name"
          [kendoDropDownFilter]="{ operator: 'contains'}"
          [autoClose]="false"
          [(ngModel)]="selectedBrandList"
          [loading]="isBrandsLoading"
          [disabled]="!brandEnabled()"
          [tagMapper]="tagMapper"
          (ngModelChange)="onBrandSelected()"
        ></kendo-multiselect>
      </div>
    </div>
</div>
