import { Injectable } from '@angular/core';
import { OrderDetailCloneOrderModalActions } from '@app/modules/orders-show/detail/components/order-detail-clone-order-modal/order-detail-clone-order-modal.actions';
import { PhoenixManageProgressPanelFacadeService } from '@kehe/phoenix-top-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as OrdersListShowActions from '../../../modules/orders-show/list/store/actions';
import { ManageProgressActions } from '../../../modules/promotional-roadmap/store/promotional-roadmap.actions';
import { CustomPromotionsActions } from '@app/modules/custom-promotions/store/custom-promotions.actions';

@Injectable({
  providedIn: 'root'
})
export class ManageProgressEffects {
  constructor(
    private _actions$: Actions,
    private _manageProgressFacade: PhoenixManageProgressPanelFacadeService
  ) {}

  // Used to trigger manage progress call for the first time to update the user syncing icon
  triggerGetManageProgressItems$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ManageProgressActions.triggerManageProgress,
          OrderDetailCloneOrderModalActions.requestItemsAfterCloning,
          OrdersListShowActions.downloadShowReportSuccess,
          OrdersListShowActions.downloadShowReportHistorySuccess,
          CustomPromotionsActions.triggerManageProgress
        ),
        tap(() => this._manageProgressFacade.requestItems())
      ),
    { dispatch: false }
  );
}
