import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { FsmaComplianceApiStore } from "./fsma-compliance-banner.api-store";
import { AlertEvents, IAlertEvent } from "@kehe/phoenix-notifications";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { selectIsFeatureFlagOn } from "@app/shared/state/feature-flag/feature-flag.selectors";
import { FeatureFlags } from "@app/feature-flag";

export interface FsmaComplianceState {}

const initialState: () => FsmaComplianceState = () => {
    const state: FsmaComplianceState = {};
    return state;
};

@Injectable()
export class FsmaComplianceStore extends ComponentStore<FsmaComplianceState> {
  constructor(
    private _apiStore: FsmaComplianceApiStore,
    private _router: Router,
    private _store: Store,
  ) {
    super(initialState());
    this._apiStore.getFsmaNonCompliantProductsCount$();
  }

  readonly vm$ = this.select(
    this._apiStore.isLoading$,
    this._apiStore.apiError$,
    this._apiStore.data$,
    this._store.select(selectIsFeatureFlagOn(FeatureFlags.EditFoodTraceability.key)),

    (
      isLoading,
      apiError,
      apiData,
      isFeatureFlagOn,
    ) => ({
      showBanner: !isLoading && isFeatureFlagOn && apiData > 0,
      apiError,
      priorityMsg: `You have <b>${apiData}</b> product(s) that require FSMA compliancy. Please take action promptly to ensure products are in compliance with FDA Traceability requirements as well as those of our mutual retailer(s).`,
      instructionsText: `<ul class='mt-2'>
        <li>If you have Food Traceability, go to the Food Traceability tab and complete all required fields.</li>
        <li>If you don’t have Food Traceability, either select No in the Food Traceability tab and Save, or select UPC(s) and use the Actions button to confirm no traceability.</li>
      </ul>`,
    }),
  );

  bannerClick(event: IAlertEvent) {
    switch (event.type) {
      case AlertEvents.Action1:
        this._router.navigate([`${this._router.url}/products`]);
        break;
    }
  }
}
