import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, filter, withLatestFrom } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import * as AuthenticationActions from '@app/authentication/authentication.actions';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Store } from '@ngrx/store';
import { PendoService } from '@app/pendo/pendo.service';
import { Account, Visitor } from '@app/pendo/interfaces/pendo.interfaces';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';
import * as SupplierDetailActions from '@app/modules/supplier-detail/store/supplier-detail.actions';
import * as ProductDetailActions from '@app/modules/product-detail/store/product-detail.actions';
import * as SupplierSelector from '@app/shared/state/supplier/supplier.selectors';
import * as ProductActions from '@app/modules/product-list/store/product-list.actions';
import * as SupplierOnboardingActions from '@app/modules/supplier-onboarding/store/supplier-onboarding.actions';
import { PendoTrackEventAction } from '@app/pendo/interfaces/pendo-track-event-action.interface';


/** Google Analytics function, defined in our index.html file */
declare const gtag: Function;

@Injectable()
export class MonitoringEffects {
  constructor(
    private actions$: Actions,
    private injector: Injector,
    private store: Store,
    private pendoService: PendoService
  ) {}

  setUpGoogleAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.logInSuccess),
        filter(() => !!environment.googleAnalyticsTrackingId),
        tap(() => {
          const gtagScript: HTMLScriptElement =
            document.createElement('script');
          gtagScript.async = true;
          gtagScript.src =
            'https://www.googletagmanager.com/gtag/js?id=' +
            environment.googleAnalyticsTrackingId;
          document.head.prepend(gtagScript);
          gtag('config', environment.googleAnalyticsTrackingId);
        })
      ),
    { dispatch: false }
  );

  setUpGoogleTagManager$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logInSuccess),
        filter(() => !!environment.googleTagManager),
        tap(() => {
          const gtmService = <GoogleTagManagerService>(
            this.injector.get(GoogleTagManagerService)
          );
          gtmService.addGtmToDom();
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Init func for Pendo.
   */
  setUpPendo$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.getUserDetailSuccess),
        withLatestFrom(
          this.store.select(AuthenticationSelectors.selectUserEmail),
          this.store.select(AuthenticationSelectors.selectIsInternalUser),
          this.store.select(AuthenticationSelectors.selectUserDetail)
        ),
        tap(([action, userEmail, isInternalUser, userDetails]) => {
          const visitor: Visitor = {
            id: userEmail,
            isInternalUser: isInternalUser,
            type: userDetails.type
          };

          this.pendoService.initializePendo(visitor);
        })
      );
    },
    { dispatch: false }
  );

  updateAccountContextSupplierDetail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SupplierDetailActions.loadSupplierDetailSuccess),
        tap((action) => {
          if (this.pendoService.shouldUpdateAccount(action.supplier.esn)) {
            const account: Account = {
              id: action.supplier.esn,
              name: action.supplier.name,
              status: action.supplier.status,
              inviteReasonType: action.supplier.inviteReasonType,
              categoryManagerCode: action.supplier.categoryManagerCode,
              categoryManagerEmail: action.supplier.categoryManagerEmail,
              supplierDevelopmentManagerCode:
                action.supplier.supplierDevelopmentManagerCode,
              supplierType: action.supplier.supplierType
            };

            this.pendoService.updateAccount(account);
          }
        })
      );
    },
    { dispatch: false }
  );

  updateAccountContextProductDetail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProductDetailActions.loadProductDetailSuccess),
        withLatestFrom(this.store.select(SupplierSelector.selectSuppliers)),
        tap(([action, suppliers]) => {
          const esnDetails = suppliers.find(
            (supplier) => supplier.esn === action.product.esn
          );

          if (
            esnDetails &&
            this.pendoService.shouldUpdateAccount(action.product.esn)
          ) {
            const account: Account = {
              id: action.product.esn,
              name: esnDetails.name,
              categoryManagerCode: action.product.categoryManager.code,
              categoryManagerEmail: action.product.categoryManager.email
            };

            this.pendoService.updateAccount(account);
          }
        })
      );
    },
    { dispatch: false }
  );

  trackPendoEvent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ProductActions.saveNewProductChangesSuccess,
          ProductDetailActions.submitProductSuccess,
          SupplierOnboardingActions.validateTabSuccess,
          SupplierOnboardingActions.sendDocusignEmailSuccess,
          SupplierDetailActions.clickPurchasingInfoScheduleButtonSuccess
        ),
        tap((action: PendoTrackEventAction) => {
          this.pendoService.trackEvent(
            action.pendoEvent.name,
            action.pendoEvent.eventProperties
          );
        })
      );
    },
    { dispatch: false }
  );
}
