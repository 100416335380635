import { TitleCasePipe } from '@angular/common';
import { OptionMenuItem } from '@kehe/phoenix-button';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartItem } from '../checkout/models/cart-item';
import { IAuthorizeNetResponse } from '../checkout/models/iauthorize-net-response';
import { BillingAddress, PaymentTransactionRequest, ThirdPartyTransactionRequest, User } from '../checkout/models/payment-transaction-request';
import { EsnSubscription } from '../models/esn-subscription';
import { ManageRecipientsForm } from '../models/manage-recipients-form';
import { ManageRecipientsRequest } from '../models/manage-recipients-request';
import { DataExchangeState, dataExchangeFeatureKey } from './data-exchange.reducer';
import { EsnSubscriptionBulkAction } from '../models/enum/esn-subscription-bulk-action';
import { EsnSubscriptionStatus } from '../models/enum/esn-subscription-status';
import { PaymentTokenErrorCodes } from '../checkout/models/checkout-errors';
import { CancelEsnSubscriptionRequest, CancelObjectList } from '../models/cancel-esn-subscription-request';
import { UpsertEsnSubscriptionRequest, UpsertObjectList } from '../models/upsert-esn-subscription-request';
import { EsnSubscriptionBillingFrequency } from '../models/enum/esn-subscription-billing-frequency';
import * as AuthenticationSelectors from '@app/shared/state/authentication/authentication.selectors';


export const getDataExchangeState = createFeatureSelector<DataExchangeState>(
  dataExchangeFeatureKey
);

export const selectFilter = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.filter
);

export const selectSuppliersByEmailFilter = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.suppliersByEmailFilter
);

export const selectAlertMessage = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.alertMessage
);

export const selectSubscriptions = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.subscriptions
);

export const getEsnsInCart = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.allItemsInCart.map(item => item.esn)
);

export const selectEsnSubscriptionsForGrid = createSelector(
  getDataExchangeState,
  getEsnsInCart,
  (state: DataExchangeState, esnsInCart: string[]) => {
    let selectedEsnSubscriptions = state.esnSubscriptions;
    if (!selectedEsnSubscriptions) { return null; }
    selectedEsnSubscriptions = selectedEsnSubscriptions.map(subscription => {
      return {
        ...subscription,
        isInCart: esnsInCart.includes(subscription.esn),
      };
    });
    return selectedEsnSubscriptions;
  }
);

export const selectIsLoading = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.isLoading
);

export const selectAvailableCount = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.availableCount
);

export const selectSkip = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.filter.skip
);

export const selectTake = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.filter.take
);

export const selectSupplierSkip = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.suppliersByEmailFilter.skip
);

export const selectSupplierTake = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.suppliersByEmailFilter.take
);

export const selectSort = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.filter.sort
);

export const selectSupplierSort = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.suppliersByEmailFilter.sort
);

export const selectShowAddSubscriptionModal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.showAddSubscriptionModal
);

export const selectSupplierOptions = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => {
    return state.supplierOptions.filter(supplier => !supplier.esn.startsWith('047')).map(supplier => {
      const dc = supplier.esn.substring(0, 3);
      if (dc === '045' || dc === '046') {
        return {...supplier, name: `${supplier.name} (${dc})`}
      }
      return supplier;    
    })
  }
);

export const selectCreateSubscriptionLoading = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.createSubscriptionLoading
);

export const selectShowInfoModal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.showInfoModal
);

export const selectInfoModalItem = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.infoModalItem
);

export const selectRetailerAreaNames = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.retailerAreaNames
);

export const selectShowDeleteSubscriptionModal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.showDeleteSubscriptionModal
);

export const selectDeleteModalItem = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.deleteModalItem
);

export const selectDeleteSubscriptionsLoading = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.deleteSubscriptionsLoading
);

export const selectSelectedSubscriptions = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.selectedSubscriptions
);

export const selectSelectedSupplierSubscriptions = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.selectedSupplierSubscriptions
);

export const getSelectedEsnsForGrid = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.selectedSupplierSubscriptions.map(sub => sub.esn)
);

export const selectSelectedSubscriptionsLength = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.selectedSubscriptions.length
);

export const selectSelectedSupplierSubscriptionsLength = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.selectedSupplierSubscriptions.length
);

export const selectEsnSubscriptionToTakeActionOn = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.esnSubscriptionToTakeActionOn
);

export const selectItems = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.items
);

export const selectSkipForCart = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.itemsToSkip
);

export const selectTakeForCart = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.itemsToTake
);

export const getAllItemsInCart = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.allItemsInCart
);

export const getBillingFrequency = createSelector(
  getDataExchangeState,
  getAllItemsInCart,
  (state: DataExchangeState, cartItems: CartItem[]) => {
    if (cartItems && cartItems.length > 0) { return cartItems[0].frequency; }
    return EsnSubscriptionBillingFrequency.Monthly;
  }
);

export const getEsnSubscriptionsForCart = createSelector(
  selectSelectedSupplierSubscriptions,
  selectEsnSubscriptionToTakeActionOn,
  getBillingFrequency,
  (esnSubscriptions: EsnSubscription[], esnSubscription: EsnSubscription, frequency: EsnSubscriptionBillingFrequency) => {
    if (!esnSubscription && (!esnSubscriptions || esnSubscriptions.length === 0)) { return null; }
    const esnsForCart = esnSubscription ? [esnSubscription] : esnSubscriptions;
    const cartItems: CartItem[] = [];
    const titleCasePipe = new TitleCasePipe();
    esnsForCart.forEach(esnForCart => {
      const cartItem = <CartItem>{
        esn: esnForCart.esn,
        supplierName: esnForCart.supplierName,
        frequency: frequency,
        itemName: `${titleCasePipe.transform(frequency)} Subscription`,
        itemDescription: `Data Exchange ${titleCasePipe.transform(frequency)} Subscription for Broker w/ Auto-Renewal`,
        quantity: 1,
        price: frequency === EsnSubscriptionBillingFrequency.Yearly ? 500 : 8
      };
      cartItems.push(cartItem);
    });
    return cartItems;
  }
);



export const selectSupplierBrandList = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.supplierBrandList
);

export const selectProducts = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.products
);

export const getNumberOfItemsInCart = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.allItemsInCart.length
);

export const getCartTotal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => {
    return state.allItemsInCart
    .map((item, i) => item.price)
    .reduce((accumulator, value) => accumulator + value, 0);
  }
);

export const selectIsOrderProcessing = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.isOrderProcessing
);

export const selectPaymentTokenResponse = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.paymentTokenResponse
);

export const selectPaymentFailedErrorMessage = createSelector(
  getDataExchangeState,
  selectPaymentTokenResponse,
  (state: DataExchangeState, selectedPaymentTokenResponse: IAuthorizeNetResponse) => {
    if (selectedPaymentTokenResponse
      && !selectedPaymentTokenResponse.succeeded
      && selectedPaymentTokenResponse.messages) {
      let errorMessage = mapPaymentTokenResponseErrorMessage(selectedPaymentTokenResponse.messages.message[0].code);
      errorMessage = appendEmailToErrorMessage(errorMessage);
      return errorMessage;
    }
    if (state.transactionResponse && !state.transactionResponse.success) {
      let errorMessage = mapPaymentTokenResponseErrorMessage('');
      errorMessage = appendEmailToErrorMessage(errorMessage);
      return errorMessage;
    }
  }
);

export const hasTransactionSucceeded = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.transactionResponse && state.transactionResponse.success
);

export const selectShowManageRecipientsModal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.showManageRecipientsModal
);

export const selectShowSubscriptionOverviewModal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.showSubscriptionOverviewModal
);

export const selectPaymentTransactionRequest = createSelector(
  AuthenticationSelectors.selectUserFirstName,
  AuthenticationSelectors.selectUserLastName,
  AuthenticationSelectors.selectUserEmail,
  AuthenticationSelectors.selectUserCompany,
  AuthenticationSelectors.selectUserNumber,
  getAllItemsInCart,
  getCartTotal,
  selectPaymentTokenResponse,
  (selectedFirstName: string, selectedLastName: string, selectedEmail: string, selectedCompany: string,
    selectedUserNumber: string, selectedCartItems: CartItem[], selectedCartTotal: number,
    selectedPaymentToken: IAuthorizeNetResponse) => {
    if (!selectedCartItems || !selectedPaymentToken || !selectedPaymentToken.succeeded) { return null; }
    const request: PaymentTransactionRequest = {
      brokers: [],
      suppliers: [],
      user: undefined,
      opaqueData: undefined,
      totalSubscriptionAmount: selectedCartTotal,
      billingFrequency: selectedCartItems[0].frequency,
    };

    request.brokers = [{ ebn: selectedUserNumber, name: selectedCompany }];
    request.suppliers = selectedCartItems.map(item => {
      return { esn: item.esn, name: item.supplierName };
    });
    request.opaqueData = selectedPaymentToken.opaqueData;
    request.user = <User>{
      email: selectedEmail,
      fullname: `${selectedFirstName} ${selectedLastName}`,
    };
    request.user.billto = <BillingAddress>{
      Address: selectedPaymentToken.billingAddress.address,
      City: selectedPaymentToken.billingAddress.city,
      Company: selectedCompany,
      FirstName: selectedPaymentToken.billingAddress.firstName,
      LastName: selectedPaymentToken.billingAddress.lastName,
      PhoneNumber: '555-555-5555',
      Zip: selectedPaymentToken.billingAddress.zipCode,
      State: selectedPaymentToken.billingAddress.state,
      Country: 'US'
    };

    return request;
  }
);
export const selectThirdPartyRequest = createSelector(
  AuthenticationSelectors.selectUserFirstName,
  AuthenticationSelectors.selectUserLastName,
  AuthenticationSelectors.selectUserEmail,
  AuthenticationSelectors.selectUserCompany,
  AuthenticationSelectors.selectUserNumber,
  selectEsnSubscriptionToTakeActionOn,
  (selectedFirstName: string, selectedLastName: string, selectedEmail: string, selectedCompany: string,
    selectedUserNumber: string, selectedEsnSubscription: EsnSubscription) => {
    const request: ThirdPartyTransactionRequest = {
      brokers: [],
      suppliers: [],
      user: undefined,
    };

    request.brokers = [{ ebn: selectedUserNumber, name: selectedCompany }];
    request.suppliers = [{
      esn: selectedEsnSubscription?.esn,
      name: selectedEsnSubscription?.supplierName,
    }];
    request.user = <User>{
      email: selectedEmail,
      fullname: `${selectedFirstName} ${selectedLastName}`,
    };

    return request;
  }
);

export const selectThirdPartyBulkRequest = createSelector(
  AuthenticationSelectors.selectUserFirstName,
  AuthenticationSelectors.selectUserLastName,
  AuthenticationSelectors.selectUserEmail,
  AuthenticationSelectors.selectUserCompany,
  AuthenticationSelectors.selectUserNumber,
  selectSelectedSupplierSubscriptions,
  (selectedFirstName: string, selectedLastName: string, selectedEmail: string, selectedCompany: string,
    selectedUserNumber: string, selectedEsnSubscription: EsnSubscription[]) => {
    const request: ThirdPartyTransactionRequest = {
      brokers: [],
      suppliers: [],
      user: undefined,
    };

    request.brokers = [{ ebn: selectedUserNumber, name: selectedCompany }];
    request.suppliers = selectedEsnSubscription.map(item => {
      return { esn: item.esn, name: item.supplierName };
    });
    request.user = <User>{
      email: selectedEmail,
      fullname: `${selectedFirstName} ${selectedLastName}`,
    };

    return request;
  }
);

export const selectManageRecipientsBulkMode = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.manageRecipientsBulkMode
);

export const selectAvailbleRecipientEmailListLoading = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.isRecipientEmailListLoading
);

export const selectAvailbleRecipientEmails = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.recipientEmailList.sort()
);

export const selectManageRecipientsFormValue = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.manageRecipientsFormValue
);

export const selectManageRecipientsFormUpdateInProgress = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.manageRecipientsFormUpdateInProgress
);

export const selectManageRecipientsUpdateRequest = createSelector(
  AuthenticationSelectors.selectUserNumber,
  AuthenticationSelectors.selectUserCompany,
  selectEsnSubscriptionToTakeActionOn,
  selectManageRecipientsFormValue,
  selectManageRecipientsBulkMode,
  (userNumber: string, userCompany: string,
    selectedEsnSubscription: EsnSubscription, selectedFormValue: ManageRecipientsForm, isBulkMode) => {
    if (isBulkMode || !selectedFormValue) { return null; }
    const request: ManageRecipientsRequest = {
      broker: {
        ebn: userNumber,
        name: userCompany
      },
      supplier: {
        esn: selectedEsnSubscription.esn,
        name: selectedEsnSubscription.supplierName,
      },
      days: [selectedFormValue.day],
      recipientEmailList: selectedFormValue.emails.map(email => ({ value: email })),
    };
    return request;
  }
);

export const selectManageRecipientsUpdateInBulkRequest = createSelector(
  AuthenticationSelectors.selectUserNumber,
  AuthenticationSelectors.selectUserCompany,
  selectSelectedSupplierSubscriptions,
  selectManageRecipientsFormValue,
  selectManageRecipientsBulkMode,
  (userNumber: string, userCompany: string,
    selectedEsnSubscriptions: EsnSubscription[], selectedFormValue: ManageRecipientsForm, isBulkMode) => {
    if (!selectedFormValue) { return null; }
    if (!isBulkMode) { return null; }
    const baseRequest: ManageRecipientsRequest = {
      broker: {
        ebn: userNumber,
        name: userCompany
      },
      supplier: {
        esn: '',
        name: '',
      },
      days: [selectedFormValue.day],
      recipientEmailList: selectedFormValue.emails.map(email => ({ value: email })),
    };

    const requests: ManageRecipientsRequest[] = [];
    selectedEsnSubscriptions.forEach(subscription => {
      const requestForEsn = {
        ...baseRequest,
        customerProfileId: subscription.customerProfileId,
        supplier: {
          ...baseRequest.supplier,
          esn: subscription.esn,
          name: subscription.supplierName,
        }
      };
      requests.push(requestForEsn);
    });

    return requests;
  }
);

export const selectManageRecipientsUpdateErrorMessage = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.manageRecipientsUpdateErrorMessage
);

export const selectActionsForBrokerActions = createSelector(
  selectSelectedSupplierSubscriptions,
  (selectedSupplierSubscriptions: EsnSubscription[]) => {
    if (!selectedSupplierSubscriptions) { return null; }
    const uniqueStatuses = new Set(selectedSupplierSubscriptions.map(sub => sub.status));
    const isManageRecipientsEnabled = !uniqueStatuses.has(EsnSubscriptionStatus.Unregistered) && !uniqueStatuses.has(EsnSubscriptionStatus.Expired);
    const menuOptions: OptionMenuItem[] = [
      new OptionMenuItem(EsnSubscriptionBulkAction.ManageRecipients, EsnSubscriptionBulkAction.ManageRecipients,isManageRecipientsEnabled)
    ];
    return menuOptions;
  }
);

export const selectShowCancelEsnSubscriptionModal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.showCancelEsnSubscriptionModal
);

export const selectEsnSubscriptionsToPerformAnActionOnCount = createSelector(
  selectEsnSubscriptionToTakeActionOn,
  selectSelectedSupplierSubscriptions,
  (selectedEsnToTakeActionOn: EsnSubscription, selectedSupplierSubscriptions: EsnSubscription[]) => {
    return selectedEsnToTakeActionOn ? 1 : selectedSupplierSubscriptions.length;
  }
);

export const selectCancelEsnSubscriptionsRequest = createSelector(
  selectEsnSubscriptionToTakeActionOn,
  selectSelectedSupplierSubscriptions,
  (selectedEsnToTakeActionOn: EsnSubscription, selectedSupplierSubscriptions: EsnSubscription[]) => {
    if (!selectedEsnToTakeActionOn && (!selectedSupplierSubscriptions || selectedSupplierSubscriptions.length === 0)) { return null; }
    const esnsToCancel = selectedEsnToTakeActionOn ? [selectedEsnToTakeActionOn] : selectedSupplierSubscriptions;
    const request: CancelEsnSubscriptionRequest = {
      ebn: esnsToCancel[0].ebn,
      cancelObjectList: esnsToCancel.map(subscription => {
        return <CancelObjectList>{
           customerProfileId: subscription.customerProfileId,
           subscriptionId: subscription.subscriptionId,
         };
      })
    };
    return request;
  }
);

export const selectUpsertEsnSubscriptionsRequest = createSelector(
  selectEsnSubscriptionToTakeActionOn,
  selectSelectedSupplierSubscriptions,
  (selectedEsnToTakeActionOn: EsnSubscription, selectedSupplierSubscriptions: EsnSubscription[]) => {
    if (!selectedEsnToTakeActionOn && (!selectedSupplierSubscriptions || selectedSupplierSubscriptions.length === 0)) { return null; }
    const esns = selectedEsnToTakeActionOn ? [selectedEsnToTakeActionOn] : selectedSupplierSubscriptions;
    const request: UpsertEsnSubscriptionRequest = {
      ebn: esns[0].ebn,
      upsertObjectList: esns.map(subscription => {
        return <UpsertObjectList>{
           customerProfileId: subscription.customerProfileId,
           subscriptionId: subscription.subscriptionId,
         };
      })
    };
    return request;
  }
);

export const selectCancelEsnSubscriptionUpdateInProgress = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.cancelEsnSubscriptionUpdateInProgress
);

export const selectCancelEsnSubscriptionErrorMessage = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.cancelEsnSubscriptionErrorMessage
);

export const selectregisterPreviouslyCanceledEsn = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.registerPreviouslyCanceledEsnProgress
);
export const selectregisterPreviouslyCanceledEsnrow = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) =>  state.esnSubscriptionToTakeActionOn
);
export const selectCurrentEsn = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.esn
);
export const isBulkAction = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.bulkAction
);


export const getShowFrequencyModal = createSelector(
  getDataExchangeState,
  (state: DataExchangeState) => state.showFrequencyModal
);

function mapPaymentTokenResponseErrorMessage(errorCode: string): string {
  let errorMessage = '';
  switch (errorCode) {
    case PaymentTokenErrorCodes.ACCEPT_JS_NOT_LOADED:
    case PaymentTokenErrorCodes.AN_ERROR_OCCURRED:
    case PaymentTokenErrorCodes.CLIENT_KEY_REQUIRED:
    case PaymentTokenErrorCodes.ENCRYPTION_FAILED:
    case PaymentTokenErrorCodes.HTTPS_REQUIRED:
    case PaymentTokenErrorCodes.INCLUDE_ACCEPT_JS_CDN:
    case PaymentTokenErrorCodes.INVALID_API_LOGIN_ID:
    case PaymentTokenErrorCodes.INVALID_AUTHENTICATION:
    case PaymentTokenErrorCodes.INVALID_FINGERPRINT:
      errorMessage = 'An error occurred while processing your transaction.';
      break;
    case PaymentTokenErrorCodes.INVALID_ZIP_CODE:
      errorMessage = 'Your billing information is invalid. Please correct and submit again.';
      break;
    case PaymentTokenErrorCodes.BOTH_CARD_AND_BANK_INFO_PROVIDED:
    case PaymentTokenErrorCodes.CARD_EXPIRY_NOT_IN_FUTURE:
    case PaymentTokenErrorCodes.INVALID_BANK_ACCOUNT_HOLDER_NAME:
    case PaymentTokenErrorCodes.INVALID_BANK_ACCOUNT_NUMBER:
    case PaymentTokenErrorCodes.INVALID_BANK_ACCOUNT_TYPE:
    case PaymentTokenErrorCodes.INVALID_BANK_ROUTING_NUMBER:
    case PaymentTokenErrorCodes.INVALID_CARD_CVV:
    case PaymentTokenErrorCodes.INVALID_CARD_EXPIRY_MONTH:
    case PaymentTokenErrorCodes.INVALID_CARD_EXPIRY_YEAR:
    case PaymentTokenErrorCodes.INVALID_CARD_HOLDER_NAME:
    case PaymentTokenErrorCodes.INVALID_CREDIT_CARD_NUMBER:
    case PaymentTokenErrorCodes.MISSING_REQUIRED_FIELDS:
      errorMessage = 'Your payment information is invalid. Please correct and submit again.';
      break;
    default:
      return 'An unexpected error occurred while processing your transaction.';
  }
  return `${errorMessage} (error code: ${errorCode})`;
}

function appendEmailToErrorMessage(errorMessage: string): string {
  const email = `For questions, email <a href="mailto:DL-Connect.Supplier_Feedback@kehe.com">DL-Connect.Supplier_Feedback@kehe.com</a>.`;
  return `${errorMessage}. ${email}`;
}
