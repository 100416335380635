import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleAnalyticsService } from './services/googleAnalytics.service';
import { HelpModule } from './modules/help/help.module';
import { MockSuggestiveSearchV2Component } from './tests/mock-suggestive-search-v2/mock-suggestive-search-v2.component';
import {
  DataExchangeModule,
  dataExchangeRoute
} from './modules/data-exchange/data-exchange.module';
import { DataExchangePageComponent } from './modules/data-exchange/pages/data-exchange-page/data-exchange-page.component';
import {
  ReportingModule,
  ssrsReportsRoute
} from './modules/reporting/reporting.module';
import { ReportingPageComponent } from './modules/reporting/pages/reporting-page/reporting-page.component';
import { NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { PagerModule } from '@progress/kendo-angular-pager';
import { PhoenixVideoPlayerModule } from '@kehe/phoenix-video-player';
import { PhoenixModalModule } from '@kehe/phoenix-modal';
import { AppComponent } from './app.component';
import { CsNavigationComponent } from './components/cs-navigation/cs-navigation.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AuthGuardService } from '@app/authentication/auth-guard.service';
import { MockComponentComponent } from './tests/mock-component/mock-component.component';
import { SubNavigationComponent } from './components/sub-navigation/sub-navigation.component';
import { GlobalService } from './services/global.service';
import { EffectsModule } from '@ngrx/effects';
import { TileCountComponent } from './components/tile-count/tile-count.component';
import { TileCountAltComponent } from './components/tile-count-alt/tile-count-alt.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AuthTokenInterceptor } from '@app/authentication/auth-token.interceptor';
import {
  analyticsDashboardRoute,
  AnalyticsPageComponent
} from './pages/analytics-page/analytics-page.component';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { priceChangeMgmtUrl } from './utilities/url-utility';
import { HealthPageComponent } from './pages/health-page/health-page.component';
import {
  gapVoidDashboardRoute,
  GapVoidPageComponent
} from './pages/gap-void-page/gap-void-page.component';
import {
  orderProjectionDashboardRoute,
  OrderProjectionPageComponent
} from './pages/order-projection-page/order-projection-page.component';
import { HelpCenterPageComponent } from './pages/help-center-page/help-center-page.component';
import { ShowAnnouncementsComponent } from './components/show-announcements/show-announcements.component';
import { TrainingVideosComponent } from './components/training-videos/training-videos.component';
import { VideoCardItemComponent } from './components/video-card/video-card.component';
import { VideoPlayerModalComponent } from './components/video-player-modal/video-player-modal.component';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { UserNotificationsStoreModule } from './modules/user-notifications/user-notifications-store.module';
import { PhoenixTopBarModule } from '@kehe/phoenix-top-bar';
import { PhoenixCommonLayoutsModule } from '@kehe/phoenix-layouts';
import { SupplierListModule } from './modules/supplier-list/supplier-list.module';
import { SupplierDetailHeaderComponent } from './components/supplier-detail-header/supplier-detail-header.component';
import { LabelModule } from '@progress/kendo-angular-label';

import { UserAgreementModalComponent } from './components/user-agreement-modal/user-agreement-modal.component';
import {
  ButtonGroupModule,
  ButtonModule as KendoButtonModule
} from '@progress/kendo-angular-buttons';
import {
  CheckBoxModule as KendoCheckboxModule,
  InputsModule
} from '@progress/kendo-angular-inputs';
import { MessagesModule } from './modules/messages/messages.module';
import { PhoenixTopNavigationPanelModule } from '@kehe/phoenix-top-navigation-panel';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { ManagePricePageComponent } from './modules/manage-prices/pages/manage-price-page/manage-price-page.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { AddDraftFutureLineItemToastComponent } from './components/add-draft-future-line-item-toast/add-draft-future-line-item-toast.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { HelpCenterPageShowComponent } from './pages/help-center-page-show/help-center-page-show.component';
import { TrainingVideosShowComponent } from './components/training-videos-show/training-videos-show.component';
import { FaqsShowComponent } from './components/faqs-show/faqs-show.component';
import { PhoenixSearchModule } from '@kehe/phoenix-search';
import { PhoenixCarouselModule } from '@kehe/phoenix-carousel';
import { BiReportModule } from './modules/bi-report/bi-report.module';
import { PhoenixAlertsModule } from '@kehe/phoenix-notifications';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';
import { PhoenixFooterModule } from '@kehe/phoenix-footer';
import { PhoenixNavigationPanelModule } from '@kehe/phoenix-navigation-panel';
import { CartComponent } from './modules/data-exchange/checkout/components/cart/cart.component';
import { NotificationService } from '@progress/kendo-angular-notification';
import { BreadCrumbModule } from '@progress/kendo-angular-navigation';
import { PhoenixErrorsModule } from '@kehe/phoenix-errors';
import { ResourcesPageComponent } from './modules/resources-module/pages/resources-page/resources-page.component';
import { RecentAnnouncementsModule } from '@app/modules/announcements/recent-announcements/recent-announcements.module';
import {
  AbstractFeedbackService,
  PhoenixFeedbackModule,
  FeedbackStore
} from '@kehe/phoenix-feedback';
import { FeedbackService } from './services/feedback.service';
import { AnnouncementsListRouterLinks } from '@app/modules/announcements/announcements-list/enums/announcements-list-router-links.enum';
import { AnnouncementsListGuard } from '@app/modules/announcements/announcements-list/guards/announcements-list.guard';
import { EventsBannerComponent } from './components/events-banner/events-banner.component';
import { reducers } from './shared/state/index';
import { FeatureFlagModule } from './feature-flag/feature-flag.module';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import { PromotionalRoadmapPageComponent } from './modules/promotional-roadmap/pages/promotional-roadmap-page/promotional-roadmap-page.component';
import { ManageProgressEffects } from './shared/state/manage-progress/manage-progress.effects';
import { KeheUtilDirectivesModule } from '@kehe/phoenix-utils';
import { AuthenticationModule } from './authentication/authentication.module';
import { CallbackPageComponent } from './authentication/components/callback-page/callback-page.component';
import { DeviceModule } from './device/device.module';
import { DistributionCenterModule } from './distribution-center/distribution-center.module';
import { FaqModule } from './faq/faq.module';
import { BreadcrumbsEffects } from './breadcrumbs/breadcrumbs.effects';
import { SupplierEffects } from './supplier/supplier.effects';
import { ShowEffects } from './show/show.effects';
import { MonitoringEffects } from './monitoring/monitoring.effects';
import { UserAgreementStoreModule } from './modules/user-agreement/store/user-agreement-store.module';
import { PhoenixCardBlocksModule } from '@kehe/phoenix-card-blocks';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { CustomPromotionsPageComponent } from './modules/custom-promotions/pages/custom-promotions-page/custom-promotions-page.component';
import { CustomPromotionsGuard } from './modules/custom-promotions/guards/custom-promotions.guard';
import { FsmaComplianceBannerComponent } from './shared/components/fsma-compliance-banner/fsma-compliance-banner.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

const appRoutes: Routes = [
  { path: '', component: HomePageComponent, canActivate: [AuthGuardService] },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'callback', component: CallbackPageComponent },
  { path: 'unauthorized', component: UnauthorizedPageComponent },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./modules/my-profile/my-profile.module').then(
        (m) => m.MyProfileModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'products/:upc',
    loadChildren: () =>
      import('./modules/product-detail/product-detail.module').then(
        (m) => m.ProductDetailModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./modules/product-list/product-list.module').then(
        (m) => m.ProductListModule
      ),
    canActivate: [AuthGuardService]
  },
  { path: 'show/products', redirectTo: '/products', pathMatch: 'full' },
  {
    path: analyticsDashboardRoute,
    component: AnalyticsPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: ssrsReportsRoute,
    component: ReportingPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: dataExchangeRoute,
    component: DataExchangePageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: gapVoidDashboardRoute,
    component: GapVoidPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: orderProjectionDashboardRoute,
    component: OrderProjectionPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: priceChangeMgmtUrl,
    redirectTo: 'manage-prices',
    pathMatch: 'full'
  },
  { path: 'health', component: HealthPageComponent },
  {
    path: 'help',
    component: HelpCenterPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'show/help',
    component: HelpCenterPageShowComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'supplier',
    loadChildren: () =>
      import('./modules/supplier-list/supplier-list.module').then(
        (m) => m.SupplierListModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'draft-supplier',
    loadChildren: () =>
      import('./modules/supplier-list/supplier-list.module').then(
        (m) => m.SupplierListModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'supplier/:esn',
    loadChildren: () =>
      import('./modules/supplier-detail/supplier-detail.module').then(
        (m) => m.SupplierDetailModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'show/:showId/orders',
    loadChildren: () =>
      import('./modules/orders-show/orders-show.module').then(
        (m) => m.OrdersShowModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'manage-prices',
    component: ManagePricePageComponent,
    loadChildren: () =>
      import('./modules/manage-prices/manage-prices.module').then(
        (m) => m.SupplierPriceChangeWrapperModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'resources',
    component: ResourcesPageComponent,
    loadChildren: () =>
      import('./modules/resources-module/resources.module').then(
        (m) => m.ResourcesWrapperModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: AnnouncementsListRouterLinks.List,
    loadChildren: () =>
      import(
        './modules/announcements/announcements-list/announcements-list.module'
      ).then((m) => m.AnnouncementsListModule),
    canActivate: [AuthGuardService, AnnouncementsListGuard]
  },
  {
    path: 'promotional-roadmap',
    component: PromotionalRoadmapPageComponent,
    loadChildren: () =>
      import('./modules/promotional-roadmap/promotional-roadmap.module').then(
        (m) => m.PromotionalRoadmapWrapperModule
      ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'custom-promotions',
    component: CustomPromotionsPageComponent,
    loadChildren: () =>
      import('./modules/custom-promotions/custom-promotions.module').then(
        (m) => m.CustomPromotionsWrapperModule
      ),
    canActivate: [AuthGuardService, CustomPromotionsGuard]
  }
];

const conditionalProviders = [];
if (environment.googleTagManager) {
  conditionalProviders.push({
    provide: 'googleTagManagerId',
    useValue: environment.googleTagManager
  });
}

@NgModule({
  declarations: [
    AppComponent,
    CsNavigationComponent,
    HomePageComponent,
    MockComponentComponent,
    MockSuggestiveSearchV2Component,
    SubNavigationComponent,
    TileCountComponent,
    TileCountAltComponent,
    AnalyticsPageComponent,
    UnauthorizedPageComponent,
    HealthPageComponent,
    GapVoidPageComponent,
    OrderProjectionPageComponent,
    HelpCenterPageComponent,
    ShowAnnouncementsComponent,
    TrainingVideosComponent,
    VideoCardItemComponent,
    VideoPlayerModalComponent,
    SupplierDetailHeaderComponent,
    UserAgreementModalComponent,
    ManagePricePageComponent,
    AddDraftFutureLineItemToastComponent,
    HelpCenterPageShowComponent,
    TrainingVideosShowComponent,
    FaqsShowComponent,
    CartComponent,
    ResourcesPageComponent,
    EventsBannerComponent,
    PromotionalRoadmapPageComponent,
    CustomPromotionsPageComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    PhoenixTopBarModule,
    PhoenixTopNavigationPanelModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled'
    }),
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode'
    }),
    MessagesModule,
    PagerModule,
    GridModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([
      BreadcrumbsEffects,
      ManageProgressEffects,
      MonitoringEffects,
      ShowEffects,
      SupplierEffects
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'Supplier Portal',
      connectInZone: true
    }),
    UserNotificationsStoreModule,
    PhoenixVideoPlayerModule,
    BiReportModule,
    PhoenixAlertsModule,
    PhoenixModalModule,
    ReportingModule,
    DataExchangeModule,
    HelpModule,
    SupplierListModule,
    LabelModule,
    ButtonGroupModule,
    KendoButtonModule,
    KendoCheckboxModule,
    InputsModule,
    PhoenixButtonModule,
    DropDownListModule,
    PhoenixSearchModule,
    PhoenixCarouselModule,
    PhoenixIndicatorsModule,
    PhoenixFooterModule,
    PhoenixNavigationPanelModule,
    BreadCrumbModule,
    PhoenixErrorsModule,
    RecentAnnouncementsModule,
    PhoenixFeedbackModule,
    PhoenixCommonLayoutsModule,
    AuthenticationModule,
    FeatureFlagModule,
    DeviceModule,
    DistributionCenterModule,
    FaqModule,
    UserAgreementStoreModule,
    KeheUtilDirectivesModule,
    PhoenixCardBlocksModule,
    ChatBotComponent,
    FsmaComplianceBannerComponent,
    ScrollingModule,
    ExperimentalScrollingModule
  ],
  providers: [
    NotificationService,
    AuthGuardService,
    GlobalService,
    CanDeactivateGuardService,
    GoogleAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    FeedbackStore,
    { provide: AbstractFeedbackService, useClass: FeedbackService },
    ...conditionalProviders,
    [{ provide: ICON_SETTINGS, useValue: { type: 'font' } }],
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
