import {
  TreatmentWithConfig,
  TreatmentsWithConfig,
} from "@splitsoftware/splitio/types/splitio";

class FeatureFlag {
  constructor(
    public key: string,
    public isOn: boolean,
    public config?: string
  ) {}
}

export const FeatureFlags = {
  SupplierOrderProjectionsReport: new FeatureFlag('Supplier_Order_Projections_Report', false),
  SupplierPurchasingOptionsEdit: new FeatureFlag(
    'Supplier_PurchasingOptions_Edit',
    false
  ),
  Messaging: new FeatureFlag('web-messaging', false),
  EnterPriseProductEditScheduleNonDateFields: new FeatureFlag(
      'Enterprise_Product_Edit_ScheduleNonDateFields',
      false
  ),
  CreateOrderMultiModalPendingTab: new FeatureFlag(
      'Create_Order_Multi_Modal_Pending_Tab',
      false
  ),
  EnterpriseProductLinkedDrafts: new FeatureFlag('Enterprise_Product_Linked_Drafts', false),
  SupplierProductApplyAll: new FeatureFlag('Supplier_Product_Add_ApplyAll', false),
  ProductComparableUpcRefreshPricing: new FeatureFlag('Product_comparable_upc_refresh_pricing', false),
  Food_Traceability: new FeatureFlag('Food_Traceability', false),
  ProductEditFoodTraceability: new FeatureFlag('Edit_Food_Traceability', false),
  DataExchangeForBrokers: new FeatureFlag('Supplier_D4b', null),
  DataExchangeTrialPeriod: new FeatureFlag('supplier_d4b_trial_period', false),
  SupplierGeneralTabView: new FeatureFlag('Supplier_GeneralTab_View', false),
  ProductSupplierGtinValidation: new FeatureFlag('Product_Supplier_Inner_Pack_Gtin_Validator', false),
  BrokerV2: new FeatureFlag('SBM_FE_MENU_ITEM', false),
  PurchasingOptionSupplierDown: new FeatureFlag('PurchasingOption_SUP_Down', false),
  AAPTerm: new FeatureFlag('CSP-Supplier_AAPTerm', false),
  ChatBot: new FeatureFlag('CONNECT_Supplier_Chatbot', false),
  ManageCustomPromotions: new FeatureFlag('CS_PC793_Manage_Custom_Promotions', false),
  EditFoodTraceability: new FeatureFlag('Edit_Food_Traceability', false),

  /***************************
   * Permanent Feature Flags *
   ***************************/
  SupplierOutageBanner: new FeatureFlag('Supplier_Top_Level_Banner', false),
  SupplierVendorPackets: new FeatureFlag('CSP-Supplier_Onboarding-Vendor-Packet', false),
  AnalyticsDashboardBanner: new FeatureFlag('Supplier_Analytics_Dashboard_Banner', false),
  /***************************
   * * * * * * end * * * * * *
   ***************************/
};

export function mapFeatureFlagsToSplitTreatmentsWithConfig(): TreatmentsWithConfig {
  let treatmentsWithConfig: TreatmentsWithConfig = {};
  const featureFlags: FeatureFlag[] = Object.values(FeatureFlags);
  featureFlags.forEach((featureFlag) => {
    const treatmentWithConfig: TreatmentWithConfig = {
      treatment: featureFlag.isOn ? "on" : "off",
      config: featureFlag.config,
    };
    treatmentsWithConfig[featureFlag.key] = treatmentWithConfig;
  });
  return treatmentsWithConfig;
}

export const FeatureFlagKeys = Object.values(FeatureFlags).map((f) => f.key);
