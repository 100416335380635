import { MessagesFacade } from './store/messages.facade';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChatBubbleComponent } from './components/chat-bubbles/chat-bubble.component';
import { MessagesPageComponent } from './pages/messages-page/messages-page.component';
import { ChatDrawerComponent } from './components/chat-drawer/chat-drawer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MessagesEffects } from './store/messages.effects';
import * as fromReducers from './store/messages.reducer';
import { MessagesService } from './service/messages.service';
import { ChatDrawerNewComponent } from './components/chat-drawer-new/chat-drawer-new.component';
import { PhoenixButtonModule } from '@kehe/phoenix-button';
import { PhoenixIndicatorsModule } from '@kehe/phoenix-indicators';

@NgModule({
  declarations: [
    ChatDrawerComponent,
    ChatBubbleComponent,
    MessagesPageComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('messagesState', fromReducers.messagesReducer),
    EffectsModule.forFeature([MessagesEffects]),
    GridModule,
    NgxSkeletonLoaderModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    NavigationModule,
    DateInputsModule,
    FormsModule,
    RouterModule,
    PhoenixButtonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    PhoenixIndicatorsModule,
    ChatDrawerNewComponent,
  ],
  exports: [
    MessagesPageComponent
  ],
  providers: [
    MessagesFacade,
    MessagesService
  ]
})
export class MessagesModule { }
