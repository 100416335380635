<div class="container-fluid w-100 px-0 mb-3 mb-md-0">
  <ng-container *ngIf="displaySupplierOutageBanner$ | async as outageBanner">
    <kehe-phoenix-global-alert *ngIf="!outageAlertClosed" [showClose]="true"
    [type]="'warning'" (closeEvent)="handleCloseOutageAlert()">
      <div class="global-alert-container" content>
        <ng-container *ngIf="isSmallOrBelow$ | async; else aboveSmallAlert">
          <strong>
            <span class="fas fa-warning"></span>
            {{outageBanner?.message}}
          </strong>
        </ng-container>
        <ng-template #aboveSmallAlert>
          <em class="fas fa-warning"></em>
          <h4 class="mb-0">
            {{outageBanner?.message}}
          </h4>
        </ng-template>
      </div>
    </kehe-phoenix-global-alert>
  </ng-container>

    <kehe-phoenix-global-alert *ngIf="showGlobalAlert && showCountdownView$ | async as view" (closeEvent)="handleClose()">
      <div class="global-alert-demo-container" content>
        <div class="title" [class.h3-font-size]="isLargeOrBelow">
          <em class="fas fa-calendar-alt"></em>
          {{view.name}} active from {{view.formattedDate}}
        </div>
        <kehe-phoenix-countdown [date]="view.targetDate"></kehe-phoenix-countdown>
      </div>
    </kehe-phoenix-global-alert>
    <header>
        <kehe-phoenix-top-bar
            [appTitle]="topbarTitle"
            appIcon="fas fa-dolly"
            appIconColor="#B3CE33"
            [userName]="(userFirstName$ | async)"
            [showSearch]="false"
            [bgColor]="topbarColor"
            [notifications]="userNotifications$ | async"
            [notificationCount]="userNotificationCount$ | async"
            [notificationShowGoToButton]="notificationShowGoToButton"
            (notificationIconClick)="onNotificationIconClick($event)"
            (notificationRedirect)="onNotificationRedirect($event)"
            (clearNotifications)="onClearNotifications($event)"
            (clearAllNotifications)="onClearAllNotifications()"
            [profileOptions]="profileOptions"
            [helpShow]="true"
            (helpClick)="handleHelpClick($event)"
            (userNameOptionClick)="userSectionClick($event)"
            [messagingShow]="showMessagingComponent"
            [messagingSource]="messagingSource"
            [chatRooms]="headerChats"
            (selectChatRoom)="handleSelectChatRoom($event)"
            [messagesCount]="unreadCount"
            (newMessageButtonClick)="handleNewMessageButtonClick($event)"
            [messagesFiltered]="searchControl.value"
        >
          <div message-search class="m-3">
            <kehe-phoenix-search
              name="msgTopbarFilter"
              placeholder="Search for a supplier or retailer"
              [formControl]="searchControl"
            ></kehe-phoenix-search>
          </div>
        </kehe-phoenix-top-bar>
        <app-cs-navigation *ngIf="userActions"></app-cs-navigation>
        <app-sub-navigation [show]="userEmail"></app-sub-navigation>
    </header>
</div>

<div class="container pt-2 pb-5 main-container" #mainContainer>
    <router-outlet></router-outlet>
</div>

<app-messages-page
  *ngIf="showMessagingComponent"
  [userFullName]="userFullName$ | async"
  [userEmail]="userEmail"
  [suppliers]="showSuppliers$ | async"
  [source]="'Supplier'"
>
</app-messages-page>
<div class="container-fluid w-100 px-0">
  <footer>
    <kehe-phoenix-feedback-headless></kehe-phoenix-feedback-headless>
    <app-video-player-modal></app-video-player-modal>
    <kehe-phoenix-footer [showFeedback]="true" (feedbackClicked)="handleFeedbackClick()" appName="CS" privacyDocsUrl="https://kehe-connect.s3.amazonaws.com/prod/public_common/managed-content/static_pages/"></kehe-phoenix-footer>
  </footer>
</div>

<app-user-agreement-modal *ngIf="isUserAgreementEnabled$ | async"></app-user-agreement-modal>

<app-clear-notifications-modal [showModal]="isClearNotificationsOpen$ | async" [notificationsCount]="userNotificationCount$ | async"></app-clear-notifications-modal>

@if (isChatBotAvailable$ | async) {
  <app-chat-bot></app-chat-bot>
}