import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SplitFactory } from '@splitsoftware/splitio';
import { ISDK, TreatmentsWithConfig } from '@splitsoftware/splitio/types/splitio';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { FeatureFlagKeys } from './index';
import * as FeatureFlagActions from './feature-flag.actions';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagServiceNew implements OnDestroy {
  private factory: ISDK;
  private splitClient: SplitIO.IClient;

  constructor(private store: Store) { }

  initializeSplitSdk(email: string): void {
    if (environment && environment.splitIoKey) {
      this.factory = SplitFactory({
        core: {
          authorizationKey: environment.splitIoKey,
          key: email.toLowerCase(),
        }
      });

      this.splitClient = this.factory.client();
      this.splitClient.once(this.splitClient.Event.SDK_READY, () => {
        this.store.dispatch(FeatureFlagActions.splitSdkReady());
        this.splitClient.on(this.splitClient.Event.SDK_UPDATE, () => {
          this.store.dispatch(FeatureFlagActions.splitSdkUpdateReceived());
        });
      });
    } else {
      console.warn('No Split.IO key specified -- using default values');
    }
  }

  getFeatureFlags(userAttributes: any): Observable<TreatmentsWithConfig> {
    return of(this.splitClient.getTreatmentsWithConfig(FeatureFlagKeys, userAttributes));
  }

  ngOnDestroy(): void {
    if(this.splitClient) { this.splitClient.destroy() } ;
    this.splitClient = null;
  }
}
