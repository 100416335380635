import { Injectable } from "@angular/core";
import { AbstractApiStore } from "@kehe/phoenix-component-store-utils";
import { EMPTY, Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { tapResponse } from '@ngrx/operators';
import { ApiFailureText } from "@app/shared/enums/alert-text.enum";
import { FsmaComplianceService } from "./fsma-compliance-banner.service";

@Injectable()
export class FsmaComplianceApiStore extends AbstractApiStore<
  number | undefined
> {
  constructor(private _service: FsmaComplianceService) {
    super();
  }

  getFsmaNonCompliantProductsCount$ = this.effect((_: Observable<void>) =>
    _.pipe(
        tap(() => {
          this.setData(undefined);
          this.startLoading();
        }),
        switchMap(() => {
          return this._service
            .getFsmaNonCompliantProductsCount()
            .pipe(
              tapResponse(
                (response) => {
                  this.setData(response);
                },
                () => {
                  this.setError(ApiFailureText.Generic);
                },
              ),
            );
        }),
        catchError(() => {
          this.setError(ApiFailureText.Generic);
          return EMPTY;
        }),
      )
  );
}
