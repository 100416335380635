<kehe-phoenix-modal
  name="help-modal"
  modalTitle="Help"
  [show]="show"
  (modalEvent)="handleKeheModalEvents($event)"
  [size]="videos?.length === 1 ? 'md' : 'lg'"
>
  <div body class="modal-body">
    <ng-container *ngIf="videos?.length > 0">
      <h4 *ngIf="videos.length > 1">Training Videos</h4>
      <div
        class="d-flex flex-row flex-wrap"
        [ngClass]="(videos.length > 1) ? 'justify-content-between' : ''"
      >
        <div *ngFor="let video of videos" class="video-container">
          <app-vimeo-video-player
            [videoId]="video.vimeoId"
            [width]="videos.length > 1 ? 356 : 450"
          ></app-vimeo-video-player>
        </div>
      </div>
    </ng-container>
    <div *ngIf="faqs?.length > 0" class="faq-section">
      <h4>FAQ's</h4>
      <app-faqs
        [faqOverride]="faqs"
        [showTitle]="false"
      ></app-faqs>
    </div>
  </div>
  <div footer class="d-flex justify-content-between align-items-center footer">
    <div *ngIf="showReportIssue">
      <a [href]="reportAnIssueLink" target="_blank">Report an Issue</a>
    </div>
    <div [ngClass]="showReportIssue ? 'footer-actions' : 'footer-actions-width'">
      <kehe-phoenix-button
        class="help-center-button"
        name="help-center-button"
        classAttr="btn-secondary btn-medium"
        (click)="onHelpCenterClick()"
      >
        {{ helpCenterButtonText }}
      </kehe-phoenix-button>
      <kehe-phoenix-button
        name="submitSubscription"
        classAttr="btn-primary btn-medium"
        (click)="modalClose.emit()"
        class="ms-2"
      >
        Close
      </kehe-phoenix-button>
    </div>
  </div>
</kehe-phoenix-modal>
