import { createActionGroup, emptyProps } from "@ngrx/store";

// Renaming of this action group would break the code, this needs to be the same as the action group in
// projects\custom-promotions-lib\src\lib\shared\store\custom-promotions.actions.ts within the custom promotions lib
export const CustomPromotionsActions = createActionGroup({
  source: 'Custom Promotions',
  events: {
    'Trigger Manage Progress': emptyProps(),
  },
});
