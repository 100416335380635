<div
  *ngIf="viewModel$ | async as vm"
  class="chat-drawer d-flex flex-column ms-3"
>
  <div
    id="new-drawer-header"
    class="d-flex flex-row mx-3 justify-content-between align-items-center header"
  >
    <div class="d-flex flex-row align-items-center">
      <em class="fas icon fa-shopping-basket" aria-hidden="true"></em>
      <div class="d-flex flex-column m-0 ms-2">
        <h4 class="mb-0">New Message</h4>
      </div>
    </div>
    <em class="far fa-times" title="Close" (click)="closeChatRoom($event)"></em>
  </div>
  <div class="panel-divider"></div>
  <div id="new-drawer-body" class="body mx-3" #formRight>
    <div class="title mt-2">From:</div>
    <kendo-combobox
      [data]="vm.suppliers"
      textField="name"
      valueField="esn"
      [valuePrimitive]="false"
      placeholder="Select Supplier"
      [kendoDropDownFilter]="supplierFilterSettings"
      (valueChange)="handleSupplierSelected($event)"
    >
      <ng-template kendoComboBoxItemTemplate let-supplier>
        <div
          class="kendo-dropdown-list-item-template"
          [ngStyle]="{
            'max-width.px': formRight.offsetWidth - 40,
            overflow: 'hidden',
            'text-overflow': 'ellipsis'
          }"
        >
          <div class="item-header">{{ supplier?.name }}</div>
          <div
            [ngStyle]="{
              'max-width.px': formRight.offsetWidth - 40,
              overflow: 'hidden',
              'text-overflow': 'ellipsis'
            }"
            title="{{ supplier?.esn }}"
          >
            ESN {{ supplier?.esn }}
          </div>
        </div>
      </ng-template>
    </kendo-combobox>

    <app-show-customer-combobox
      label="To:"
      placeholder="Select Customer"
      (customerSelected)="handleCustomerSelected($event)"
    ></app-show-customer-combobox>

    <div class="d-flex justify-content-end mt-3">
      <kehe-phoenix-button
        classAttr="btn-primary btn-medium"
        [disabled]="!vm.canCreateMessage"
        (click)="createMessage()"
        >Create Message</kehe-phoenix-button
      >
    </div>
  </div>
</div>
