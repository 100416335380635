import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ChatBotAuthorizeRequest,
  ChatBotAuthorizeResponse,
  ChatBotSendMessageRequest,
  ChatBotSendMessageResponse,
  ChatBotSendFeedbackRequest
} from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  private url = `${environment.bimlApi}/supplier-chatbot-api`;

  constructor(private httpClient: HttpClient) {}

  authorize(
    request: ChatBotAuthorizeRequest
  ): Observable<ChatBotAuthorizeResponse> {
    return this.httpClient.put<ChatBotAuthorizeResponse>(
      `${this.url}/authorize`,
      request
    );
  }

  sendMessage(
    request: ChatBotSendMessageRequest
  ): Observable<ChatBotSendMessageResponse> {
    return this.httpClient.post<ChatBotSendMessageResponse>(
      `${this.url}/chat`,
      request
    );
  }

  sendFeedback(request: ChatBotSendFeedbackRequest) {
    return this.httpClient.post(`${this.url}/feedback`, request);
  }
}
