import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DestroyableDirective } from '@app/abstract/destroyable';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DayOfWeek, dayOfWeekAbbreviationMap } from '../../models/enum/day-of-week';
import { ManageRecipientsForm } from '../../models/manage-recipients-form';
import {
  loadAvailableRecipientEmails,
  loadAvailableRecipientEmailsForBulk,
  manageRecipientsFormValueChange,
  manageRecipientsModalCancelClicked,
  manageRecipientsModalCloseClicked,
  manageRecipientsUpdateClick,
  manageRecipientsUpdateForBulkClick
} from '../../store/data-exchange.actions';
import {
  selectAvailbleRecipientEmailListLoading,
  selectAvailbleRecipientEmails,
  selectEsnSubscriptionsToPerformAnActionOnCount,
  selectManageRecipientsBulkMode,
  selectManageRecipientsFormUpdateInProgress,
  selectManageRecipientsFormValue,
  selectManageRecipientsUpdateErrorMessage,
} from '../../store/data-exchange.selectors';
import { KeheModalEventsTypes } from '@kehe/phoenix-modal';
import { PermissionsService } from '@app/services/permissions.service';
import { ResourceActions } from '@app/constants/resource-actions';

@Component({
  selector: 'app-manage-recipients-modal',
  templateUrl: './manage-recipients-modal.component.html',
  styleUrls: ['./manage-recipients-modal.component.scss']
})
export class ManageRecipientsModalComponent extends DestroyableDirective implements OnInit {

  isBulkMode = false;
  esnCount: number;
  warningMessage: string;
  isEmailRecipientsListLoading$: Observable<boolean>;
  emailRecipients$: Observable<string[]>;
  thirdPartyUsers: boolean = false;

  dayOptions: { id: DayOfWeek, text: string }[] = [
    DayOfWeek.All,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday
  ].map(day => ({
    id: day,
    text: dayOfWeekAbbreviationMap.get(day),
  }));

  form = new UntypedFormGroup({
    emails: new UntypedFormControl([], [
      Validators.required,
      this.maximumNumberOfEmailsValidator(),
    ]),
    day: new UntypedFormControl(DayOfWeek.All)
  });

  updateInProgress: boolean;
  errorMessage: string;

  constructor(private _store: Store,
    private _permSvc: PermissionsService) {
    super();
  }

  ngOnInit(): void {
    this._store.pipe(
      select(selectManageRecipientsBulkMode),
      withLatestFrom(this._store.select(selectEsnSubscriptionsToPerformAnActionOnCount)),
      take(1),
    ).subscribe(([bulkMode, count]) => {
      this.isBulkMode = bulkMode;
      this.warningMessage = `${count > 1 ? `You are managing subscriptions for ${count} suppliers. ` : ''}
        Updating recipients and report delivery day will override all selected suppliers.`;
      if (bulkMode) {
        this.esnCount = count;
        this._store.dispatch(loadAvailableRecipientEmailsForBulk());
      } else {
        this._store.dispatch(loadAvailableRecipientEmails());
      }
    });

    if (this._permSvc.userHasAction(ResourceActions.ThirdPartyOfRecord)) {
      this.thirdPartyUsers = true;
    } else {
      this.dayOptions = this.dayOptions.filter(day => day.id !== DayOfWeek.All)
    }

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectManageRecipientsFormValue)
    ).subscribe(formValue => this.setInitialFormValues(this.form, formValue));

    this.isEmailRecipientsListLoading$ = this._store.select(selectAvailbleRecipientEmailListLoading);
    this.emailRecipients$ = this._store.select(selectAvailbleRecipientEmails);

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(formValue => this._store.dispatch(manageRecipientsFormValueChange({formValue})));

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectManageRecipientsFormUpdateInProgress)
    ).subscribe(value => this.updateInProgress = value);

    this._store.pipe(
      takeUntil(this.destroy$),
      select(selectManageRecipientsUpdateErrorMessage),
    ).subscribe(error => this.errorMessage = error);

  }

  setInitialFormValues(form: UntypedFormGroup, formValue: ManageRecipientsForm): void {
    if (formValue === null) { return; }
    if (formValue.emails) { form.get('emails').setValue(formValue.emails.sort(), { emitEvent: false }); }
    if (formValue.day) { form.get('day').setValue(formValue.day, { emitEvent: false }); }
    if(this.thirdPartyUsers) { form.get('day').setValue("", { emitEvent: false });}
  }

  handleModalEvent(event): void {
    switch (event.type) {
      case KeheModalEventsTypes.Close:
        this._store.dispatch(manageRecipientsModalCloseClicked());
        break;
    }
  }

  onCancelClick(): void {
    this._store.dispatch(manageRecipientsModalCancelClicked());
  }

  onUpdateClick(isBulkMode: boolean): void {
    if (isBulkMode) {
      this._store.dispatch(manageRecipientsUpdateForBulkClick());
    } else {
      this._store.dispatch(manageRecipientsUpdateClick());
    }
  }

  maximumNumberOfEmailsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !control.value) { return null; }
      if (control.value.length > 10) {
        return { tooManyEmails: true };
      }
      return null;
    };
  }

}
