import { Action, createReducer, on } from '@ngrx/store';
import { OnboardingTabStatus } from '../models/onboarding-tab-status';
import * as SupplierOnboardingActions from './supplier-onboarding.actions';
import { Signer } from '../../supplier-detail/models/signer';
import { User } from '../../supplier-detail/models/user';
import { UserDetails } from '../../supplier-detail/models/user-details';
import { ITag } from '@kehe/phoenix-indicators';
import { startCase } from 'lodash';

export const supplierOnboardingStateKey = 'supplierOnboarding';

export class SupplierOnboardingState {
  isLoading: boolean;
  showValidateModal: boolean;
  isValidating: boolean;
  validationErrorMessage: string;
  onboardingTabStatuses: OnboardingTabStatus[];
  tabStatuses: OnboardingTabStatus[];
  docusignSigner: Signer;
  isSendingDocusign: boolean;
  sendDocusignError: string;
  revisionStatus: OnboardingTabStatus;
  showAddUserModal: boolean;
  showDeleteUserModal: boolean;
  addUserInProgress: boolean;
  updateUserInProgress: boolean;
  deleteUserInProgress: boolean;
  addUserError: string;
  deleteUserError: string;
  users: User[];
  stagedUser?: User;
  loadingUsers: boolean;
}

export const initializeState = (): SupplierOnboardingState => {
  return {
    isLoading: false,
    showValidateModal: false,
    isValidating: false,
    onboardingTabStatuses: [],
    tabStatuses: [],
    validationErrorMessage: null,
    docusignSigner: null,
    isSendingDocusign: false,
    sendDocusignError: null,
    revisionStatus: null,
    showAddUserModal: false,
    showDeleteUserModal: false,
    addUserInProgress: false,
    updateUserInProgress: false,
    deleteUserInProgress: false,
    deleteUserError: null,
    addUserError: null,
    users: [],
    loadingUsers: false,
  };
};

export const initialState = initializeState();

const rootReducer = createReducer(
  initialState,
  on(SupplierOnboardingActions.loadOnboardingTabV2StatusSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    onboardingTabStatuses: action.tabStatuses,
  })),
  on(SupplierOnboardingActions.loadOnboardingSupplierStatusSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    tabStatuses: action.tabStatuses,
  })),
  on(SupplierOnboardingActions.showValidateModal, (state: SupplierOnboardingState) => ({
    ...state,
    showValidateModal: true,
    validationErrorMessage: ''
  })),
  on(SupplierOnboardingActions.hideValidateModal, (state: SupplierOnboardingState) => ({
    ...state,
    showValidateModal: false
  })),
  on(SupplierOnboardingActions.validateTab, (state: SupplierOnboardingState) => ({
    ...state,
    isValidating: true,
    validationErrorMessage: ''
  })),
  on(SupplierOnboardingActions.validateTabSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    isValidating: false,
    showValidateModal: false,
    tabStatuses: updateTabStatus(state.tabStatuses, action.tabStatus),
    validationErrorMessage: '',
  })),
  on(SupplierOnboardingActions.validateTabFailure, (state: SupplierOnboardingState, action) => ({
    ...state,
    isValidating: false,
    showValidateModal: true,
    validationErrorMessage: 'Something went wrong. Please try again.'
  })),
  // Revision
  on(SupplierOnboardingActions.showRevisionNotes, (state: SupplierOnboardingState, action) => ({
    ...state,
    revisionStatus: action.onboardingStatus,
  })),
  on(SupplierOnboardingActions.closeRevisionNotes, (state: SupplierOnboardingState, action) => ({
    ...state,
    revisionStatus: initialState.revisionStatus,
  })),
  // Revision

  // Docusign
  on(SupplierOnboardingActions.confirmDocusignSigner, (state: SupplierOnboardingState, action) => ({
    ...state,
    docusignSigner: action.signer,
    sendDocusignError: null,
  })),
  on(SupplierOnboardingActions.cancelDocusignSigner, (state: SupplierOnboardingState, action) => ({
    ...state,
    docusignSigner: null,
    sendDocusignError: null,
  })),
  on(SupplierOnboardingActions.sendDocusignEmail, (state: SupplierOnboardingState, action) => ({
    ...state,
    isSendingDocusign: true,
    sendDocusignError: null,
  })),
  on(SupplierOnboardingActions.sendDocusignEmailSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    isSendingDocusign: false,
    docusignSigner: null,
    sendDocusignError: null,
  })),
  on(SupplierOnboardingActions.sendDocusignEmailError, (state: SupplierOnboardingState, action) => ({
    ...state,
    isSendingDocusign: false,
    sendDocusignError: 'Something went wrong. Please try again.',
  })),
  // Docusign

  // User
  on(SupplierOnboardingActions.showAddUserModal, (state: SupplierOnboardingState, action) => ({
    ...state,
    showAddUserModal: true,
    addUserError: null,
  })),
  on(SupplierOnboardingActions.closeAddUserModal, (state: SupplierOnboardingState, action) => ({
    ...state,
    showAddUserModal: false,
    addUserError: null,
  })),
  on(SupplierOnboardingActions.showDeleteUserModal, (state, { user }) => ({
    ...state,
    showDeleteUserModal: true,
    stagedUser: user,
  })),
  on(SupplierOnboardingActions.closeDeleteUserModal, (state) => ({
    ...state,
    showDeleteUserModal: false,
    deleteUserError: null,
    stagedUser: null,
  })),
  on(SupplierOnboardingActions.addUser, (state: SupplierOnboardingState, action) => ({
    ...state,
    addUserInProgress: true,
    addUserError: null,
  })),
  on(SupplierOnboardingActions.updateUser, (state: SupplierOnboardingState, action) => ({
    ...state,
    updateUserInProgress: true,
    updateUserError: null,
    users: updateUserState(state.users, action.user.email, true)
  })),
  on(SupplierOnboardingActions.addUserSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    addUserInProgress: false,
    addUserError: null,
    showAddUserModal: false,
    users: [
      ...state.users,
      action.user
    ]
  })),
  on(SupplierOnboardingActions.updateUserSuccess, (state, action) => ({
    ...state,
    updateUserInProgress: false,
    updateUserError: null,
    users: state.users.map((u) => {
      if (u.email === action.user.email) {
        return { ...u, ...action.user, isSyncing: undefined };
      }
      return u;
    })
  })),
  on(SupplierOnboardingActions.addUserError, (state: SupplierOnboardingState, action) => ({
    ...state,
    addUserInProgress: false,
    addUserError: action.errorMessage ?? 'Something went wrong. Please try again later.',
  })),
  on(SupplierOnboardingActions.updateUserError, (state, action) => ({
    ...state,
    updateUserInProgress: false,
    updateUserError: 'Something went wrong. Please try again.',
    users: state.users.map((u) => {
      delete (u as any).isSyncing;
      return u;
    }),
  })),
  on(SupplierOnboardingActions.deleteUser, (state) => ({
    ...state,
    deleteUserInProgress: true,
    deleteUserError: null,
  })),
  on(SupplierOnboardingActions.deleteUserSuccess, (state, { user }) => ({
    ...state,
    deleteUserInProgress: false,
    stagedUser: null,
    showDeleteUserModal: false,
    users: [...removeUser(state.users, user.email)],
  })),
  on(SupplierOnboardingActions.deleteUserError, (state, { error }) => ({
    ...state,
    deleteUserInProgress: false,
    deleteUserError: error
  })),
  on(SupplierOnboardingActions.loadUserStatusSuccess, (state, action) => ({
    ...state,
    users: updateUserStatus(state.users, action.users),
    loadingUsers: false,
  })),
  on(SupplierOnboardingActions.loadUserStatusError, (state, action) => ({
    ...state,
    loadingUsers: false,
  })),
  on(SupplierOnboardingActions.getUsers, (state: SupplierOnboardingState, action) => ({
    ...state,
    loadingUsers: true,
  })),
  
  on(SupplierOnboardingActions.getUsersSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    users: action.users,
  })),
  on(SupplierOnboardingActions.getUsersError, (state: SupplierOnboardingState, action) => ({
    ...state,
    users: [],
  })),
  
  on(SupplierOnboardingActions.loadUsers, (state: SupplierOnboardingState, action) => ({
    ...state,
    esn: action.esn,
    loadingUsers: true,
})),
on(SupplierOnboardingActions.loadUsersSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    users: action.users,
})),
on(SupplierOnboardingActions.loadUsersError, (state: SupplierOnboardingState, action) => ({
    ...state,
    users: [],
    loadingUsers: false,
})),
  // User
  // Resend Invitation
  on(SupplierOnboardingActions.resendInvitation, (state: SupplierOnboardingState, action) => ({
    ...state,
    users: updateUserSync(state.users, action.user, true)
  })),
  on(SupplierOnboardingActions.resendInvitationSuccess, (state: SupplierOnboardingState, action) => ({
    ...state,
    users: updateUserSync(state.users, action.user, false)
  })),
  on(SupplierOnboardingActions.resendInvitationError, (state: SupplierOnboardingState, action) => ({
    ...state,
    users: updateUserSync(state.users, action.user, false)
  })),
  // Resend Invitation
);

export function reducer(state: SupplierOnboardingState | undefined, action: Action) {
  return rootReducer(state, action);
}

function updateTabStatus(statuses: OnboardingTabStatus[], newStatus: OnboardingTabStatus): OnboardingTabStatus[] {
  const existingStatus = statuses.find(status => status.tab === newStatus.tab);
  const index = statuses.indexOf(existingStatus);

  if (index >= 0) {
    statuses[index] = newStatus;
  }

  return statuses;
}

function updateUserState(users: (User & { isSyncing?: boolean })[], email: string, isSyncing: boolean) {
  return users.map((u) => {
    if (u.email === email) {
      u.isSyncing = isSyncing;
    }
    return u;
  });
}

function updateUserSync(users: User[], user: User, isSyncing: boolean) {
  return users.map(item => item.email === user.email ? { ...item, isSyncing: isSyncing } : item);
}

function updateUserStatus(users: User[], userDetails: UserDetails[]) {
  var statusMap = userDetails.reduce(function(map, obj) {
      map[obj.email] = obj.status;
      return map;
  }, {});
  users.forEach(item => {
    const status = statusMap[item.email];
    item.status = status;
    item.statusTag = prepareStatusTag(status);
  });
  return users;
}

function prepareStatusTag(status: string): ITag {
  if (status) {
      switch (status) {
          case 'active': {
              return {
                  bgColor: '#D5E48F',
                  borderColor: '#D5E48F',
                  text: 'Active',
              };
          }
          case 'pending': {
              return {
                  bgColor: '#FAD981',
                  borderColor: '#FAD981',
                  text: 'Pending',
              };
          }
          default: {
              return {
                  text: startCase(status.toLowerCase()),
              };
          }
      }
  }
  return null;
}

function removeUser(userList: User[], email: string) {
  userList.splice(userList.findIndex((u) => u.email === email), 1);

  return userList;
}
