import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { DestroyableDirective } from '../../../../abstract/destroyable';
import { SupplierListItem } from '../../models/supplier-list-item';
import {
  loadSupplierList,
  supplierListPageChange,
  openSupplierFilter,
  supplierListSearchChange,
  supplierListSortChange,
  supplierListFilterChipClicked,
  SupplierListFilterChipsClearAll,
  hideAlertMessage,
} from '../../store/supplier-list.actions';

import {
  getSupplierList,
  getSupplierListCount,
  getSupplierListLoading,
  getSupplierListPageCount,
  getSupplierListCurrentPage,
  getSupplierListSearch,
  getSupplierListFilterChips,
  getSupplierListSort,
  getSupplierListSkip,
  hasPoToReview,
  alertMessage,
} from '../../store/supplier-list.selectors';

import { SupplierListSort } from '../../enum/supplier-list-sort';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { Constants } from '../../../../constants/constants';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import * as SupplierListPageActions from './supplier-page.actions';
import { AlertMessage } from '@app/models/alert-message';

@Component({
  selector: 'app-supplier-page',
  templateUrl: './supplier-page.component.html',
  styleUrls: []
})
export class SupplierPageComponent extends DestroyableDirective  implements OnInit {
  constructor(
    private _store: Store,
    private _router: Router,
  ) {
    super();
  }

  suppliers$: Observable<Array<SupplierListItem>>;
  hasPoToReview$: Observable<boolean>;
  alertMessage$: Observable<AlertMessage>;
  suppliersLoading: boolean;
  suppliersCount: number;
  filterChips: any;
  isProd = true;

  search = new UntypedFormControl('');
  showSidePanel$: Observable<boolean>;
  sort$: Observable<any>;
  isManageDraftSupplierPage: boolean;

  // kendo sorting
  currentSort: SortDescriptor[] = [{ field: 'esn', dir: 'asc' }];

  // kendo paging
  pageSize = 25;
  pageIndex = 0;
  skip = 0;

  ngOnInit() {
    this.isManageDraftSupplierPage = this._router.url.startsWith(Constants.ManageDraftSupplierPath);

    if (environment.name !== '') {
      this.isProd = false;
    }

    this._store.dispatch(SupplierListPageActions.clearKendoBreadCrumbs());
    this._store.dispatch(loadSupplierList());
    this.sort$ = this._store.pipe(select(getSupplierListSort));
    this.hasPoToReview$ = this._store.select(hasPoToReview);
    this.alertMessage$ = this._store.select(alertMessage);

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSupplierListLoading)
    ).subscribe(value => this.suppliersLoading = value);

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSupplierListCount))
      .subscribe((search) => {
        this.suppliersCount = search;
    });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSupplierListPageCount)
    ).subscribe(value => this.pageSize = value);

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSupplierListCurrentPage)
    ).subscribe(value => this.pageIndex = value);

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSupplierListSkip)
    ).subscribe(result => this.skip = result);

    this.suppliers$ = this._store.select(getSupplierList);

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSupplierListSearch))
      .subscribe(value => {
        this.search.setValue(value);
    });

    this.search.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this._store.dispatch(supplierListSearchChange({ search: value }));
      });

    this._store.pipe(
      takeUntil(this.destroy$),
      select(getSupplierListFilterChips))
      .subscribe((filterChips) => {
        this.filterChips = filterChips;
    });
  }

  pageChange(event: PageChangeEvent): void {
    this._store.dispatch(
      supplierListPageChange({
        pageCount: event.take,
        pageIndex: event.skip / event.take,
        skip: event.skip
      })
    );
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  sortGrid(e) {
    this.currentSort = e;
    this._store.dispatch(supplierListSortChange({
      orderDirection: e[0].dir === 'asc' ? 0 : 1,
      sortName: SupplierListSort[e[0].field as keyof typeof SupplierListSort]
    }));
  }

  public openFilterPanel() {
    this._store.dispatch(openSupplierFilter());
  }

  filterChipClicked(chip) {
    this._store.dispatch(supplierListFilterChipClicked({ key: chip.extraPayload.key }));
  }

  filterChipClearAllClicked() {
    this._store.dispatch(SupplierListFilterChipsClearAll());
  }

  getSupplierLink(currentLink: string) {
    if (this.isManageDraftSupplierPage) {
      return currentLink;
    }
    return currentLink.replace('/general','/purchasing-options');
  }

  public hideAlertMessage (): void {
    this._store.dispatch(hideAlertMessage());
  }
}
