import { InviteSupplierType } from '../models/invite-supplier-type';
import { SupplierDetail } from '../models/supplier-detail';
import { SupplierStatus } from '../models/supplier-status';

export function isDraft(supplier: SupplierDetail): boolean {
  return supplier?.status === SupplierStatus.draft;
}

export function isInvitedSupplier(supplier: SupplierDetail): boolean {
  return supplier?.inviteReasonType !== null && supplier?.inviteReasonType !== undefined;
}

export function isInvitedDraftSupplier(supplier: SupplierDetail): boolean {
  return isDraft(supplier) && isInvitedSupplier(supplier);
}

export function isSupplierInReview(supplier: SupplierDetail): boolean {
  return isDraft(supplier) &&
    supplier.invitedSupplierSubmittedForReviewDate !== null &&
    supplier.invitedSupplierSubmittedForReviewDate !== undefined;
}

export function getSupplierCategoryManagerFullName(supplier: SupplierDetail): string {
  if (supplier.categoryManagerCode) {
    if (supplier.categoryManagerFirstName && supplier.categoryManagerLastName) {
      return `${supplier.categoryManagerFirstName} ${supplier.categoryManagerLastName}`;
    }
    if (supplier.categoryManagerFirstName) {
      return supplier.categoryManagerFirstName;
    }
    if (supplier.categoryManagerLastName) {
      return supplier.categoryManagerLastName;
    }
  }
  return null;
}

export function getSupplierDevelopmentManagerFullName(supplier: SupplierDetail): string {
  if (supplier.supplierDevelopmentManagerCode) {
    if (supplier.supplierDevelopmentManagerFirstName && supplier.supplierDevelopmentManagerLastName) {
      return `${supplier.supplierDevelopmentManagerFirstName} ${supplier.supplierDevelopmentManagerLastName}`;
    }
    if (supplier.supplierDevelopmentManagerFirstName) {
      return supplier.supplierDevelopmentManagerFirstName;
    }
    if (supplier.supplierDevelopmentManagerLastName) {
      return supplier.supplierDevelopmentManagerLastName;
    }
  }
  return null;
}

export function convertToSupplierDetails(response) {
  if (response.categoryManager) {
    response.categoryManagerCode = response.categoryManager.code;
    response.categoryManagerFirstName = response.categoryManager.firstName;
    response.categoryManagerLastName = response.categoryManager.lastName;
  }
  if (response.invitation) {
    if (response.invitation.categoryManagerContact) {
      response.categoryManagerEmail = response.invitation.categoryManagerContact.email;
    }
    response.inviteReasonType = response.invitation.reason;
    response.inviteSupplierType = response.invitation.policyProcedureType;

    response.preferredCustomerId = response.invitation.preferredCustomerId;
    response.preferredCustomerName = response.invitation.preferredCustomerName;
    response.preferredCustomerComments = response.invitation.comments;

    if (response.invitation.requester) {
      response.requesterType = response.invitation.requester.type;
      response.retailerMasterAccountNumber = response.invitation.requester.retailerMasterAccountNumber;

      response.preferredCustomerId = response.invitation.requester.preferredCustomerId;
      response.preferredCustomerName = response.invitation.requester.preferredCustomerName;
      response.preferredCustomerComments = response.invitation.requester.comments;
    }
  }
  if (response.supplierDevelopmentManager) {
    response.supplierDevelopmentManagerCode = response.supplierDevelopmentManager.code;
    response.supplierDevelopmentManagerFirstName = response.supplierDevelopmentManager.firstName;
    response.supplierDevelopmentManagerLastName = response.supplierDevelopmentManager.lastName;
  }
  return response;
}
export function shouldShowAAPFieldForGeneralTab(supplier: SupplierDetail, isGeneralTabNotSubmitted: boolean, ffAAPTerm: boolean): boolean {
  // Show if general tab is not submitted. Any other tab status then, show if value already exist
  return ffAAPTerm &&
    isDraft(supplier) &&
    supplier.supplierType === InviteSupplierType.Standard &&
    (isGeneralTabNotSubmitted || (supplier.administrativeAllowanceProgramRequested != null && true));
}
