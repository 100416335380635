export enum OrdersListShowActions {
  PageLoad = '[Orders List Show] Page Load',
  LoadOrders = '[Orders List Show] Load Orders',
  ToggleOrderSelect = '[Orders List Show] Toggle Order Select',
  ToggleOrderSelectAll = '[Orders List Show] Toggle Order Select All',
  OrdersListPageChange = '[Orders List Show] Orders List Page Change',
  OrdersListStatusChange = '[Orders List Show] Orders List Status Change',
  OrdersListShowIdChange = '[Orders List Show] Orders List Show Id Change',

  LoadOrdersListShowData = '[Orders List Show] Load Orders List Show Data',

  ChangeSelectedTab = '[Orders List Show] Change Selected Tab',
  HandleQueryParams = '[Orders List Show] Handle Query Params',
  SetBanner = '[Orders List Show] Set Banner',
  CloseBanner = '[Orders List Show] Close Banner',

  OrdersListDeleteOrders = '[Orders List Show] Orders List Delete Order',
  OrdersListDeleteOrdersSuccess = '[Orders List Show] Orders List Delete Order Success',
  OrdersListDeleteOrdersFailure = '[Orders List Show] Orders List Delete Order Failure',
  CloseDeleteOrdersModal = '[Orders List Show] Close Delete Orders Modal',
  OpenDeleteOrdersModal = '[Orders List Show] Open Delete Orders Modal',
  CloseDeleteErrorAlert = '[Orders List Show] Close Bulk Delete Orders Error Banner Alert',

  ChangeSearchText = '[Orders List Show] Change Search Text',
  ResetAllFilters = '[Orders List Show] Reset All Filters',
  ChangeSorting = '[Orders List Show] Change Sorting',

  OpenHelpModal = '[Orders List Show] Open Help Modal',

  // Upload Order Modal
  OpenUploadOrderModal = '[Orders List Show] Open Upload Order Modal',

  // ECM
  SyncOrdersListShow = '[Orders List Show] ECM Sync Orders List',
  SetOrdersListOutOfSync = '[Orders List Show] ECM Set Orders List Out Of Sync',

  SyncOrdersListShowError = '[Orders List Show] Sync Orders List Show Error',
  SyncOrdersListShowSuccess = '[Orders List Show] Sync Orders List Show Success',

     // get Show Reports
  getShowsHistory =  '[Orders List Show] get Shows History',
  getShowsHistorySuccess =  '[Orders List Show] get Shows History Success',
  getShowsHistoryError =  '[Orders List Show] get Shows History Failure',

  // Download Orders Report
  getShowReports= '[Orders List Show] Load Show History',
  DownloadShowReport = '[Orders List Show] Download Show Report',
  DownloadShowReportSuccess = '[Orders List Show] Download Show Report Success',
  DownloadShowReportError = '[Orders List Show] Download Show Report Error',

  OpenCreateOrderModal = '[Orders List Show] Open Create Order Modal',
  CloseCreateOrderModal = '[Orders List Show] Close Create Order Modal',
  SubmitCreateOrderModal = '[Orders List Show] Submit Create Order Modal',
  SubmitCreateOrderModalSuccess = '[Orders List Show] Submit Create Order Modal Success',
  SubmitCreateOrderModalError = '[Orders List Show] Submit Create Order Modal Error',
  SetShowDownloadReportModal = '[Orders] Set Show Download Report Modal',
  DownloadShowReportHistory = '[Orders List Show] Download Show Report History',
  DownloadShowReportHistorySuccess = '[Orders List Show] Download Show Report Success',
  DownloadHistoryShowReportError = '[Orders List Show] Download Show Report History Error',
  GetShowOrderCountsSuccess = '[Orders List Show] Get Show Order Counts Success',
  GetShowOrderCountsError = '[Orders List Show] Get Show Order Counts Error',
  GetShowOrderCountsOnTabChangeSuccess = '[Orders List Show] Get Show Order Counts On Tab Change Success',
  GetShowOrderCountsOnTabChangeError = '[Orders List Show] Get Show Order Counts On Tab Change Error',
  EsnSearchEnterClicked = '[Orders List Show] ESN Search Enter Clicked',
  EsnSearchValueChanged = '[Orders List Show] ESN Search Value Changed',
}
