
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as MessagesSelectors from './messages.selectors';
import { MessagesActions } from './messages.actions';
import { IChatRoom } from '../models/IChatRoom';
import * as ShowSelectors from '@app/shared/state/show/show.selectors';

@Injectable()
export class MessagesFacade {


  constructor(
    private _store: Store,
  ) {
  }

  selectEnabledChats$ = this._store.select(MessagesSelectors.selectEnabledChats);
  selectHeaderChats$ = this._store.select(MessagesSelectors.selectHeaderChats);
  selectHeaderChatsFiltered$ = this._store.select(MessagesSelectors.selectHeaderChatsFiltered);
  selectUnreadCount$ = this._store.select(MessagesSelectors.selectUnreadCount);
  selectSuppliers$ = this._store.select(ShowSelectors.selectSuppliers);
  selectNewChatOpen$ = this._store.select(MessagesSelectors.selectNewChatOpen);


  openChat(customerNumber: string,
    supplierNumber: string,
    customerName: string,
    supplierName: string
    ) {
    this._store.dispatch(MessagesActions.openChat({
      customerNumber,
      supplierNumber,
      customerName,
      supplierName,
    }));
  }

  closeChat(chatId: string) {
    this._store.dispatch(MessagesActions.closeChat({ chatId }));
  }

  toggleCollapseChat(chatRoom: IChatRoom) {
    this._store.dispatch(MessagesActions.toggleCollapseChat({ chatRoom }));
  }

  sendMessage(message: string, chatId: string, userFullName: string, userEmail: string) {
    this._store.dispatch(MessagesActions.sendMessage({message, chatId, userFullName, userEmail}));
  }

  getMoreMessages(chatRoom: IChatRoom) {
    this._store.dispatch(MessagesActions.getMoreMessages({ chatRoom }));
  }

  createNewChat() {
    this._store.dispatch(MessagesActions.createNewChat());
  }

  closeNewChat() {
    this._store.dispatch(MessagesActions.closeNewChat());
  }

  saveDraftMessageChat(draftMessage: string, chatId: string, ) {
    this._store.dispatch(MessagesActions.saveDraftMessageChat({ chatId, draftMessage }));
  }

  setFilterHeaderChatsText(text: string) {
    this._store.dispatch(MessagesActions.setFilterHeaderChatsText({ text }));
  }

}

